<template>

	<component
		v-if="noWrapper"
		ref="input"
		v-bind="$attrs"
		:is="whatTagIsThis"
		:id="id"
		:class="inputClasses"
		:type="type"
		:placeholder="placeholder"
		:disabled="disabled"
		:name="name"
		:required="required"
		:checked="inputChecked"
		:value="innerValue"
		:rows="rows"
		:maxlength="maxlength"
		:aria-label="label || ariaLabel || placeholder"
		:aria-describedby="ariaDescribedBy"
		:aria-labelledby="ariaLabelledBy"
		@focus="focus"
		@blur="blur"
		@click="wave"
		@change="onChange"
		@input="onInput"
		v-text="whatTagIsThis==='textarea' && value || ''"
	/>

	<div :class="outterWrapperClasses" v-else-if="doesItGetTheGroupClass">

		<label v-if="separateLabel" :class="labelClasses" :for="id">{{ separateLabel }}</label>

		<slot name="before"></slot>

		<i v-if="icon" :class="iconClasses" />

		<div :class="wrapperClasses">

			<div class="input-group-prepend" v-if="$slots.prepend" :id="prependSlotID">
				<slot name="prepend"></slot>
			</div>

			<component
				ref="input"
				:is="whatTagIsThis"
				v-bind="$attrs"
				:id="id"
				:class="inputClasses"
				:type="type"
				:placeholder="placeholder"
				:disabled="disabled"
				:name="name"
				:required="required"
				:checked="inputChecked"
				:value="innerValue"
				:rows="rows"
				:maxlength="maxlength"
				:aria-label="label || ariaLabel || placeholder"
				:aria-describedby="ariaDescribedBy"
				:aria-labelledby="ariaLabelledBy"
				@focus="focus"
				@blur="blur"
				@click="wave"
				@change="onChange"
				@input="onInput"
				v-text="whatTagIsThis==='textarea' && value || ''"
			/>

			<label v-if="!separateLabel && label" :class="labelClasses" @click="focus" ref="label" :for="id">{{ label }}</label>

			<label v-if="isThisCheckboxLabeless" :class="labelClasses" @click="focus" ref="label" :for="id" />

			<slot></slot>

			<div class="input-group-append" v-if="$slots.append" :id="appendSlotID">
				<slot name="append"></slot>
			</div>

		</div>

		<div v-if="validFeedback" class="valid-feedback">
			{{ validFeedback }}
		</div>

		<div v-if="invalidFeedback" class="invalid-feedback">
			{{ invalidFeedback }}
		</div>

		<small v-if="small" class="form-text text-muted">{{ small }}</small>

	</div>

	<div :class="wrapperClasses" v-else>

		<label v-if="separateLabel" :class="labelClasses" :for="id">{{ separateLabel }}</label>

		<i v-if="icon" :class="iconClasses" />

		<div class="input-group-prepend" v-if="$slots.prepend" :id="prependSlotID">
			<slot name="prepend"></slot>
		</div>

		<label v-if="!separateLabel && label" :class="labelClasses" @click="focus" ref="label" :for="id">{{ label }}</label>

		<component
			ref="input"
			:is="whatTagIsThis"
			v-bind="$attrs"
			:id="id"
			:class="inputClasses"
			:type="type"
			:placeholder="placeholder"
			:disabled="disabled"
			:name="name"
			:required="required"
			:checked="inputChecked"
			:value="innerValue"
			:rows="rows"
			:maxlength="maxlength"
			:aria-label="label || ariaLabel || placeholder"
			:aria-describedby="ariaDescribedBy"
			:aria-labelledby="ariaLabelledBy"
			@focus="focus"
			@blur="blur"
			@click="wave"
			@change="onChange"
			@input="onInput"
			v-text="whatTagIsThis==='textarea' && value || ''"
		/>

		<label v-if="isThisCheckboxLabeless" :class="labelClasses" @click="focus" ref="label" :for="id" />

		<slot></slot>

		<div class="input-group-append" v-if="$slots.append" :id="appendSlotID">
			<slot name="append"></slot>
		</div>

		<div v-if="validFeedback" class="valid-feedback">
			{{ validFeedback }}
		</div>

		<div v-if="invalidFeedback" class="invalid-feedback">
			{{ invalidFeedback }}
		</div>

		<small v-if="small" class="form-text text-muted">{{ small }}</small>

	</div>

</template>

<script>

	import waves from '@incodeapps/mdbvue-pro/src/mixins/waves';
	import mdbClassMixin from '@incodeapps/mdbvue-pro/src/mixins/mdbClassMixin';

	export default {

		mixins: [waves, mdbClassMixin],

		inheritAttrs: false,

		props: {
			bootstrap: {
				type: Boolean,
				default: false
			},
			basic: {
				type: Boolean,
				default: false
			},
			name: {
				type: String
			},
			required: {
				type: Boolean,
				default: false
			},
			tag: {
				type: String,
				default: "input"
			},
			type: {
				type: String,
				default: "text"
			},
			id: {
				type: String
			},
			label: {
				type: String
			},
			filled: {
				type: Boolean
			},
			icon: {
				type: String,
			},
			placeholder: {
				type: String
			},
			size: {
				type: String
			},
			disabled: {
				type: Boolean,
				default: false
			},
			checked: {
				type: Boolean,
				default: false
			},
			navInput: {
				type: Boolean,
				default: false
			},
			gap: {
				type: Boolean,
				default: false
			},
			waves: {
				type: Boolean,
				default: false
			},
			wavesFixed: {
				type: Boolean,
				default: false
			},
			value: {
				type: [String, Number, Boolean],
				default: ''
			},
			labelColor: {
				type: String
			},
			iconClass: {
				type: String
			},
			inline: {
				type: Boolean
			},
			successMsg: {
				type: String
			},
			errorMsg: {
				type: String
			},
			validation: {
				type: Boolean
			},
			customValidation: {
				type: Boolean
			},
			isValid: {
				type: Boolean
			},
			active: {
				type: Boolean,
				default: false
			},
			labelClass: {
				type: [Array, String]
			},
			far: {
				type: Boolean,
				default: false
			},
			regular: {
				type: Boolean,
				default: false
			},
			fal: {
				type: Boolean,
				default: false
			},
			light: {
				type: Boolean,
				default: false
			},
			fab: {
				type: Boolean,
				default: false
			},
			brands: {
				type: Boolean,
				default: false
			},
			rows: {
				type: [Number, String]
			},
			wrapperClass: {
				type: [String, Array]
			},
			noWrapper: {
				type: Boolean,
				value: false
			},
			ariaLabel: {
				type: String
			},
			ariaDescribedBy: {
				type: String
			},
			ariaLabelledBy: {
				type: String
			},
			prependSlotID: {
				type: String
			},
			appendSlotID: {
				type: String
			},
			inputClass: {
				type: String
			},
			maxlength: {
				type: [String, Number]
			},
			floatLabel: {
				type: Boolean,
				default: true
			},
			outline: {
				type: Boolean,
				default: false
			},
			validFeedback: {
				type: [String, Boolean],
				default: false
			},
			invalidFeedback: {
				type: [String, Boolean],
				default: false
			},
			small: {
				type: String
			},
			bg: {
				type: Boolean
			},
			radioValue: {
				type: String
			}
		},

		data() {

			return {
				innerValue: this.value,
				innerChecked: this.checked,
				isTouched: this.active
			};

		},

		computed: {

			separateLabel() {

				return (this.bootstrap || !this.floatLabel) ? this.label : false;

			},

			inputChecked() {

				if (this.type === "checkbox") {

					if (this.value === true || this.innerChecked === true) {

						return true;

					}

					return false;

				}

				if (this.type === "radio") {

					if (this.value === this.radioValue || this.innerChecked) {

						return true;

					}

					return false;

				}

				return false;

			},

			inputClasses() {

				return [
					(!this.basic) && 'form-control',
					this.validation ? this.isValid ? 'is-valid' : 'is-invalid' : false,
					this.customValidation ? this.isValid ? 'is-valid' : 'is-invalid' : false,
					this.size && 'form-control-' + this.size,
					{
						'filled-in': this.filled,
						'with-gap': this.gap
					},
					this.type === 'checkbox' ? this.gap ? false : 'form-check-input' : false,
					this.type === 'radio' ? 'form-check-input' : false,
					this.type === 'textarea' && !this.basic ? 'md-textarea' : false,
					this.inputClass && this.inputClass
				];

			},

			outterWrapperClasses() {

				if (this.noWrapper) {

					return [];

				}

				let classes = [
					this.outline && 'md-outline',
					this.bg && 'md-bg',
					this.waves && 'ripple-parent',
					this.doesItGetTheGroupClass && this.size ? `form-${this.size}` : false,
					this.wrapperClass,
					...this.mdbClass,
					'field-type-' + this.type,
					this.required ? 'required' : null,
					!this.isValid ? 'was-validated' : null
				];

				if (this.bootstrap) {

					if (!this.basic && !['checkbox', 'radio'].includes(this.type) && this.doesItGetTheGroupClass) {

						classes.unshift('form-group');

					}

				} else {

					if (['checkbox'/*, 'radio'*/].includes(this.type)) {

						classes.unshift('form-check');

					}

					if (!this.basic && !['checkbox', 'radio'].includes(this.type)) {

						classes.unshift('md-form');

					}

				}

				return classes;

			},

			wrapperClasses() {

				if (this.noWrapper) {

					return [];

				}

				let classes = [
					this.outline && 'md-outline',
					this.bg && 'md-bg',
					this.waves && 'ripple-parent',
					this.doesItGetTheGroupClass && this.size ? `input-group input-group-${this.size}`
					: this.doesItGetTheGroupClass && !this.size ? 'input-group'
						: !this.doesItGetTheGroupClass && this.size ? `form-${this.size}` : false,
					this.wrapperClass,
					...this.mdbClass,
					'field-type-' + this.type,
					this.required ? 'required' : null,
					!this.isValid ? 'was-validated is-invalid' : null
				];

				if (this.bootstrap) {

					if (!this.basic && !['checkbox', 'radio'].includes(this.type) && !this.doesItGetTheGroupClass) {

						classes.unshift('form-group');

					}

				} else {

					if (['checkbox'/*, 'radio'*/].includes(this.type)) {

						classes.unshift('form-check');

					}

					if (!this.basic && !['checkbox', 'radio'].includes(this.type)) {

						classes.unshift('md-form');

					}

				}

				return classes;

			},

			iconClasses() {

				return [
					this.far || this.regular ? 'far'
					: this.fal || this.light ? 'fal'
						: this.fab || this.brands ? 'fab' : 'fas',
					'prefix fa-' + this.icon,
					this.isTouched && 'active',
					this.iconClass
				];

			},

			labelClasses() {

				return [
					{
						'active': (this.placeholder || this.isTouched || this.innerValue !== '') && this.type!=='checkbox' && this.type!=='radio',
						'disabled': this.disabled,
						'form-check-label': (this.type === 'checkbox' || this.type === 'radio'),
						'mr-5': !this.isThisCheckboxLabeless
					},
					this.labelColor && 'text-' + this.labelColor,
					this.labelClass
				];

			},

			// tagname helper
			whatTagIsThis() {

				if (this.type === 'textarea') {

					return 'textarea';

				}

				return this.tag;

			},

			// classname helper
			doesItGetTheGroupClass() {

				return (this.$slots.prepend || this.$slots.append) || (this.basic && this.type==='textarea');

			},

			// checkbox with no label (say, has to fit vertically & horizontally) helper
			isThisCheckboxLabeless() {

				return this.type==='checkbox' && typeof this.label==="undefined";

			}

		},

		watch: {

			/*innerValue(val) {
				console.log('innerValue', val);
			},*/

			value(val, oldVal) {

				this.innerValue = val;
				 this.$refs.input.value = val;

				if (val && oldVal === null) {

					this.$refs.input.value = val;
					let e = new CustomEvent('manualUpdate', {detail: {value: val}});
					this.$el.dispatchEvent(e);

				}

			}

		},

		mounted() {

			if (this.type === "checkbox" || this.type === "radio") {

				this.$emit('getDefaultValue', this.inputChecked);

			} else {

				this.$emit('getDefaultValue', this.innerValue);

			}

		},

		methods: {

			focus(e) {

				this.isTouched = true;

				if (!this.disabled) {

					this.$refs.input.focus();

				}

				// styles for navbar input
				if (this.navInput) {

					this.$el.firstElementChild.style.borderColor = "transparent";
					this.$el.firstElementChild.style.boxShadow = "none";

				}

			},

			blur(e) {

				this.isTouched = false;

				// styles for navbar input
				if (this.navInput) {

					this.$el.firstElementChild.style.borderColor = "#fff";

				}

				this.$emit('blur', e);

			},

			onChange(e) {

				if (this.type === "checkbox") {

					this.$emit('change', e.target.checked);
					this.$emit('input', e.target.checked);
					this.innerChecked = e.target.checked;

				} else if (this.type === "radio") {

					this.innerChecked = e.target.checked;

					if (this.radioValue) {

						this.$emit('input', this.radioValue);

					}

				} else {

					// console.log('Input onChange', e.target.value);
					this.$emit('change', e.target.value);

				}

			},

			onInput(e) {

				// console.log('onInput', e);

				if (this.type !== "checkbox") {

					this.$emit('input', e.target.value);
					this.innerValue = e.target.value;

				}

			}

		}

	};

</script>

<style scoped>

	.navbar .md-form {
		margin-top: 0;
		margin-bottom: 0;
	}

	.form-dark input[type="checkbox"]:checked + label:before {
		top: -4px;
		left: -3px;
		width: 12px;
		height: 22px;
		border-style: solid;
		border-width: 2px;
		border-color: transparent #00c851 #00c851 transparent;
		-webkit-transform: rotate(40deg);
		-ms-transform: rotate(40deg);
		transform: rotate(40deg);
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%; }

	.form-dark .font-small {
		font-size: 0.8rem; }

	.form-dark input[type=email]:focus:not([readonly]) + label {
		color: #fff; }

	.form-dark input[type="checkbox"] + label:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 17px;
		height: 17px;
		z-index: 0;
		border: 1.5px solid #fff;
		border-radius: 1px;
		margin-top: 2px;
		-webkit-transition: 0.2s;
		transition: 0.2s; }

	.form-dark input[type="checkbox"]:checked + label:before {
		top: -4px;
		left: -3px;
		width: 12px;
		height: 22px;
		border-style: solid;
		border-width: 2px;
		border-color: transparent #00c851 #00c851 transparent;
		-webkit-transform: rotate(40deg);
		-ms-transform: rotate(40deg);
		transform: rotate(40deg);
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%; }

	.form-dark input[type=password]:focus:not([readonly]) {
		border-bottom: 1px solid #00C851;
		-webkit-box-shadow: 0 1px 0 0 #00C851;
		box-shadow: 0 1px 0 0 #00C851; }


	.form-dark input[type=email]:focus:not([readonly]) {
		border-bottom: 1px solid #00C851;
		-webkit-box-shadow: 0 1px 0 0 #00C851;
		box-shadow: 0 1px 0 0 #00C851; }


	.form-dark [type="checkbox"] + label:before {
		top: 2px;
		width: 15px;
		height: 15px;
	}

	.md-form textarea~label.active{
		color: inherit;
	}

	.form-control.is-valid {
		border-color: #28a745;
		background-image: url("data:image/svg+xml,%3Csvg%20title%3D%27valid%20input%27%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%208%208%27%3E%3Ctitle%3Evalid%20input%3C/title%3E%3Cdesc%3Evalid%20input%3C/desc%3E%3Cpath%20fill%3D%27%2328a745%27%20d%3D%27M2.3%206.73L.6%204.53c-.4-1.04.46-1.4%201.1-.8l1.1%201.4%203.4-3.8c.6-.63%201.6-.27%201.2.7l-4%204.6c-.43.5-.8.4-1.1.1z%27/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center right calc(0.375em + 0.1875rem);
		background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}

	.form-control.is-invalid {
		border-color: #dc3545;
		background-image: url("data:image/svg+xml,%3Csvg%20%20title%3D%27invalid%20input%27%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20fill%3D%27%23dc3545%27%20viewBox%3D%27-2%20-2%207%207%27%3E%3Ctitle%3Einvalid%20input%3C/title%3E%3Cdesc%3Einvalid%20input%3C/desc%3E%3Cpath%20stroke%3D%27%23dc3545%27%20d%3D%27M0%200l3%203m0-3L0%203%27/%3E%3Ccircle%20r%3D%27.5%27/%3E%3Ccircle%20cx%3D%273%27%20r%3D%27.5%27/%3E%3Ccircle%20cy%3D%273%27%20r%3D%27.5%27/%3E%3Ccircle%20cx%3D%273%27%20cy%3D%273%27%20r%3D%27.5%27/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center right calc(0.375em + 0.1875rem);
		background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}

</style>
