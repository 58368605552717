import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import storage from './storage';
import vuexModules from './vuex';

Vue.use(Vuex);

export const vuexLocalStorage = new VuexPersist({
	key: 'appSession',
	storage: storage,
	filter: mutation => {

		// mutations that should trigger a save

		return ([
			'SAVE_LOCALE',
			'SAVE_AUTH_USER',
			'SAVE_AUTH_USER_VENDOR',
			'SAVE_AUTH_LOGO',
			'SAVE_AUTH_USER_TOKEN',
			'SET_AUTH_USER_TOKEN_LAST_CHECK',
			'SAVE_APPLICATION_INFO',
			'SAVE_APPLICATION_SECTIONS',
			'SAVE_CO_BORROWER',
			'SAVE_VALIDATE_ALL',
			'SAVE_APPLICATION_OPTIONS',
			'SAVE_ASSET_TYPES',
			'SAVE_APPLICATION_LIST_ITEM'
		].includes(mutation.type));

	},
	reducer: (state) => {

		// state properties to keep in session
		// must have the exact same structure to reload them

		let reduced = {
			app: {
				locale: state.app.locale
			},
			auth: {
				authUser: state.auth.authUser,
				authUserVendor: state.auth.authUserVendor,
				logo: state.auth.logo,
				authUserToken: state.auth.authUserToken,
				authUserTokenLastCheck: state.auth.authUserTokenLastCheck
			},
			application: {
				options: state.application.options,
				info: state.application.info,
				sections: state.application.sections,
				coBorrower: state.application.coBorrower,
				validateAll: state.application.validateAll,
				applicationLists: state.application.applicationLists
			}
		};

		return reduced;

	}
});

export default new Vuex.Store({
	strict: process.env.VUE_APP_DEBUG, // do not use when in production
	modules: vuexModules,
	plugins: [
		vuexLocalStorage.plugin
	]
});
