<template>

	<sub-btn
		:class="btnClass"
		:disabled="loading"
		v-bind="$attrs"
		v-on="Object.assign({}, $listeners)"
	>

		<template v-if="loading">
			<div :class="spinnerClass">

				<spinner :width="spinnerSize" :height="spinnerSize" :color="cpuSpinnerColor" :style="absoluteSpinnerStyle"></spinner>

			</div>
		</template>

		<div :style="contentStyle">
			<span v-trim><slot></slot></span>
		</div>

	</sub-btn>

</template>

<script>

	import SubBtn from '../globalComponents/Form/Button';
	import Spinner from 'vue-loading-overlay/src/loaders/spinner';

	export default {

		components: {
			SubBtn,
			Spinner
		},

		props: {

			loading: {
				type: Boolean,
				default: false
			},

			spinnerColor: {
				type: String,
				default: '#ffffff'
			},

			spinnerSize: {
				type: Number,
				default: 30
			},

			spinnerAbsolute: {
				type: Boolean,
				default: true
			}

		},

		computed: {

			contentStyle() {

				return {
					opacity: this.loading ? 0 : 1
				};

			},

			btnClass() {

				return this.spinnerAbsolute ? 'btn-with-absolute-loader' : '';

			},

			spinnerClass() {

				return this.spinnerAbsolute ? 'btn-absolute-loader-wrapper' : 'btn-loader-wrapper';

			},

			cpuSpinnerColor() {

				switch (this.spinnerColor) {

					case 'info':
						return '#008093';

					case 'white':
						return '#ffffff';

					default:
						return this.spinnerColor;

				}

			},

			absoluteSpinnerStyle() {

				if (!this.spinnerAbsolute) {

					return;

				}

				let num = parseInt(this.spinnerSize / 2);

				return {
					marginTop: `${num}px`,
				};

			}

		}

	};

</script>

<style lang="scss" scoped>

	.btn-with-absolute-loader {
		position: relative;
	}

	.btn-absolute-loader-wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		//margin-left: 0.5em;
	}

	.btn-sm {
		.btn-absolute-loader-wrapper {
			top: -0.25em;
		}
	}

</style>
