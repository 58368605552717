<template>
	<div class="add-applicant-template">
		<steps :current="1" :total="4" />

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Add Applicant</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" class="needs-validation" novalidate>
			<div class="container-xl template--vh">
				<div class="row">
					<div class="col-lg-8">
						<program :programType="programType" :financedAmount="vModels.program.financedAmount" :monthly="vModels.program.monthly" />

						<div v-if="applicant.firstName" class="tffi-card tffi-card-secondary-card theming primary-color">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(app.application)" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(app.application)" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ this.applicant['firstName'] + ' ' + this.applicant['lastName'] }}</p>
									<p>{{ this.applicant['email'] }}</p>
								</div>
								<!-- <div class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</div> -->
								<!-- <button @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button> -->
							</div>
						</div>

						<div v-if="coApps" v-for="(coApp, index) in coApps" class="tffi-card tffi-card-secondary-card">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(coApps[index])" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(coApps[index])" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Co-Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ coApp['firstName'] + ' ' + coApp['lastName'] }}</p>
									<p>{{ coApp['email'] }}</p>
								</div>
								<button type="button" @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button>
							</div>
						</div>

						<!-- <button class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100">
							Add Applicant
						</button> -->

						<div class="template--section-divider"></div>

					</div>
					<div class="col-lg-16">
						<div class="tffi-card tffi-card-main-card" v-if="coApps != null && coApps.length >= 3">
							<div class="row">
								<div class="col-24">
									<p>Maximum number of co-applicants reached</p>
								</div>
							</div>
						</div>
						<div class="tffi-card tffi-card-main-card" v-if="!isMaxApplicants">
							<div class="row">
								<div class="col-24">
									<h2>{{ !applicant.firstName ? 'Applicant' : 'Co-Applicant'}}</h2>
								</div>
							</div>
							<div class="row row-cols-lg-2">
								<template v-for="(fieldData, fieldName) in getForm('1')">
									<div :class="fieldData.class">
										<div class="d-flex flex-column">
											<!-- <label :for="fieldName">{{ fieldData.label }}</label> -->
											<form-field
												v-bind="fieldData"
												v-model="vModels[fieldName]"
												:name="fieldName"
												:label="getLabel(fieldName)"
												:id="fieldName"
												:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
												:error="errors[fieldName]"
												:locale="locale"
												autocomplete="off"
												bootstrap
											>
											<div class="invalid-feedback">Please choose a username.</div>
										</form-field>
										</div>
									</div>
								</template>
							</div>
							<div class="row">
								<div class="col-24 col-lg-16 my-3">
									<div class="d-flex align-items-center h-100">
										<p class="theming primary-color my-0" v-if="applicant.firstName">
											* If there are no more co-applicants, click NEXT.
										</p>
									</div>
								</div>
								<div class="col-24 col-lg-8 my-3">
									<div class="d-flex justify-content-end">
										<button
											class="tffi-btn tffi-btn--solid theming accent-color accent-color-btn-solid mx-auto-lg fw-bold w-75"
											type="submit"
											>
											Add
										</button>
									</div>
								</div>
							</div>
							<!-- <div class="row">
								<div class="col-lg-24 my-3">
									<div class="d-flex justify-content-end">
										<button
											class="tffi-btn tffi-btn--solid theming accent-color accent-color-btn-solid mx-auto-lg w-10 fw-bold"
											type="button"
											@click="addApplicant"
											>
											Add
										</button>
									</div>
								</div>
							</div> -->
						</div>

					</div>
				</div>
			</div>

		</form>
		
		<div class="tffi-navigation-bottom">
			<div class="container-xl">
				<div class="row">
					<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
						<button
							type="button"
							class="tffi-btn tffi-btn--solid theming secondary-color"
							color="primary"
							:loading="goingBack"
							@click="goToPage({ name: vModels.program.program.Code == 2 ? 'LeaseCalculator' : 'PaymentCalculator', params: $route.params })"
						>
							{{ $t('forms.back') }}
						</button>
					</div>
					<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
						<button
							type="button"
							class="tffi-btn tffi-btn--solid theming secondary-color ms-auto"
							@click="goToPage({ name: 'Dashboard'})"
							>
							Save for later
						</button>
					</div>
					<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
						<button
							:disabled="!applicant.firstName"
							:title="!applicant.firstName ? 'Please add at least one applicant' : ''"
							class="tffi-btn tffi-btn--solid theming primary-color me-0"
							@click="goToPage({ name: 'ApplicationStep2', params: $route.params })"
							>
							Next
						</button>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>

	import ApplicationMixin from './ApplicationMixin';
	import { submitApplication, saveApplication, saveCoBorrower, getOptions, getApplication } from '../../../../api';


	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				programType: '',
				applicant: {},
				app: {},
				isMaxApplicants: false,
				equipments: [],
				coApps: [],
				vModels: {
					applicants: [],
					program: {
						financedAmount: 0
					}
				}
			};

		},

		filters: {
			formatMoney(value) {
      			return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(value);
    		},
		},

		watch: {

			'vModels.amount'(val) {
			}

		},

		async mounted() {

			this.saveLoading(true);

			let id = this.$route.params.applicationId

			this.coApps = null

			console.log('id', id);

			if (!id) {
				return;
			}

			let app = await getApplication(id)

			this.app = app;

			console.log('app', app);

			this.vModels.program = app.application.program

			this.applicant = {
				firstName: app.application.firstName,
				lastName: app.application.lastName,
				email: app.application.email
			}

			if (app.application.coApps) {
				this.coApps = app.application.coApps
				this.isMaxApplicants = this.coApps.length >= 3 ?? true;
			}
			
			if (app.application.equipments) {
				this.equipments = app.application.equipments
			}

			console.log('Number(this.vModels.program.program.Code)', Number(this.vModels.program.program.Code));
			console.log('this.vModels.program.program.Code)', Number(this.vModels.program.program.Code));

			this.programType = ((this.vModels.program && this.vModels.program.program && this.vModels.program.program.Code) && Number(this.vModels.program.program.Code) === 2) ? 'Lease' : 'Loan'

			this.saveLoading(false);

			await setTimeout(() => {
				console.log('setting focus');
				const firstNameInput = document.getElementById('firstName');
				firstNameInput.focus();
			}, 1000);

		},

		methods: {
			async addApplicant() {

				const firstNameInput = document.getElementById('firstName');
				firstNameInput.focus();

				this.saveLoading(true);

				console.log('vModels.applicants.length', this.vModels.applicants.length);
				console.log('this.vModels.applicants', this.vModels.applicants);

				let fields = [
					'firstName',
					'lastName',
					'email'
				]

				let isMain = false

				if (
					!this.applicant
					|| !this.applicant.firstName
				) {
					isMain = true;
				}

				let coApp = {}
				let error = null

				fields.forEach(element => {
					if (!this.vModels[element]) {
						console.log('vModels[element]: ', this.vModels[element]);
						this.errorAlert = this.showErrorAlert('Please fill out ' + element);
						this.saveLoading(false);
						error = true
					}
				})

				if (error) {
					return
				}

				fields.forEach(element => {

					if (isMain) {
						this.applicant[element] = this.vModels[element]
					} else {
						coApp[element] = this.vModels[element]
					}

					this.vModels[element] = ''

				});

				console.log('coApp', coApp);

				if (
					!isMain
				) {
					console.log('this.coApps before', this.coApps);
					let coApps = [];

					if (this.coApps) {
						coApps = this.coApps
					}

					coApps.push(coApp)

					this.isMaxApplicants = coApps.length >= 3 ?? true;

					this.coApps = coApps
					console.log('this.coApps after', this.coApps);
				}

				let id = this.$route.params.applicationId

				let result = await this.saveApplicationAction({
					data: {
						id,
						...this.applicant,
						coApps: this.coApps ? [...this.coApps] : []
					},
					errors: this.errors
				});

				console.log('result', result);

				this.saveLoading(false);

			},

			async removeApplicant(index) {

				this.saveLoading(true);

				console.log('removeApplicant', index);

				this.coApps.splice(index, 1);

				let result = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						...this.applicant,
						coApps: this.coApps ? [...this.coApps] : []
					},
					errors: this.errors
				});

				this.isMaxApplicants = this.coApps.length >= 3 ?? true;

				this.saveLoading(false);

			},

			async moveToStep2() {

				this.saveLoading(true);

				// let result = await this.saveApplicationAction({
				// 	data: {
				// 		id: this.$route.params.applicationId,
				// 		...this.applicant,
				// 		coApps: this.coApps ? [...this.coApps] : []
				// 	},
				// 	errors: this.errors
				// });

				// console.log('result', result);
				
				await this.$router.push({
					name: `ApplicationStep2`,
					params: {
						applicationId: this.$route.params.applicationId
					}
				});
				
				this.saveLoading(false);

			},

			async onSubmit(e) {

				this.addApplicant()

			}
		}

	};

</script>

<style lang="scss" scoped>
	.float-right {
		float: right;
	}

</style>
