// don't forget to make sure there is a translation with the same key in the component

export default [
	
	{
		key: 'Applications',
		ariaLabel: 'Applications',
		to: {name: 'List'},
		icon: {
			type: 'tffi-icon',
			name: 'home'
		}
	}
	

];