<template>

	<btn
		:type="type"
		:disabled="loading"
		:color="color"
		size="lg"
		rounded
		block
		style="white-space: nowrap"
		v-bind="$attrs"
		v-on="Object.assign({}, $listeners)"
	>

		<template v-if="loading">
			<div class="btn-loader-wrapper">

				<spinner :width="30" :height="30" :color="cpuSpinnerColor"></spinner>

			</div>
		</template>

		<template v-else>
			<span v-if="text" style="white-space: pre-line">{{ text }}</span>
			<span v-else style="white-space: pre-line"><slot></slot></span>
		</template>

	</btn>

</template>

<script>

	import Spinner from 'vue-loading-overlay/src/loaders/spinner';

	export default {

		components: {
			Spinner
		},

		props: {

			loading: {
				type: Boolean,
				default: false
			},

			color: {
				type: String,
				default: 'info'
			},

			spinnerColor: {
				type: String,
				default: '#ffffff'
			},

			type: {
				type: String,
				default: 'submit'
			},

			text: {
				type: String,
				default: null
			}

		},

		computed: {

			cpuSpinnerColor() {

				switch (this.spinnerColor) {

					case 'info':
						return '#008093';

					case 'white':
						return '#ffffff';

					default:
						return this.spinnerColor;

				}

			}

		}

	};

</script>
