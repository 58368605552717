<template>
	
	<div v-if="isMultiWrapper" :class="wrapperClasses">
		
		<label v-if="label" :class="labelClasses" :for="idCPU">{{ label }}</label>
		
		<my-checkbox
			v-for="(option, index) in options"
			v-bind="$attrs"
			:key="idCPU + '-' + index"
			:id="idCPU + '-' + index"
			:name="nameCPU + '-' + index"
			:label="option.text"
			:value="value"
			:checkbox-value="option.value"
			:bootstrap="bootstrap"
			v-on="inputListeners"
		>{{ option.text }}
		</my-checkbox>
	
	</div>
	
	<div v-else-if="isMaterial" :class="wrapperClasses">
		<div :class="checkboxWrapperClasses">
			
			<my-input
				v-bind="$attrs"
				type="checkbox"
				:label="label"
				:id="idCPU"
				:name="nameCPU"
				:checked="inputChecked"
				:value="checkboxValue"
				v-on="inputListeners"
			/>
			
		</div>
	</div>
	
	<div v-else :class="wrapperClasses">
		
		<input
			type="checkbox"
			class="custom-control-input"
			:id="idCPU"
			:name="nameCPU"
			:checked="inputChecked"
			:value="checkboxValue"
			v-on="inputListeners"
		>
		
		<label
			class="custom-control-label"
			:for="idCPU"
		>{{ label }}</label>
		
	</div>
	
</template>

<script>
	
	import { paramCase } from 'change-case';
	import MyInput from './Input';
	import MyCheckbox from './Checkbox';
	
	export default {
		
		name: 'MyCheckbox',
		
		components: {
			MyInput,
			MyCheckbox
		},
		
		inheritAttrs: false,
		
		props: {
			
			bootstrap: {
				type: Boolean,
				default: false
			},
			
			label: {
				type: String,
				default: null
			},
			
			labelClass: {
				type: String,
				default: null
			},
			
			name: {
				type: String,
				default: null
			},
			
			id: {
				type: String,
				default: null
			},
			
			wrapperClass: {
				type: String,
				default: null
			},
			
			checkboxWrapperClass: {
				type: String,
				default: null
			},
			
			options: {
				type: [Object, Array],
				default: null
			},
			
			checked: {
				type: Boolean,
				default: false
			},
			
			value: {
				type: [String, Number, Boolean, Array, Object, Event],
				default: ''
			},
			
			checkboxValue: {
				type: String
			}
			
		},
		
		data() {
			
			return {
				innerChecked: this.checked,
				multi: []
			};
			
		},
		
		computed: {
			
			isMaterial() {
				
				return !this.bootstrap;
				
			},
			
			isMultiWrapper() {
				
				return !!this.options;
				
			},
			
			inputChecked() {
				
				if ((this.value === true || this.value === this.checkboxValue) || this.innerChecked === true) {
					
					return true;
					
				}
				
				return false;
				
			},
			
			nameCPU() {
				
				return (this.name) ? this.name : 'field-' + this._uid;
				
			},
			
			idCPU() {
				
				return (this.id) ? this.id : paramCase(this.nameCPU);
				
			},
			
			labelClasses() {
				
				return [
					this.labelClass
				];
				
			},
			
			wrapperClasses() {
				
				if (this.isMultiWrapper) {
					
					return [
						'form-group',
						'field-type-checkbox',
						this.$attrs.required && 'required',
						this.wrapperClass
					];
					
				}
				
				return [
					this.bootstrap && 'custom-control custom-checkbox',
					this.wrapperClass
				];
				
			},
			
			checkboxWrapperClasses() {
				
				return [
					this.checkboxWrapperClass
				];
				
			},
			
			inputListeners() {
				
				let _self = this;
				
				return Object.assign({},
					this.$listeners,
					{
						
						blur: function (event) {
							
							_self.$emit('blur', event);
							
						},
						
						change: function (event) {
							
							if (_self.isMultiWrapper) {
								
								Object.keys(event).forEach(k => {
									
									let position = _self.multi.indexOf(k);
									
									if (position !== -1) {
										
										if (!event[k]) {
											
											_self.multi.splice(position, 1);
											
										}
										
									} else if (event[k]) {
										
										_self.multi.push(k);
										
									}
									
								});
								
								return _self.$emit('change', _self.multi.sort());
								
							}
							
							if (event && event.target && event.target.value) {
								
								return _self.$emit('change', {[event.target.value]: event.target.checked});
								
							}
							
							if (!_self.checkboxValue) {

								return _self.$emit('change', event);
							
							}
							
							_self.$emit('change', {[_self.checkboxValue]: event});
							
						},
						
						focus: function (event) {
							
							_self.$emit('focus', event);
							
						},
						
						input: function (event) {
							
							if (_self.isMultiWrapper) {
								
								Object.keys(event).forEach(k => {
									
									let position = _self.multi.indexOf(k);
									
									if (position !== -1) {
										
										if (!event[k]) {
											
											_self.multi.splice(position, 1);
											
										}
										
									} else if (event[k]) {
										
										_self.multi.push(k);
										
									}
									
								});
								
								return _self.$emit('input', _self.multi.sort());
								
							}
							
							if (event && event.target && event.target.value) {
								
								return _self.$emit('input', {[event.target.value]: event.target.checked});
								
							}
							
							if (!_self.checkboxValue) {

								return _self.$emit('input', event);
							
							}
							
							_self.$emit('input', {[_self.checkboxValue]: event});
							
						}
						
					});
				
			}
			
		}
	
	};
	
</script>