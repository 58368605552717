<template>
	<div class="application-dashboard-template">
		<steps :current="3" :total="4" />

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Dashboard</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>
			<div class="container-xl template--vh">
				<div class="row">
					<div class="col-lg-8 d-none">
						<router-link tag="div" :to="{ name: 'ApplicationStep1', params: $route.params }">
							<button type="button"
								class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100 mb-4"
								color="primary">
								Add Note
							</button>
						</router-link>
					</div>

					<div class="col-lg-8">

						<div class="tffi-card tffi-card-summary-card">
							<div class="tffi-card-summary-card__top-area theming primary-color">
								<!-- <div class="tffi-card-summary-card__top-area__overlay-gradient"></div> -->
								<div class="tffi-card-summary-card__top-area__overlay-image"></div>
								<div class="tffi-card-summary-card__top-area__title-area">
									<h2>{{ programType }}</h2>
								</div>
								<div class="tffi-card-summary-card__top-area__financed-amount">
									<p v-if="vModels.program.financedAmount">Financed Amount: <span
											class="tffi-card-summary-card__top-area__financed-amount__price">${{ vModels.program.financedAmount
												| formatMoney }}</span></p>
								</div>
							</div>
							<div class="tffi-card-summary-card__bottom-area">
								<div v-if="vModels.program.programType" class="tffi-card-summary-card__bottom-area__label">
									<p>Program Type:</p>
									<span class="flex-grow-1"></span>
									<p>{{ getProgramType(vModels.program.programType) }}</p>
								</div>
								<div v-if="vModels.program.leasingPeriod" class="tffi-card-summary-card__bottom-area__label">
									<span class="flex-grow-1"></span>
									<p>{{ $t('financingProgram.leasingPeriod') }}: {{ vModels.program.leasingPeriod || 'N/A' }}</p>
								</div>
								<div v-if="vModels.program.rate" class="tffi-card-summary-card__bottom-area__label">
									<p>Rate:</p>
									<span class="flex-grow-1"></span>
									<p>{{ vModels.program.rate }}%</p>
								</div>
								<div v-if="vModels.program.term" class="tffi-card-summary-card__bottom-area__label">
									<p>Finance Term:</p>
									<span class="flex-grow-1"></span>
									<p>{{ vModels.program.term }} Months</p>
								</div>
								<div v-if="vModels.program.amortTerm" class="tffi-card-summary-card__bottom-area__label">
									<p>Amortization Term:</p>
									<span class="flex-grow-1"></span>
									<p>{{ vModels.program.amortTerm }} Months</p>
								</div>
								<div v-if="vModels.program.monthly" class="tffi-card-summary-card__bottom-area__label">
									<p class="theming primary-color fw-bold">Base Monthly Payment:</p>
									<span class="flex-grow-1"></span>
									<p class="theming primary-color fw-bold">${{ vModels.program.monthly | formatMoney }}</p>
								</div>
							</div>
						</div>

						<div @click="resetNote" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-toggle="modal" data-bs-target="#notesModal" v-if="note"
							class="tffi-card tffi-card-summary-card sticky-note theming">
							<p class="strong">Notes</p>
							{{ note }}
						</div>

						<button v-if="!note" type="button" class="tffi-btn tffi-btn--solid theming accent-color w-100 fw-bold"
							data-bs-toggle="modal" data-bs-target="#notesModal">
							Add Note
						</button>

						<!-- Modal -->
						<div class="modal fade show-" id="notesModal" tabindex="-1" aria-labelledby="notesModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered">
								<div class="modal-content">
									<!-- <div class="modal-header">
										<h3 class="modal-title theming primary-color">Modal title</h3>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div> -->
									<div class="modal-body">
										<form-field v-model="vModels.note" name="note" bootstrap multiline type="textarea" rows="5"></form-field>
									</div>
									<div class="modal-footer">
										<button @click="saveNote" data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming primary-color w-100 fw-bold">Save Note</button>
										<button data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming accent-color w-100 fw-bold">Cancel</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-8">
						<div style="cursor: pointer;" @click="$router.push({ name: 'ApplicationStep2', params: $route.params, query: { select: 'main'} })" v-if="applicant.firstName" class="tffi-card tffi-card-secondary-card theming primary-color person">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(app.application)" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(app.application)" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">

								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Applicant</h2>
								</div>

								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ this.applicant['firstName'] + ' ' + this.applicant['lastName'] }}</p>
									<p>{{ this.applicant['email'] }}</p>
								</div>
								
								<button @click="removeApplicant('main')" class="tffi-card-secondary-card__right-area__trash-area d-none">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button>

							</div>
						</div>

						<div style="cursor: pointer;" @click="$router.push({ name: 'ApplicationStep2', params: $route.params, query: { select: index } })" v-if="coApps" v-for="(coApp, index) in this.coApps" class="tffi-card tffi-card-secondary-card person">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(coApps[index])" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(coApps[index])" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Co-Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ coApp['firstName'] + ' ' + coApp['lastName'] }}</p>
									<p>{{ coApp['email'] }}</p>
								</div>
								<button @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area d-none-">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button>
							</div>
						</div>

						<router-link v-if="coApps.length < 3" tag="div" :to="{ name: 'ApplicationStep1', params: $route.params }">
							<button type="button"
								class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100 mb-4"
								color="primary">
								Add Applicant
							</button>
						</router-link>
					</div>

					<div class="col-lg-8">

						<div v-if="equipments" v-for="(equipment, index) in equipments" class="tffi-card tffi-card-secondary-card">
							<div class="tffi-card-secondary-card__left-area">
								<!-- <div class="tffi-card-secondary-card__left-area__status-indicator">
									<div class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div class="status-denied">
										<img src="../../../../../assets/img/icons/stop.svg" alt="">
									</div>
								</div> -->
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Equipment</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>Type: {{ equipment['equipmentType'] }}</p>
									<p>Brand: {{ equipment['brand'] }}</p>
									<p>Model: {{ equipment['model'] }}</p>
								</div>
								<button @click="removeEquipment(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button>
							</div>
						</div>

						<router-link v-if="equipments.length < 4" tag="div" :to="{ name: 'ApplicationStep3', params: $route.params }">
							<button type="button"
								class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100 mb-4"
								color="primary">
								Add Equipment
							</button>
						</router-link>

					</div>
				</div>
			</div>

			<div class="tffi-navigation-bottom">
				<div class="container-xl">
					<div class="row">
						<div class="col order-2 order-lg-1 col-24 col-lg-20 d-flex justify-content-end">
							<button type="button" class="tffi-btn tffi-btn--solid theming secondary-color" color="primary"
								:loading="goingBack" @click="goToPage({ name: 'Dashboard'})">
								Save For Later
							</button>
						</div>
						<div class="col order-1 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
							<button :title="checkAppDetails(app.application).errorMessage ?? ''" :disabled="checkAppDetails(app.application).errorMessage" type="button" class="tffi-btn tffi-btn--solid theming primary-color me-0"
								@click="goToPage({ name: 'Terms', params: $route.params })">
								Submit
							</button>
							
							<button type="button" class="tffi-btn tffi-btn--solid theming primary-color me-0 d-none"
								@click="goToPage({ name: 'UploadFiles', params: $route.params })">
								Upload Files
							</button>
						</div>
					</div>
				</div>
			</div>

		</form>
	</div>
</template>

<script>
import ApplicationMixin from './ApplicationMixin';
import FormsConfirmationMixin from '../FormsConfirmationMixin';
import { getApplication } from '../../../../api';


export default {

	mixins: [
		ApplicationMixin,
		FormsConfirmationMixin
	],

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, }).format(value);
		},
	},

	data() {

		return {
			applicant: {},
			equipments: [],
			coApps: [],
			programType: '',
			app: [],
			note: '',
			vModels: {
				applicants: [],
				note: '',
				program: {
					financedAmount: 0
				}
			}
		};

	},

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, }).format(value);
		},
	},

	computed: {

	},

	watch: {

	},

	async mounted() {

		this.saveLoading(true);

		let id = this.$route.params.applicationId

		let app = await getApplication(id)

		this.app = app

		console.log('app', app);

		this.applicant = {
			firstName: app.application.firstName,
			lastName: app.application.lastName,
			email: app.application.email
		}

		if (app.application.equipments) {
			this.equipments = app.application.equipments
		}

		if (app.application.hasOwnProperty('note')) {
			this.vModels.note = app.application.note
			this.note = app.application.note
		}

		this.vModels.program = app.application.program ?? {};

		this.coApps = app.application.coApps


		if (
			this.vModels.program
			&& this.vModels.program.program
		) {
			this.programType = (this.vModels.program.program.Code == 2) ? 'Lease Payment' : 'Loan Payment'
		} else if (app.application.isImportedFromEdge) {
			this.programType = 'Imported from EDGE, no program details found'
		}

		this.saveLoading(false);

	},

	methods: {

		async removeApplicant(index) {

			this.saveLoading(true);

			this.coApps.splice(index, 1);

			let result = await this.saveApplicationAction({
				data: {
					id: this.$route.params.applicationId,
					coApps: this.coApps
				},
				errors: this.errors
			});

			this.saveLoading(false);

		},

		async removeEquipment(index) {

			this.saveLoading(true);

			this.equipments.splice(index, 1);

			let result = await this.saveApplicationAction({
				data: {
					id: this.app.application.id,
					equipments: this.equipments
				}
			});

			this.saveLoading(false);

		},

		getFieldError(name) {

			return this.application.errors && this.application.errors[name];

		},

		otherBtnClick() {

			this.otherBtnLoading = true;

			this.$router.push({ name: 'CoBorrowerStep0', params: this.$route.params });
			return;

		},

		async saveNote() {

			this.saveLoading(true);

			// console.log('this.app.application', this.app.application);

			let result = await this.saveApplicationAction({
				data: {
					id: this.app.application.id,
					note: this.vModels.note
				}
			});

			// console.log('result', result);
			this.app.application.note = this.vModels.note
			this.note = this.vModels.note

			this.saveLoading(false);


		},
		
		async resetNote() {

			this.vModels.note = this.app.application.note;
			this.note = this.app.application.note;

		},

	}

};

</script>

<style lang="scss" scoped>
.edit-btn {
	position: relative;
	top: -1rem;
	right: -1rem
}

.card-body {

	.form-group.static-form-group {

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}

	}

	.invalid-feedback {
		margin-top: -1rem;
		margin-bottom: 1rem;
	}

}

.form-control-plaintext {
	white-space: pre-line;
}

.tffi-card-secondary-card.person,
.tffi-card-secondary-card.person {
	&:hover {
		border: 2px solid #C18F14;
	}
}

.sticky-note {

	padding: 40px;
	padding-top: 20px;
	overflow-wrap: anywhere;
	cursor: pointer;
	background-color: #DFCE86;

	&:hover {
		border: 2px solid #C18F14;
	}

}
</style>
