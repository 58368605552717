<template>

	<mdb-container class="mt-5">

			<mdb-card>
				<mdb-card-body>

					<h1 class="mb-5">TFFI Digital Accessibility Statement</h1>

				</mdb-card-body>
			</mdb-card>

	</mdb-container>

</template>

<script>

	export default {

		components: {},

		data() {

			return {};

		},

		created() {},

		beforeDestroy() {},

		methods: {}

	};

</script>
