<template>

	<div class="databoxes-wrapper" :class="paramCase('databoxes-layout-' + tools.displayStyle)">

		<datatable-tools
			:display-style="tools.displayStyle"
			:display-style-btns="tools.displayStyleShow"
			:per-page="tools.perPage"
			:per-page-select="tools.perPageShow"
			:search="tools.searchShow && tools.searchTerm"
			:search-in-url="true"
			@update-display-style="updateDisplayStyle"
			@update-per-page="updatePerPage"
			@update-search="updateSearch"
		>
			<slot v-for="(_, name) in $slots" :name="name" :slot="name" />
		</datatable-tools>

		<div v-if="isLoading">
			<slot name="loading"></slot>
		</div>

		<div class="databoxes-all">

			<datatable-header
				v-if="!isLoading && pages.length && tools.displayStyle === 'list'"
				:display-style="tools.displayStyle"
				:columns="columns"
				:sorting="sorting"
				:sort="recentSort"
				@sort="emittedSort"
			/>

			<div
				v-if="!isLoading && !pages.length"
				class="databox-item no-results"
				:class="colSizeClass"
			>
				<p class="theming primary-color">No Results</p>
			</div>

			<div
				v-if="!isLoading && pages.length"
				v-for="(row, rowIndex) in pages[activePage]"
				:key="'row-' + rowIndex"
				class="databox-item"
				:class="colSizeClass"
			>

				<div class="databox">

					<datatable-header
						v-if="tools.displayStyle === 'grid'"
						:display-style="tools.displayStyle"
						:columns="columns"
						:row="rowIndex"
						:sorting="sorting"
						:sort="recentSort"
						@sort="emittedSort"
					/>

					<div class="box-values-wrap">
						<div class="box-values p-1">
							<div
								v-if="!col.hidden"
								v-for="(col, colIndex) in row"
								:key="itemKey(rowIndex, colIndex) + '-values'"
								class="box-cell d-flex flex-fit p-0"
								:class="colClass('values', col.field)"
							>

								<slot v-if="$scopedSlots[slotName(col)]" :name="slotName(col)" :item="col" :itemKey="itemKey(rowIndex, colIndex)"></slot>
								<div v-else v-html="col.display" class="w-100"></div>

							</div>
						</div>
					</div>

				</div>

			</div>

		</div>
		<!-- Main -->

		<!-- Labels and pagination -->
		<div v-if="!isLoading && pagination && pages.length > 0" class="row">

			<div class="col-md-8">

				<div class="databoxes-info" role="status" aria-live="polite">
					{{ $t('applications.pagination', {
						from: activePage > 0 ? activePage * tools.perPage : activePage+1,
						to: pages.length-1 > activePage ? pages[activePage].length*(activePage+1) : filteredRows.length,
						total: filteredRows.length
					}) }}
				</div>

			</div>

			<div class="col-md-16">
				<div class="databoxes-paginate" v-if="visiblePages.length > 1">
					<pagination>

						<page-item
							v-if="pages.length > maxPages"
							@click.native="changePage(0)"
							:disabled="activePage === 0 ? true : false"
						>
							<mdb-icon v-if="arrows" icon="angle-double-left" />
							<p v-else class="pagination">{{ start }}</p>
						</page-item>

						<page-item
							@click.native="changePage(activePage-1)"
							:disabled="activePage === 0 ? true : false"
						>
							<mdb-icon v-if="arrows" icon="angle-left" />
							<p v-else class="pagination">{{ previous }}</p>
						</page-item>

						<page-item
							v-for="(page, index) in visiblePages"
							:key="index"
							@click.native="changePage(pages.indexOf(visiblePages[index]))"
							:active="activePage === pages.indexOf(visiblePages[index]) ? true : false"
						>
							{{ pages.indexOf(visiblePages[index])+1 }}
						</page-item>

						<page-item
							@click.native="changePage(activePage+1)"
							:disabled="activePage === pages.length-1 ? true : false"
						>
							<mdb-icon v-if="arrows" icon="angle-right" />
							<p v-else class="pagination">{{ next }}</p>
						</page-item>

						<page-item
							v-if="pages.length > maxPages"
							@click.native="changePage(pages.length-1)"
							:disabled="activePage === pages.length-1 ? true : false"
						>
							<mdb-icon v-if="arrows" icon="angle-double-right" />
							<p v-else class="pagination">{{ end }}</p>
						</page-item>

					</pagination>
				</div>

			</div>

		</div>
		<!-- Labels and pagination -->

	</div>

</template>

<script>

	import { throttle } from 'throttle-debounce';
	import { paramCase } from 'change-case';
	import mdbBtn from '@incodeapps/mdbvue-pro/src/components/Components/Button';
	import mdbIcon from '@incodeapps/mdbvue-pro/src/components/Content/Fa';
	import Pagination from '@incodeapps/mdbvue-pro/src/components/Components/Pagination';
	import PageItem from '@incodeapps/mdbvue-pro/src/components/Components/PageItem';
	import DatatableTools from './DatatableTools';
	import DatatableHeader from './DatatableHeader';

	export default {

		name: 'Datatable',

		components: {
			DatatableHeader,
			DatatableTools,
			mdbBtn,
			mdbIcon,
			Pagination,
			PageItem
		},

		inheritAttrs: false,

		props: {

			data: {
				type: [Object, String],
				default: () => ({
					columns: [],
					rows: []
				})
			},

			locale: {
				type: String,
				default: 'en'
			},

			order: {
				type: Array
			},

			loading: {
				type: Boolean,
				default: false
			},

			// tools props

			displayStyle: {
				type: String,
				default: 'grid'
			},

			displayStyleBtns: {
				type: Boolean,
				default: true
			},

			pagination: {
				type: [Boolean, Number],
				default: true
			},

			perPage: {
				type: [Boolean, Number],
				default: true
			},

			search: {
				type: [Boolean, String],
				default: true
			},

			sorting: {
				type: Boolean,
				default: true
			},

			// pagination props

			start: {
				type: String,
				default: 'Start'
			},

			end: {
				type: String,
				default: 'End'
			},

			next: {
				type: String,
				default: 'Next'
			},

			previous: {
				type: String,
				default: 'Previous'
			},

			arrows: {
				type: Boolean,
				default: true
			},

			maxPages: {
				type: Number,
				default: 12
			},

			defaultRow: {
				type: String,
				default: '-'
			},

			defaultCol: {
				type: String,
				default: 'undefined'
			}

		},

		data() {

			return {
				isLoading: true,
				recentSort: null,
				rows: [],
				columns: [],
				pages: [],
				activePage: 0,
				changeFlag: false,
				tools: {
					displayStyleShow: true,
					displayStyle: 'grid',
					pagination: true,
					perPageShow: true,
					perPage: 10,
					searchShow: true,
					searchTerm: ''
				}

			};

		},

		computed: {

			colSizeClass() {

				return [
					this.tools.displayStyle === 'grid' ? 'databox-grid' : 'databox-list-row'
				];

			},

			columnsDisplay() {

				return this.columns.filter(column => !column.hidden);

			},

			rowsDisplay() {

				return this.formatRows();

			},

			// filter objects by parameters match
			filteredRows() {

				return this.rowsDisplay.filter(row => {

					return row.filter(value => {

						let val = (typeof value === 'object') ? value.value : value;

						return val && val
							.toString()
							.toLowerCase()
							.match(this.tools.searchTerm.toLowerCase());

					}).length > 0;

				});

			},

			visiblePages() {

				let start = this.activePage - Math.floor(this.maxPages / 2) > 0 ? this.activePage - Math.floor(this.maxPages / 2) : 0;
				let end = start + this.maxPages < this.pages.length ? start + this.maxPages : this.pages.length;
				if (end - start < this.maxPages && end - this.maxPages >= 0) {

					start = end - this.maxPages;

				}

				return this.pages.slice(start, end);

			}

		},

		watch: {

			displayStyle: {
				handler(val) {

					this.$set(this.tools, 'displayStyle', val === 'grid' ? 'grid' : 'list');

				},
				immediate: true
			},

			displayStyleBtns: {
				handler(val) {

					this.$set(this.tools, 'displayStyleShow', Boolean(val));

				},
				immediate: true
			},

			pagination:{
				handler(val) {

					if (val) {

						this.$set(this.tools, 'pagination', true);

						if (val !== true) {
							this.$set(this.tools, 'perPage', parseInt(val));
						}

					} else {

						this.$set(this.tools, 'pagination', false);
						this.$set(this.tools, 'perPageShow', false);
						this.$set(this.tools, 'perPage', false);

					}

				},
				immediate: true
			},

			perPage: {
				handler(val) {

					if (val && this.tools.pagination) {

						this.$set(this.tools, 'perPageShow', true);

						if (val !== true) {
							this.$set(this.tools, 'perPage', parseInt(val));
						}

					} else {

						this.$set(this.tools, 'pagination', false);
						this.$set(this.tools, 'perPageShow', false);
						this.$set(this.tools, 'perPage', false);

					}

				},
				immediate: true
			},

			search: {
				handler(val) {

					val = (val === true) ? '' : val;

					if (val || val === '') {

						this.$set(this.tools, 'searchTerm', val);
						this.$set(this.tools, 'searchShow', true);

					} else {

						this.$set(this.tools, 'searchTerm', '');
						this.$set(this.tools, 'searchShow', false);

					}

				},
				immediate: true
			},

			loading(val) {

				this.isLoading = val;

			},

			isLoading(val) {

				if (val) {

					this.$emit('loading-start');

				} else {

					this.$emit('loading-stop');

				}

			},

			data: {
				handler(val) {

					if (val.columns) {

						this.columns.forEach(column => {

							if (!val.columns.find(col => col.field === column.field)) {

								let pos = this.columns.indexOf(column);

								if (pos !== -1) {

									this.columns.splice(pos, 1);

								}

							}

						});

						val.columns.forEach(column => {

							let found = this.columns.find(col => col.field === column.field);

							if (!found) {

								this.columns.push(column);
								this.changeFlag = true;

							}

						});

					}

					if (val.rows) {

						this.rows.forEach(row => {

							if (!val.rows.find(r => r.id.value === row.id.value)) {

								let pos = this.rows.indexOf(row);

								if (pos !== -1) {
									this.rows.splice(pos, 1);
								}

							}

						});

						val.rows.forEach(row => {

							let found = this.rows.find(r => r.id.value === row.id.value);

							if (!found) {

								this.rows.push(row);
								this.changeFlag = true;

							}

						});

					}

				},
				immediate: true,
				deep: true
			},

			rows: {
				handler(val, oldVal) {

					// initial sorting

					if (this.order && (this.recentSort === null || this.changeFlag)) {

						if (this.columns[this.order[0]]) {

							this.changeFlag = false;
							this.sort(this.columns[this.order[0]].field, this.order[1]);

						}

					}

					if (this.rows.length && this.isLoading) {
						this.isLoading = false;
					}

				},
				immediate: true
			},

			filteredRows: {
				handler(val) {

					this.buildPages();

				},
				deep: true,
				immediate: true
			},

			'tools.perPage'(val) {

				this.splitPages();

			}

		},

		methods: {

			paramCase: val => paramCase(val),

			colClass(type, field) {

				return 'table-col-' + paramCase(field) + ' ' + type + '-col-' + paramCase(field);

			},

			itemKey(rowIndex, colIndex) {

				return 'row-' + rowIndex + '-col-' + colIndex;

			},

			slotName(col) {

				return 'column-' + paramCase(col.field);

			},

			changePage(index) {

				this.activePage = index;

			},

			buildPages() {

				const pagesAmount = Math.ceil(this.filteredRows.length / this.tools.perPage);

				this.pages = [];

				if (this.pagination) {

					for (let i = 1; i <= pagesAmount; i++) {

						const pageEndIndex = i * this.tools.perPage;
						this.pages.push(this.filteredRows.slice(pageEndIndex - this.tools.perPage, pageEndIndex));

					}

				} else {

					this.pages.push(this.filteredRows);

				}

				this.$emit('pages', this.pages);

			},

			updateDisplayStyle(val) {

				this.$set(this.tools, 'displayStyle', val);
				this.$emit('update-display-style', val);

			},

			updatePerPage(val) {

				this.$set(this.tools, 'perPage', val);
				this.$emit('update-per-page', val);

			},

			splitPages() {

				const pagesAmount = Math.ceil(this.filteredRows.length / this.tools.perPage);

				this.pages = [];

				for (let i = 1; i <= pagesAmount; i++) {

					const pageEndIndex = i * this.tools.perPage;
					this.pages.push(this.filteredRows.slice(pageEndIndex - this.tools.perPage, pageEndIndex));

				}

				this.activePage = this.activePage < this.pages.length ? this.activePage : this.pages.length - 1;

				this.$emit('pages', this.pages);

			},

			sort(field, sort) {

				this.recentSort = {field, sort};

				if (this.sorting) {

					this.rows.sort((a, b) => {

						let valA = (typeof a[field] === 'object') ? a[field].value : a[field];
						let valB = (typeof b[field] === 'object') ? b[field].value : b[field];

						valA = (valA === null || typeof valA === 'undefined') ? '' : valA;
						valB = (valB === null || typeof valB === 'undefined') ? '' : valB;

						let compare;

						if (valA && valA.localeCompare) {

							compare = valA.localeCompare(valB, this.locale, {sensitivity: 'base'});

						} else {

							compare = (valA > valB) ? 1 : (valA < valB) ? -1 : 0;

						}

						if (compare === 0) {

							return 0;

						} else if (compare > 0) {

							return (sort === 'asc') ? 1 : -1;

						} else {

							return (sort === 'asc') ? -1 : 1;

						}

					});

					let key = this.columns.findIndex(column => column.field === field);
					this.columns[key].sort = sort === 'asc' ? 'desc' : 'asc';

				}

			},

			emittedSort(event) {

				this.sort(event.field, event.sort);

			},

			updateSearch(value) {

				this.$set(this.tools, 'searchTerm', value);
				this.$emit('search', value);

				this.activePage = 0;

			},

			escapeRegExp(string) {

				return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

			},

			setDefaultColumns() {

				this.columns.forEach((col, i) => {

					if (!col) {

						this.columns[i] = {
							label: this.defaultCol,
							field: this.defaultCol.concat(i),
							sort: 'asc'
						};

					}

				});

			},

			formatRows() {

				this.setDefaultColumns();

				let arrRows = [];

				let headers = this.columns.map(col => col.field);

				this.rows.map(row => {

					let newRow = [];

					headers.forEach(header => {

						let content = row[header] || this.defaultRow;

						if (typeof content === 'object') {

							content.field = header;

						} else {

							content = {
								value: content,
								display: content,
								field: header
							};

						}

						content.hidden = this.columns.find(col => col.field === header).hidden;

						newRow.push(content);

					});

					arrRows.push(newRow);

				});

				return arrRows;

			}

		}

	};

</script>

<style lang="scss" scoped>

	.databoxes-wrapper {
		margin-bottom: 3rem !important;
	}

	.databoxes-info {
		margin: 1rem 0;
	}

	.databoxes-paginate {

		.pagination {
			flex-wrap: wrap;
			margin: 1rem -0.65rem;

			.page-item {
				a {
					width: auto;
					display: block;
				}
			}
		}

		@include media-breakpoint-up(md) {
			float: right;
		}
	}

	.databoxes-all {
		margin: 0 -15px;
		display: flex;
		flex-wrap: wrap;

		&:before, &:after {
			content: " ";
			display: block;
			clear: both;
		}

		.databox-item {
			margin: 0 15px 1rem;
		}

		/*.databox-row {
			margin: 0 15px 1rem;
			flex: 0 0 1;
			width: 100%;
			overflow: auto;

			.databox {
				min-width: 1200px;
			}

			.box-titles-wrap, .box-values-wrap {
				float: none;
				width: 100%;

				.box-titles {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;

					.box-cell {
						padding: 0;
					}
				}

				.box-titles, .box-values {
					display: flex;
					flex-direction: row;

					.box-cell {
						border-top: 0;
						height: auto;
					}
				}
			}

			.box-titles-wrap {

			}

		}*/



	}

	.databox {
		white-space: pre-line;
		font-size: 0.9rem;
		line-height: normal;
		border-radius: 1rem;
		overflow: hidden;
	}

	.box-cell, .box-cell {
		box-sizing: border-box;
		display: flex;
		align-content: center;
		align-items: center;
		width: 100%;
		height: 2.8rem;
		margin: 0;
		padding: 0.5rem 0;

		& + .box-cell {
			border-top: 1px solid rgba(255,255,255,0.4);
		}
	}

	.box-titles-wrap, .box-values-wrap {
		float: left;
		width: 50%;
	}

	.box-titles, .box-values {
		padding: 1rem;
	}

	.box-titles {
		background: $primary-color;
		color: $white;

		a {
			color: $white;
		}

		.box-cell + .box-cell {
			border-color: rgba(255,255,255,0.4);
		}
	}

	.box-values {
		background: $white;

		.box-cell + .box-cell {
			border-color: rgba(0,0,0,0.15);
		}
	}

	.no-results {
		font-size: 2rem;
		color: $text-muted;
	}

	.databoxes-layout-grid {

		.databoxes-all {

			.databox-item.databox-grid {
				margin-bottom: 1rem;
				width: 100%;

				.table-col-actions-column {
					height: 12rem;

					.btn {
						display: block;
						width: 100%;
						padding-left: 0.5rem;
						padding-right: 0.5rem;

						& + .btn {
							margin-top: 1rem !important;
						}
					}
				}

				@include media-breakpoint-up(lg) {
					flex: 0 0 calc(50% - 2rem);
				}

				@include media-breakpoint-up(xl) {
					flex: 0 0 calc(25% - 2rem);
				}

			}

		}

	}

	.databoxes-layout-list {

		.databoxes-all {
			overflow-x: auto;

			.box-titles-wrap {
				margin: 0 15px 1rem;
				white-space: pre-line;
				font-size: 0.9rem;
				line-height: normal;
				border-radius: 1rem;
				overflow: hidden;
				min-width: 1200px;
			}

			.databox-item.databox-list-row {
				flex: 0 0 1;
				width: 100%;

				.databox {
					min-width: 1200px;
				}
			}

			.box-titles-wrap {
				float: none;
			}

			.box-titles-wrap, .box-values-wrap {
				float: none;
				width: 100%;

				.box-titles {
					.box-cell {
						padding: 0;
					}
				}

				.box-titles, .box-values {
					display: flex;
					flex-direction: row;

					.box-cell {
						border-top: 0;
						height: auto;
					}
				}
			}

		}

	}

	.status-tag {
		background: $light;
		border-radius: 4px;
		padding: 0.05em 0.5em;
		font-size: 12px;
		display: inline-flex;
		align-content: center;
		align-items: center;
		line-height: 1em;
		height: 2em;
		white-space: nowrap;

		&:before {
			content: "•";
			font-size: 20px;
			margin-right: 0.25em;
		}

		&.status-tag-incomplete {
			$color0: $gray-300;
			background: $color0;
			color: darken($color0, 60%);
		}

		&.status-tag-approved {
			$color1: #bff99f;
			background: $color1;
			color: darken($color1, 65%);
		}

		&.status-tag-funded {
			$color2: #b3e2ff;
			background: $color2;
			color: darken($color2, 60%);
		}

		&.status-tag-not-approved {
			$color3: #ffc4ba;
			background: $color3;
			color: darken($color3, 50%);
		}

		&.status-tag-cancelled {
			$color4: $gray-500;
			background: $color4;
			color: darken($color4, 50%);
		}

		&.status-tag-pending, &.status-tag-bureau-not-found {
			$color5: #fff2d1;
			background: $color5;
			color: darken($color5, 60%);
		}

		&.status-tag-submitted-to-kamsel {
			$color6: #f3ddff;
			background: $color6;
			color: darken($color6, 60%);
		}

	}

</style>
