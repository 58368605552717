<template>

	<fieldset class="mt-5" @submit.prevent="onSubmit">

		<legend>
			<h1 class="text-center">{{ title }}</h1>
		</legend>


		<section>

			<div class="container-xl">
				<div class="row">
					<div class="col-lg-24">
						<div class="dashboard-item-mobile__entry tffi-card tffi-card-main-card">
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Created Date</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1">
									<p>
										Nov 2nd 2023 05:07 am
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Full Name</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										John Doe
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Status</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										Incomplete
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Loan #</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										-
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Amount</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										$649.00
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Phone #</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										416-123-4567
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Address</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										123 Fake Street
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">City</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										Toronto
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Actions Available</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>

									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-24">
						<div class="dashboard-item-mobile__entry tffi-card tffi-card-main-card">
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Created Date</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1">
									<p>
										Nov 2nd 2023 05:07 am
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Full Name</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										John Doe
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Status</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										Incomplete
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Loan #</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										-
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Amount</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										$649.00
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Phone #</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										416-123-4567
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Address</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										123 Fake Street
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">City</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										Toronto
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Actions Available</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>

									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-24">
						<div class="dashboard-item-mobile__entry tffi-card tffi-card-main-card">
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Created Date</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1">
									<p>
										Nov 2nd 2023 05:07 am
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Full Name</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										John Doe
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Status</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										Incomplete
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Loan #</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										-
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Amount</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										$649.00
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Phone #</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										416-123-4567
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Address</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										123 Fake Street
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">City</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>
										Toronto
									</p>
								</div>
							</div>
							<div class="dashboard-item-mobile__entry__item d-flex flex-row">
								<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
									<p class="fw-bold">Actions Available</p>
								</div>
								<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
									<p>

									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h1>Mobile Dashboard Card</h1>
			<div class="dashboard-item-mobile__entry tffi-card tffi-card-main-card">
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Created Date</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1">
						<p>
							Nov 2nd 2023 05:07 am
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Full Name</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							John Doe
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Status</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							Incomplete
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Loan #</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							-
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Amount</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							$649.00
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Phone #</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							416-123-4567
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Address</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							123 Fake Street
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">City</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>
							Toronto
						</p>
					</div>
				</div>
				<div class="dashboard-item-mobile__entry__item d-flex flex-row">
					<div class="dashboard-item-mobile__entry__item w-100 d-flex flex-grow-1">
						<p class="fw-bold">Actions Available</p>
					</div>
					<div class="dashboard-item-mobile__entry__details w-100 d-flex flex-grow-1 justify-content-start">
						<p>

						</p>
					</div>
				</div>
			</div>

			<h1>Delete Modal</h1>

			<button type="button" class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid" data-bs-toggle="modal" data-bs-target="#deleteModal">
				<i class="p-2 fas fa-trash"></i>
			</button>

			<!-- Modal -->
			<div class="modal fade delete-modal" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-body p-0">
							<p>Are you sure you want to delete the modal?</p>
						</div>
						<div class="modal-footer d-flex p-0">
							<div>
								<button type="button" class="tffi-btn tffi-btn--solid tffi-btn--bold fw-bold theming accent-color primary-color-btn-solid" data-bs-dismiss="modal">Cancel</button>
							</div>
							<div class="flex-grow-1"></div>
							<div>
								<button type="button" class="tffi-btn tffi-btn--solid tffi-btn--bold fw-bold theming primary-color primary-color-btn-solid">Yes</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h1>E- Signature Template</h1>

			<!--e-signature-template-->
			<div class="e-signature-template">
				<div class="container-xl">
					<div class="row">
						<div class="col-24">
							<div class="template__page-title">
								<h1>E-Signature</h1>
							</div>
							<div class="template__page-description">
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque incidunt pariatur impedit doloremque corrupti temporibus. Hic quos quo nisi quaerat, facere maxime animi, suscipit ducimus perferendis asperiores unde, repellat quae!
								</p>
							</div>
						</div>
					</div>
				</div>

				<div class="mb-5"></div>

				<form @submit.prevent="onSubmit" novalidate>
					<div class="container-xl template--vh">
						<div class="row">
							<div class="col-lg-12">
								<div class="tffi-card tffi-card-main-card e-signature-card theming secondary-color">
									<div class="row">
										<div class="col-lg-12 d-flex align-items-center">
											<p class="my-0">
												Lorem ipsum dolor sit amet, consectetur adipisicing elit.
											</p>
										</div>
										<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
											<button href="#" class="tffi-btn tffi-btn--solid theming accent-color mx-auto-lg w-100 fw-bold">
												Sign with e-signature
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-12">
								<div class="tffi-card tffi-card-main-card signed-contract-card">
									<div class="row">
										<div class="col-lg-12 d-flex align-items-center">
											<p class="my-0">
												Lorem ipsum dolor sit amet, consectetur adipisicing elit.
											</p>
										</div>
										<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
											<button href="#" class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
												Upload Signed Contract
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

			<h1>
				Upload Template
			</h1>



			<h1>
				Upload 2 Template
			</h1>

			<div class="upload-template">

				<div class="container-xl">
					<div class="row">
						<div class="col-24">
							<div class="template__page-title">
								<h1>Upload Files</h1>
							</div>
							<div class="template__page-description mb-5">
								<p>
									Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur ullam necessitatibus eaque itaque veritatis? Nemo possimus, culpa natus rerum ex cum at molestias beatae quae voluptatum quibusdam voluptatem! Dolores, architecto.
								</p>
							</div>
						</div>
					</div>

					<form @submit.prevent="onSubmit" novalidate>
						<div class="container-xl template--vh">
							<div class="row">
								<div class="col-lg-8">
									<div class="tffi-upload-card-mini d-flex flex-row my-1">
										<div class="d-flex align-items-center me-4">
											<input type="checkbox" name="" id="" class="tffi-input-checkbox theming primary-color">
										</div>
										<div class="flex-grow-1">
											<div class="tffi-card tffi-card-main-card d-flex flex-row p-4">
												<div class="d-flex flex-grow-1">
													<p class="my-0">Bill of Sale/Invoice*</p>
												</div>
												<div>
													<div class="status-accepted">
														<img src="../../../assets/img/icons/check-mark.svg" alt="">
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="tffi-upload-card-mini d-flex flex-row my-1">
										<div class="d-flex align-items-center me-4">
											<input type="checkbox" name="" id="" class="tffi-input-checkbox theming primary-color">
										</div>
										<div class="flex-grow-1">
											<div class="tffi-card tffi-card-main-card d-flex flex-row p-4">
												<div class="d-flex flex-grow-1">
													<p class="my-0">Bill of Sale/Invoice*</p>
												</div>
												<div>
													<div class="status-accepted">
														<img src="../../../assets/img/icons/check-mark.svg" alt="">
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="tffi-upload-card-mini d-flex flex-row my-1">
										<div class="d-flex align-items-center me-4">
											<input type="checkbox" name="" id="" class="tffi-input-checkbox theming primary-color">
										</div>
										<div class="flex-grow-1">
											<div class="tffi-card tffi-card-main-card d-flex flex-row p-4">
												<div class="d-flex flex-grow-1">
													<p class="my-0">Bill of Sale/Invoice*</p>
												</div>
												<div>
													<div class="status-accepted">
														<img src="../../../assets/img/icons/check-mark.svg" alt="">
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="tffi-upload-card-mini d-flex flex-row my-1">
										<div class="d-flex align-items-center me-4">
											<input type="checkbox" name="" id="" class="tffi-input-checkbox theming primary-color">
										</div>
										<div class="flex-grow-1">
											<div class="tffi-card tffi-card-main-card d-flex flex-row p-4">
												<div class="d-flex flex-grow-1">
													<p class="my-0">Bill of Sale/Invoice*</p>
												</div>
												<div>
													<div class="status-accepted">
														<img src="../../../assets/img/icons/check-mark.svg" alt="">
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="tffi-upload-card-mini d-flex flex-row my-1">
										<div class="d-flex align-items-center me-4">
											<input type="checkbox" name="" id="" class="tffi-input-checkbox theming primary-color">
										</div>
										<div class="flex-grow-1">
											<div class="tffi-card tffi-card-main-card d-flex flex-row p-4">
												<div class="d-flex flex-grow-1">
													<p class="my-0">Bill of Sale/Invoice*</p>
												</div>
												<div>
													<div class="status-accepted">
														<img src="../../../assets/img/icons/check-mark.svg" alt="">
													</div>
												</div>
											</div>
										</div>
									</div>

									<button class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100">
										Add Other Document
									</button>

									<hr class="my-5">

									<div class="tffi-card tffi-card-main-card theming secondary-color">
										<div class="row">
											<div class="col-lg-14 d-flex align-items-center">
												<p class="mb-0">
													Send files to send link to customer
												</p>
											</div>
											<div class="col-lg-10 d-flex align-items-center justify-content-center justify-content-xl-end">
												<button class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid">
													Send Link
												</button>
											</div>
										</div>
									</div>

									<div class="template--section-divider"></div>
									<div class="template--section-divider"></div>

								</div>
								<div class="col-lg-16">

									<div class="tffi-card tffi-card-upload-card theming primary-color">
										<p class="tffi-card-upload-card__title theming primary-color">Drag & Drop Files to Upload!</p>
										<div class="tffi-card-upload-card__image">
											<!-- <img src="../../../assets/img/icons/upload.svg" alt=""> -->
											<svg xmlns="http://www.w3.org/2000/svg" width="124.746" height="64.749" viewBox="0 0 124.746 64.749">
												<g id="Group_1108" data-name="Group 1108" transform="translate(-902 -346)">
													<g id="Group_374" data-name="Group 374" transform="translate(740.061 -309.916)">
													<g id="Group_373" data-name="Group 373" transform="translate(156.781 648)">
														<g id="Group_371" data-name="Group 371" transform="translate(23.912 14.288)">
														<path id="Path_4192" data-name="Path 4192" d="M1285,1333.284l-24.808,5.771c-.693.162-1.122.85-.869,1.935l10.555,45.374a1.322,1.322,0,0,0,1.451.565l34.257-7.969c.69-.161,1.118-.85,1.049-1.147l-8.357-35.923c-.139-.6-.262-.77-.432-.876l-12.261-7.634a.774.774,0,0,0-.585-.1Zm0,0" transform="translate(-1259.254 -1333.264)" fill="#fff" stroke="#c18f14" stroke-miterlimit="10" stroke-width="2"/>
														<path id="Path_4193" data-name="Path 4193" d="M1284,1333.468l2.357,10.131,10.126-2.355Zm0,0" transform="translate(-1257.86 -1333.252)" fill="#fff" stroke="#c18f14" stroke-linejoin="round" stroke-width="2"/>
														<line id="Line_292" data-name="Line 292" x2="9.484" transform="translate(11.953 19.483) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
														<line id="Line_293" data-name="Line 293" x2="21.428" transform="translate(13.472 26.065) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
														<line id="Line_294" data-name="Line 294" x2="21.428" transform="translate(14.992 32.646) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
														<line id="Line_295" data-name="Line 295" x2="21.428" transform="translate(16.511 39.228) rotate(-13)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/>
														</g>
														<g id="Group_372" data-name="Group 372" transform="translate(69.157 4.802) rotate(20)">
														<rect id="Rectangle_799" data-name="Rectangle 799" width="42.555" height="42.555" rx="6.394" transform="translate(12.872 8.296)" fill="#fff" stroke="#c18f14" stroke-miterlimit="10" stroke-width="1.749"/>
														<path id="Path_4199" data-name="Path 4199" d="M1241.585,1391.816h32.61L1264.927,1375l-9.612,12.014-6.521-6.864Z" transform="translate(-1223.522 -1347.241)" fill="#d6b943"/>
														<circle id="Ellipse_39" data-name="Ellipse 39" cx="3.089" cy="3.089" r="3.089" transform="translate(22.183 14.375)" fill="#d6b943"/>
														</g>
													</g>
													</g>
													<g id="Group_1104" data-name="Group 1104" transform="translate(-524.254 -120)">
													<line id="Line_296" data-name="Line 296" x2="10" transform="translate(1539.5 472.5)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
													<line id="Line_297" data-name="Line 297" y2="10" transform="translate(1544.5 467.5)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
													</g>
													<g id="Group_1105" data-name="Group 1105" transform="translate(-621.922 -69.853)">
													<line id="Line_296-2" data-name="Line 296" x2="6.353" transform="translate(1539.5 470.676)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
													<line id="Line_297-2" data-name="Line 297" y2="6.353" transform="translate(1542.676 467.5)" fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3"/>
													</g>
													<g id="Group_1106" data-name="Group 1106" transform="translate(-636 -115.128)">
													<line id="Line_296-3" data-name="Line 296" x2="5.128" transform="translate(1539.5 470.064)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-width="3"/>
													<line id="Line_297-3" data-name="Line 297" y2="5.128" transform="translate(1542.064 467.5)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-width="3"/>
													</g>
													<g id="Group_1107" data-name="Group 1107" transform="translate(-526.818 -79.128)">
													<line id="Line_296-4" data-name="Line 296" x2="5.128" transform="translate(1539.5 470.064)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-width="3"/>
													<line id="Line_297-4" data-name="Line 297" y2="5.128" transform="translate(1542.064 467.5)" fill="none" stroke="#c18f14" stroke-linecap="round" stroke-width="3"/>
													</g>
												</g>
												</svg>
										</div>
										<p class="tffi-card-upload-card__or">or</p>
										<div>
											<button class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color">Choose Files</button>
										</div>
									</div>

									<hr class="my-5">

									<div class="tffi-card tffi-card-upload-progress-card">
										<div class="row">
											<div class="col-24">
												<!--item-->
												<div class="tffi-card-upload-progress-card__item d-flex flex-row">
													<div class="tffi-card-upload-progress-card__item__left-area d-flex flex-column flex-grow-1 justify-content-center me-5">
														<div class="tffi-card-upload-progress-card__item__item-description d-flex flex-row">
															<div class="tffi-card-upload-progress-card__item__item-description__file-name">
																<p>Government ID</p>
															</div>
															<div class="flex-grow-1"></div>
															<div class="tffi-card-upload-progress-card__item__item-description__file-size">
																<p>7.5MB</p>
															</div>
														</div>
														<div class="tffi-card-upload-progress-card__item__progress-bar progress" style="height: 8px;">
															<div class="progress-bar theming accent-color" style="width:25%;" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="tffi-card-upload-progress-card__item__progress-percentage">
															<p class="theming primary-color">64% done</p>
														</div>
													</div>
													<div class="tffi-card-upload-progress-card__item__right-area">
														<div class="tffi-card-upload-progress-card__item__controls d-flex align-items-center h-100">
															<button class="tffi-card-upload-progress-card__item__controls__stop">
																<img src="../../../assets/img/icons/stop.svg" alt="">
															</button>
														</div>
													</div>
												</div>

												<!--item-->
												<div class="tffi-card-upload-progress-card__item d-flex flex-row">
													<div class="tffi-card-upload-progress-card__item__left-area d-flex flex-column flex-grow-1 justify-content-center me-5">
														<div class="tffi-card-upload-progress-card__item__item-description d-flex flex-row">
															<div class="tffi-card-upload-progress-card__item__item-description__file-name">
																<p>Government ID</p>
															</div>
															<div class="flex-grow-1"></div>
															<div class="tffi-card-upload-progress-card__item__item-description__file-size">
																<p>7.5MB</p>
															</div>
														</div>
														<div class="tffi-card-upload-progress-card__item__progress-bar progress" style="height: 8px;">
															<div class="progress-bar theming accent-color" style="width:25%;" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
														</div>
														<div class="tffi-card-upload-progress-card__item__progress-percentage">
															<p class="theming primary-color">64% done</p>
														</div>
													</div>
													<div class="tffi-card-upload-progress-card__item__right-area">
														<div class="tffi-card-upload-progress-card__item__controls d-flex align-items-center h-100">
															<button class="tffi-card-upload-progress-card__item__controls__stop">
																<img src="../../../assets/img/icons/stop.svg" alt="">
															</button>
														</div>
													</div>
												</div>
											</div>

											<div class="col-lg-24">

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="tffi-navigation-bottom">
							<div class="container-xl">
								<div class="row">
									<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
										<button
											type="button"
											class="tffi-btn tffi-btn--solid theming secondary-color"
											color="primary"
											:loading="goingBack"
											@click="goBack({ name: 'ApplicationStep2', params: $route.params })"
										>
											{{ $t('forms.back') }}
										</button>
									</div>
									<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
										<button
											type="button"
											class="tffi-btn tffi-btn--solid theming secondary-color ms-auto"
											@click="saveForLater"
											>
											Save for later
										</button>
									</div>
									<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
										<button
											class="tffi-btn tffi-btn--solid theming primary-color me-0"
											@click="goToStep(4)"
											>
											Next
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			<h1>Dashboard 2</h1>

			<div class="dashboard-template">
				<div class="container-xl">
					<div class="row">
						<div class="col-24">
							<div class="template__page-title">
								<h1>Dashboard</h1>
							</div>
						</div>
					</div>
				</div>

				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-8">
							<div class="tffi-card tffi-card-summary-card">
								<div class="tffi-card-summary-card__top-area theming primary-color">
									<!-- <div class="tffi-card-summary-card__top-area__overlay-gradient"></div> -->
									<div class="tffi-card-summary-card__top-area__overlay-image"></div>
									<div class="tffi-card-summary-card__top-area__title-area">
										<h2>Installment loan estimate</h2>
									</div>
									<div class="tffi-card-summary-card__top-area__financed-amount">
										<p>Financed Amount: <span class="tffi-card-summary-card__top-area__financed-amount__price">$2000</span></p>
									</div>
								</div>
								<div class="tffi-card-summary-card__bottom-area">
									<div class="tffi-card-summary-card__bottom-area__label">
										<p>Program Type:</p>
										<span class="flex-grow-1"></span>
										<p>Standard</p>
									</div>
									<div class="tffi-card-summary-card__bottom-area__label">
										<p>Rate:</p>
										<span class="flex-grow-1"></span>
										<p>%10.99</p>
									</div>
									<div class="tffi-card-summary-card__bottom-area__label">
										<p>Finance Term:</p>
										<span class="flex-grow-1"></span>
										<p>60 Month</p>
									</div>
									<div class="tffi-card-summary-card__bottom-area__label">
										<p>Amortization Term:</p>
										<span class="flex-grow-1"></span>
										<p>144 Month</p>
									</div>
									<div class="tffi-card-summary-card__bottom-area__label">
										<p class="theming primary-color fw-bold">Base Monthly Payment:</p>
										<span class="flex-grow-1"></span>
										<p class="theming primary-color fw-bold">$5000</p>
									</div>
								</div>
							</div>
						</div>

						<div class="col-8">
							<div class="tffi-card tffi-card-secondary-card">
								<div class="tffi-card-secondary-card__left-area">
									<div class="tffi-card-secondary-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-secondary-card__right-area">
									<div class="tffi-card-secondary-card__right-area__title-area">
										<h2>Applicant</h2>
									</div>
									<div class="tffi-card-secondary-card__right-area__content-area">
										<p>John Doe</p>
										<p>JohnDoe@email.com</p>
									</div>
									
								</div>
							</div>

							<div class="tffi-card tffi-card-secondary-card">
								<div class="tffi-card-secondary-card__left-area">
									<div class="tffi-card-secondary-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-secondary-card__right-area">
									<div class="tffi-card-secondary-card__right-area__title-area">
										<h2>Applicant</h2>
									</div>
									<div class="tffi-card-secondary-card__right-area__content-area">
										<p>John Doe</p>
										<p>JohnDoe@email.com</p>
									</div>
									
								</div>
							</div>

							<div class="tffi-card tffi-card-secondary-card">
								<div class="tffi-card-secondary-card__left-area">
									<div class="tffi-card-secondary-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-secondary-card__right-area">
									<div class="tffi-card-secondary-card__right-area__title-area">
										<h2>Applicant</h2>
									</div>
									<div class="tffi-card-secondary-card__right-area__content-area">
										<p>John Doe</p>
										<p>JohnDoe@email.com</p>
									</div>
									
								</div>
							</div>
						</div>

						<div class="col-8">
							<div class="tffi-card tffi-card-secondary-card">
								<div class="tffi-card-secondary-card__left-area">
									<div class="tffi-card-secondary-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-secondary-card__right-area">
									<div class="tffi-card-secondary-card__right-area__title-area">
										<h2>Equipment</h2>
									</div>
									<div class="tffi-card-secondary-card__right-area__content-area">
										<p>Type: Dishwasher</p>
										<p>Brand: Whirlpool</p>
										<p>Model: 1234567</p>
									</div>
								</div>
							</div>

							<div class="tffi-card tffi-card-secondary-card">
								<div class="tffi-card-secondary-card__left-area">
									<div class="tffi-card-secondary-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-secondary-card__right-area">
									<div class="tffi-card-secondary-card__right-area__title-area">
										<h2>Equipment</h2>
									</div>
									<div class="tffi-card-secondary-card__right-area__content-area">
										<p>Type: Dishwasher</p>
										<p>Brand: Whirlpool</p>
										<p>Model: 1234567</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="container-xl">
					<div class="row">
						<div class="col-24">
							<hr class="theming primary-color my-5">
						</div>
					</div>
				</div>

				<div class="container-xl">
					<div class="row">
						<div class="col-8">

							<div class="tffi-card tffi-card-status-list-card">
								<div class="tffi-card-status-list-card__left-area">
									<div class="tffi-card-status-list-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-status-list-card__right-area">
									<div class="tffi-card-status-list-card__right-area__title-area">
										<h2>E-Signatures</h2>
									</div>
									<div class="tffi-card-status-list-card__right-area__content-area">
										<ul class="tffi-card-status-list-card__list list-unstyled">
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													John Doe
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Jane Doe
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Sophie Zhang
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Rich Finlay
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>

						</div>

						<div class="col-8">
							
							<div class="tffi-card tffi-card-status-list-card upload-files-card">
								<div class="tffi-card-status-list-card__left-area">
									<div class="tffi-card-status-list-card__left-area__status-indicator">
										<div class="status-accepted">
											<img src="../../../assets/img/icons/check-mark.svg" alt="">
										</div>
										<!-- <div class="status-denied">
											<img src="../../../assets/img/icons/stop.svg" alt="">
										</div> -->
									</div>
								</div>
								<div class="tffi-card-status-list-card__right-area">
									<div class="tffi-card-status-list-card__right-area__title-area">
										<h2>Upload Files</h2>
									</div>
									<div class="tffi-card-status-list-card__right-area__content-area">
										<ul class="tffi-card-status-list-card__list list-unstyled">
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Bill of Sale / Invoice *
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Completion Certificate *
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													PAP Form / Void Cheque *
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Government ID *
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Income Verification
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
											<li class="tffi-card-status-list-card__list__item">
												<span class="tffi-card-status-list-card__list__item__name">
													Install Pictures
												</span>
												<span class="flex-grow-1"></span>
												<span class="tffi-card-status-list-card__list__item__status">
													<img src="../../../assets/img/icons/check-mark.svg" alt="">
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>

						</div>

						<div class="col-8">

						</div>
					</div>
				</div>

				<div class="tffi-navigation-bottom">
					<div class="container-xl">
						<div class="row">
							<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
								<button
									type="button"
									class="tffi-btn tffi-btn--solid theming secondary-color"
									color="primary"
									:loading="goingBack"
									@click="goBack({ name: 'ApplicationStep2', params: $route.params })"
								>
									{{ $t('forms.back') }}
								</button>
							</div>
							<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
								<button
									type="button"
									class="tffi-btn tffi-btn--solid theming secondary-color ms-auto"
									@click="saveForLater"
									>
									Save for later
								</button>
							</div>
							<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
								<button
									class="tffi-btn tffi-btn--solid theming primary-color me-0"
									@click="goToStep(4)"
									>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h1>Confirmation Card Template</h1>

			<div class="confirmation-card-template">
				<div class="container-xl template--vh">
					<div class="row h-100 d-flex align-items-center justify-content-center">
						<div class="col-24">
							<div class="tffi-card tffi-card-confirmation-card mx-auto">
								<i class="tffi-card-confirmation-card__icon fa-solid fa-circle-check theming secondary-color"></i>
								<p class="tffi-card-confirmation-card__text">
									The link has been sent to xxx@gmail.com successfully.
								</p>
								<button href="#" class="tffi-btn tffi-btn--solid theming accent-color mx-auto-lg fw-bold px-5">
									Ok
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

		</section>

	</fieldset>

</template>

<script>

	import {mapActions, mapState} from 'vuex';
	import Spinner from 'vue-loading-overlay/src/loaders/spinner';

	export default {

		components: {
			Spinner
		},

		data() {

			return {
				vModels: {
					userName: '',
					password: ''
				},
				submitting: false,
				errorAlert: null
			};

		},

		computed: {

			...mapState({
				title: state => state.app.title
			})

		},

		mounted() {

			this.$emit('ready', true);

			if (process.env.VUE_APP_DEBUG) {

				this.setDebugValues();

			}

		},

		beforeDestroy() {

			this.removeAlert(this.errorAlert);

		},

		methods: {

			...mapActions([
				// 'saveLoading',
				// 'showErrorAlert',
				// 'removeAlert',
				// 'loginAction'
			]),

			async onSubmit() {

				this.submitting = true;

				this.removeAlert(this.errorAlert);

				let result = await this.loginAction(this.vModels).catch(async error => {

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					console.log('error', error);
					console.log('error.response', error.response);
					console.log('error.response.data', error.response.data);

					if (errorMsg) {

						console.log('errorMsg', errorMsg);

						this.errorAlert = await this.showErrorAlert(errorMsg);

						if (errorMsg == "Password Expired") {

							return errorMsg;

						}

					}

				});

				console.log('result', result);

				if (result) {

					if (result == "Password Expired") {

						await this.$router.push({
							name: 'ChangePassword'
						});
						
						// this.vModels.userName = 'sdsd';
						// this.userName = 'sdsd';
						// this.$set(this.vModels, 'userName', 'use');

						return await this.showErrorAlert(this.$i18n.t('auth.changePassword.pleaseChangePassword'));

					}

					let redirect = {
						name: 'List',
						params: { status: 'all' }
					};

					if (this.$route.query.redirect) {

						redirect = {
							path: this.$route.query.redirect
						};

					}

					return this.$router.push(redirect);

				}

				this.submitting = false;

			},

			setDebugValues() {

				if (this.vModels && Object.keys(this.vModels).length) {

					Object.keys(this.vModels).forEach(key => {

						let val;

						switch (key) {

							case 'userName':
								val = process.env.VUE_APP_FORMS_PRE_FILLED_USERNAME;
								break;

							case 'password':
								val = process.env.VUE_APP_FORMS_PRE_FILLED_PASSWORD;
								break;

						}

						if (val) {

							this.$set(this.vModels, key, val);

						}

					});

				}

			}

		}

	};

</script>

<style lang="scss" scoped>

.text-white {
	color: white;
}

</style>