<template>

	<loading active color="#008093"></loading>

</template>

<script>

	import { mapActions } from 'vuex';

	export default {

		async created() {

			this.saveLoading(true);

			this.removeAlerts();

			let response = await this.logoutAction().catch(error => {

				let errorMsg = error.toString();

				if (error.response && error.response.data && error.response.data.errorMessage) {

					errorMsg = error.response.data.errorMessage;

				}

				if (errorMsg) {

					this.showErrorAlert(errorMsg);

				}

			});

			this.$emit('ready', 'logout');

			if (response) {

				return this.$router.push({name: 'Login'});

			} else {

				this.$router.go(-1);

			}

		},

		methods: {

			...mapActions([
				'saveLoading',
				'showErrorAlert',
				'removeAlerts',
				'logoutAction'
			])

		}

	};

</script>
