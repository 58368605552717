import Vue from 'vue';
import assignDeep from 'assign-deep';

export default {

	SAVE_APPLICATION_OPTIONS: (state, payload) => {

		Vue.set(state, 'options', payload);

	},

	SAVE_ASSET_TYPES: (state, payload) => {

		Vue.set(state, 'assetTypes', payload);

	},

	SAVE_VALIDATE_ALL: (state, payload) => {

		Vue.set(state, 'validateAll', payload);

	},

	SAVE_APPLICATION_INFO: (state, payload) => {

		if (payload === false) {

			Vue.set(state, 'info', {
				data: {},
				errors: {}
			});

			return;

		}

		Vue.set(state, 'info', assignDeep(state.info, payload));

	},

	SAVE_APPLICATION_SECTIONS: (state, payload) => {

		if (payload === false) {

			Vue.set(state, 'sections', {
				curAddressLess: false
			});

			return;

		}

		Vue.set(state, 'sections', assignDeep(state.sections, payload));

	},

	SAVE_CO_BORROWER: (state, payload) => {

		if (payload === false) {

			Vue.set(state, 'coBorrower', {
				data: {},
				errors: {}
			});

			return;

		}

		Vue.set(state, 'coBorrower', assignDeep(state.coBorrower, payload));

	},

	SAVE_APPLICATION_LIST_ITEM: (state, payload) => {

		if (!payload.key) {

			return false;

		}

		Vue.set(state.applicationLists, payload.key, payload);

		return true;

	}

};
