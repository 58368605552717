let handler;

// for pretty localized currency text display
export const formatCurrency = (val, locale) => {

	locale = locale || 'en';

	// console.log('locale', locale);

	let money = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: 'CAD'
	}).format(val);

	// console.log('money', money);

	if (locale === 'fr') {

		money = money.replace('$CA', '$').replace(',', '.');

	} else {

		money = money.replace('CA$', '$');

	}

	return money;

};

// for form field
const formatValue = (val, locale, event) => {

	let num = '' + val;

	let decimalSeparator = '.'; //(locale === 'fr') ? ',' : '.';
	let thousandSeparator = ''; //(locale === 'fr') ? ' ' : '';

	num = num.trim();
	num = num.replace(decimalSeparator, '.');
	num = num.replace(thousandSeparator, '');

	let matches = num.match(/^(\$ *)?(.*?)( *\$)?$/);

	if (matches && matches.length) {

		let parsedNum = matches[2];

		// let enDollar = matches[1];
		// let frDollar = matches[3];

		num = parseFloat(parsedNum);

	} else {

		num = parseFloat(num);

	}

	if (isNaN(num)) {

		if (typeof num !== 'string' && (event && event.type === 'change')) {

			return '';

		}

		return;

	}

	let split = num.toFixed(2).toString().split('.');
	let dollars = split[0];
	let cents = split[1] || '00';

	dollars = dollars.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${thousandSeparator}`);

	num = [dollars, cents].join(decimalSeparator);

	return num;

};

export default {

	twoWay: true,

	bind(el, binding, vnode) {

		let locale = (binding.expression) ? vnode.context[binding.expression] : 'en';

		handler = (event) => {

			let _input = vnode.elm.querySelector('input');

			let val = formatValue(_input.value, locale, event);

			if (val === '') {

				_input.value = '';
				vnode.componentInstance.$emit('input', '');
				return;

			}

			if (typeof val === 'string') {

				// console.log('--', _input.name, _input.value, val, (_input.value !== val), event);

				if (_input.value !== val) {

					_input.value = val;
					let event = new Event('input');
					_input.dispatchEvent(event/*new CustomEvent('change', {detail: {value: val}})*/);
					vnode.componentInstance.$emit('input', val);

				}

			}

		};

		if (binding.expression) {

			let _self = this;

			vnode.context.$watch(binding.expression, (val) => {

				locale = val;
				_self.handler();

			});

		}

		el.addEventListener('change', handler);
		el.addEventListener('focusout', handler); // for MS Edge
		el.addEventListener('manualUpdate', handler); // in input watch

	},

	/*update(el, binding, vnode) {

		vnode.context.$nextTick(() => {

			console.log('update');
			handler();

		});

	},*/

	unbind(el) {

		el.removeEventListener('change', handler);
		el.removeEventListener('focusout', handler); // for MS Edge
		el.removeEventListener('manualUpdate', handler);

	}

};
