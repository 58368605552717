import { submitApplication, saveApplication, saveCoBorrower, getOptions } from '../../api';

export const getApplicationsOptions = async ({ commit, state }) => {

	if (state.options && Object.keys(state.options).length) {

		return state.options;

	}

	let result = await getOptions();

	if (result) {

		commit('SAVE_APPLICATION_OPTIONS', result);

		return result;

	}

};


// export const getAssetTypes = async ({ commit, state }) => {

// 	if (state.assetTypes && Object.keys(state.assetTypes).length) {

// 		return state.assetTypes;

// 	}

// 	let result = await getProgramAssetTypes();

// 	if (result) {

// 		commit('SAVE_ASSET_TYPES', result);

// 		return result;

// 	}

// };

export const saveValidateAll = ({ commit }, payload) => {

	commit('SAVE_VALIDATE_ALL', payload);

};

export const submitApplicationAction = async ({ commit, state }, id) => {

	id = id || state.info.data.id;

	let result = await submitApplication(id).catch(error => {

		if (error.response && error.response.data && error.response.data.errorMessages) {

			let ApplicationErrors = error.response.data.errorMessages.application;
			let CoApplicationErrors = error.response.data.errorMessages.coApplicant;

			if (Object.keys(ApplicationErrors).length) {

				let newPayload = {
					errors: ApplicationErrors
				};

				commit('SAVE_APPLICATION_INFO', newPayload);

			}

			if (Object.keys(CoApplicationErrors).length) {

				let payload = {
					errors: CoApplicationErrors
				};

				commit('SAVE_CO_BORROWER', payload);

			}

		}

		throw error;

	});

	if (result) {

		let newPayload = {
			data: Object.assign({}, result)
		};

		commit('SAVE_APPLICATION_INFO', newPayload);

		// console.log('[submitApplicationAction]\n', state.info && state.info.data && state.info.data.status);

		return newPayload.data.status;

	}

};

export const resetApplicationAction = ({ commit }) => {

	console.log('resetApplicationAction');

	commit('SAVE_APPLICATION_INFO', false);
	commit('SAVE_APPLICATION_SECTIONS', false);
	commit('SAVE_CO_BORROWER', false);
	commit('SAVE_VALIDATE_ALL', false);

};

export const saveApplicationAction = async ({ commit, state, dispatch }, payload) => {

	console.log('saveApplicationAction payload', payload);

	if (!payload.token) {
		commit('SAVE_APPLICATION_INFO', payload);
	}
	
	// if (['GuestApplication'].includes(this.$route.name)) {
		// commit('SAVE_APPLICATION_INFO', payload);
		// return;
	// }

	let id = payload.id; // state.info.data.id;

	let modifiedApp = Object.assign({}, state.info.data);
	delete modifiedApp.amountApproved;
	delete modifiedApp.rentalAmount;

	let application = await saveApplication(modifiedApp).catch(err => {

		console.debug('getApplicationData err:', JSON.stringify(err, null, 2));

		if (err.response && err.response.data && err.response.data.errorMessage) {

			dispatch('showErrorAlert', err.response.data.errorMessage);

		} else if (err.message && err.message !== 'Network Error') {

			dispatch('showErrorAlert', err.message);

		}

	});

	if (
		state.info.data.token
		|| payload.token
	) {
		// this.showSuccessAlert('Application saved');
		return true;
	}

	if (application && application.id !== id) {

		console.log('new id given');

		let newPayload = {
			data: {
				id: application.id
			}
		};

		// console.debug('[saveApplicationAction] got diffrerent ID so saving it =\n', application.id);

		commit('SAVE_APPLICATION_INFO', newPayload);

		return application.id;

	}

	console.log('application', application);

	return application.id

};

export const saveApplicationSections = ({ commit }, payload) => {

	commit('SAVE_APPLICATION_SECTIONS', payload);

};

export const saveCoBorrowerAction = async ({ commit, state }, payload) => {

	payload.data.id = state.info.data.id;

	commit('SAVE_CO_BORROWER', payload);

	let coBorrower = await saveCoBorrower(state.coBorrower.data);

	return coBorrower;

};

export const saveApplicationListItem = ({ commit }, payload) => {

	commit('SAVE_APPLICATION_LIST_ITEM', Object.assign({}, payload));

};
