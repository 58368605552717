<template>

	<div class="upload-template">
		<steps :current="3" :total="4" />

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Upload Files</h1>
					</div>
					<div class="template__page-description mb-5">
						<p>
							Upload supporting documentation by selecting each document chiclet and selecting Choose Files. Use Add
							Other Document to specify additional optional documents. Use the check boxes and then select “Send Link”
							to send customers a link to upload selected documents.
						</p>
					</div>
				</div>
			</div>

			<form @submit="onSubmit" novalidate>
				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-lg-8">

							<div v-for="(type, index) in uploadTypes" class="tffi-upload-card-mini d-flex flex-row my-1">
								<div class="d-flex align-items-center me-4">
									<input
										:style="( (app.application && app.application['linkSentOn'] && app.application['linkPurpose'] == 'uploads')) ? 'opacity: 0.4' : ''"
										:disabled="(app.application && app.application['linkSentOn'] && app.application['linkPurpose'] == 'uploads')"
										v-if="!['signedContract', 'billOfSaleInvoice', 'completionCertificate'].includes(type.name) && !type.uploaded"
										v-on:change="checkBoxChanged()" v-model="requestUploadTypes[index]" type="checkbox"
										:name="requestUploadTypes[index]" :id="requestUploadTypes[index]"
										class="tffi-input-checkbox theming primary-color">
									<div
										v-if="['signedContract', 'billOfSaleInvoice', 'completionCertificate'].includes(type.name) || (type.uploaded)"
										name="" id="" class="tffi-input-checkbox theming primary-color p-2 me-2 invisible"></div>
								</div>

								<div @click="selectedType = index" class="flex-grow-1">
									<div class="tffi-card tffi-card-secondary-card d-flex flex-row p-4 upload-type"
										:class="(selectedType == index) ? 'active' : ''">
										<div class="d-flex flex-grow-1">
											<p class="my-0">{{ type.description }}</p><span v-if="type.required"> *</span>
											<div
												v-if="requestUploadTypes[index] && (app.application && app.application['linkSentOn'] && app.application['linkPurpose'] == 'uploads') && !type.uploaded"
												class="tffi-card-secondary-card__right-area__link-confirmation-area position-static ms-auto mx-1">

												<ul class="float-right">
													<li>Link Sent</li>
												</ul>

											</div>
										</div>

										<div>
											<div v-if="type.uploaded" class="status-accepted">
												<img src="../../../assets/img/icons/check-mark.svg" alt="">
											</div>
											<div v-if="type.required && !type.uploaded" class="status-denied">
												<img src="../../../assets/img/icons/exclamation-mark.svg" alt="">
											</div>
											<button v-if="type.otherType && !type.uploaded" @click.prevent="removeOtherType(type.name)"
												classd="status-denied"
												class="tffi-card-secondary-card__right-area__trash-area position-static ms-3 d-none-">
												<img src="../../../assets/img/icons/garbage-can.svg" alt="">{{ type.otherType }}
											</button>
										</div>
									</div>
								</div>
							</div>

							<button @click.prevent="showOtherDocBox()" v-if="!addingOtherDoc"
								class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100">
								Add Other Document
							</button>

							<div class="template--section-divider"></div>
							<div class="template--section-divider"></div>

						</div>
						<div class="col-lg-16">

							<div v-if="addingOtherDoc" class="tffi-card tffi-card-secondary-card other-document-card">

								<div class="row">
									<div class="col-24">

										<h2>
											Other Document
										</h2>

										<div class="d-flex- flex-column- w-100">
											<label>Document Type</label>
											<input id="documentType" v-model="documentType" @keyup.enter="addUploadType(documentType)"
												type="text" class="w-100">
										</div>

										<div class="px-4"></div>

										<div class="d-inline-flex- align-items-right">
											<button @click.prevent="addUploadType(documentType)"
												class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid px-5">
												Add
											</button>
										</div>

									</div>
								</div>

							</div>


							<div v-if="!addingOtherDoc" class="tffi-card tffi-card-main-card theming secondary-color">
								<div class="row">
									<div class="col-24 col-sm-14 col-md-14 col-lg-14 col-xl-14 d-flex align-items-center mb-4 mb-sm-0">
										<p v-if="app && app.application && app.application['linkSentOn'] && app.application['linkPurpose'] == 'uploads'"
											class="my-0">A link has been sent to the primary applicant to upload supporting documents.</p>
										<p v-else class="my-0">
											Select documents and click "Send Link" to email the primary applicant a link to upload supporting
											documents.
										</p>
									</div>
									<div
										class="col-24 col-sm-5 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center justify-content-center justify-content-xl-end mb-3 mb-sm-0">
										<button type="button"
											class="tffi-btn tffi-btn--solid theming accent-color accent-color-btn-solid mx-auto-lg w-100 text-nowrap"
											v-if="app && app.application && app.application['linkSentOn'] && app.application['linkPurpose'] == 'uploads'"
											@click.prevent="cancelLink()">
											Cancel Link
										</button>
									</div>
									<div
										class="col-24 col-sm-5 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center justify-content-center justify-content-xl-end mb-0 mb-sm-0">
										<button :disabled="!this.isAnySelected" @click.prevent="sendLink()"
											class="tffi-btn tffi-btn--solid theming primary-color primary-color-btn-solid mx-auto-lg w-100 text-nowrap">
											{{ (app && app.application && app.application['linkSentOn'] && app.application['linkPurpose'] ==
											'uploads') ? 'Resend Link' : 'Send Link' }}
										</button>
									</div>
								</div>
							</div>

							<hr v-if="!addingOtherDoc" class="my-5">

							<div :class="$refs.upload && $refs.upload.dropActive && 'drop-active'" v-if="!addingOtherDoc"
								class="tffi-card tffi-card-upload-card theming primary-color">
								<p class="tffi-card-upload-card__title theming primary-color">Drag & Drop Files to Upload!</p>
								<div class="tffi-card-upload-card__image">
									<!-- <img src="../../../assets/img/icons/upload.svg" alt=""> -->
									<svg xmlns="http://www.w3.org/2000/svg" width="124.746" height="64.749" viewBox="0 0 124.746 64.749">
										<g id="Group_1108" data-name="Group 1108" transform="translate(-902 -346)">
											<g id="Group_374" data-name="Group 374" transform="translate(740.061 -309.916)">
												<g id="Group_373" data-name="Group 373" transform="translate(156.781 648)">
													<g id="Group_371" data-name="Group 371" transform="translate(23.912 14.288)">
														<path id="Path_4192" data-name="Path 4192"
															d="M1285,1333.284l-24.808,5.771c-.693.162-1.122.85-.869,1.935l10.555,45.374a1.322,1.322,0,0,0,1.451.565l34.257-7.969c.69-.161,1.118-.85,1.049-1.147l-8.357-35.923c-.139-.6-.262-.77-.432-.876l-12.261-7.634a.774.774,0,0,0-.585-.1Zm0,0"
															transform="translate(-1259.254 -1333.264)" fill="#fff" stroke="#c18f14"
															stroke-miterlimit="10" stroke-width="2" />
														<path id="Path_4193" data-name="Path 4193" d="M1284,1333.468l2.357,10.131,10.126-2.355Zm0,0"
															transform="translate(-1257.86 -1333.252)" fill="#fff" stroke="#c18f14"
															stroke-linejoin="round" stroke-width="2" />
														<line id="Line_292" data-name="Line 292" x2="9.484"
															transform="translate(11.953 19.483) rotate(-13)" fill="none" stroke="#c18f14"
															stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
														<line id="Line_293" data-name="Line 293" x2="21.428"
															transform="translate(13.472 26.065) rotate(-13)" fill="none" stroke="#c18f14"
															stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
														<line id="Line_294" data-name="Line 294" x2="21.428"
															transform="translate(14.992 32.646) rotate(-13)" fill="none" stroke="#c18f14"
															stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
														<line id="Line_295" data-name="Line 295" x2="21.428"
															transform="translate(16.511 39.228) rotate(-13)" fill="none" stroke="#c18f14"
															stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
													</g>
													<g id="Group_372" data-name="Group 372" transform="translate(69.157 4.802) rotate(20)">
														<rect id="Rectangle_799" data-name="Rectangle 799" width="42.555" height="42.555" rx="6.394"
															transform="translate(12.872 8.296)" fill="#fff" stroke="#c18f14" stroke-miterlimit="10"
															stroke-width="1.749" />
														<path id="Path_4199" data-name="Path 4199"
															d="M1241.585,1391.816h32.61L1264.927,1375l-9.612,12.014-6.521-6.864Z"
															transform="translate(-1223.522 -1347.241)" fill="#d6b943" />
														<circle id="Ellipse_39" data-name="Ellipse 39" cx="3.089" cy="3.089" r="3.089"
															transform="translate(22.183 14.375)" fill="#d6b943" />
													</g>
												</g>
											</g>
											<g id="Group_1104" data-name="Group 1104" transform="translate(-524.254 -120)">
												<line id="Line_296" data-name="Line 296" x2="10" transform="translate(1539.5 472.5)" fill="none"
													stroke="#d6b943" stroke-linecap="round" stroke-width="3" />
												<line id="Line_297" data-name="Line 297" y2="10" transform="translate(1544.5 467.5)" fill="none"
													stroke="#d6b943" stroke-linecap="round" stroke-width="3" />
											</g>
											<g id="Group_1105" data-name="Group 1105" transform="translate(-621.922 -69.853)">
												<line id="Line_296-2" data-name="Line 296" x2="6.353" transform="translate(1539.5 470.676)"
													fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3" />
												<line id="Line_297-2" data-name="Line 297" y2="6.353" transform="translate(1542.676 467.5)"
													fill="none" stroke="#d6b943" stroke-linecap="round" stroke-width="3" />
											</g>
											<g id="Group_1106" data-name="Group 1106" transform="translate(-636 -115.128)">
												<line id="Line_296-3" data-name="Line 296" x2="5.128" transform="translate(1539.5 470.064)"
													fill="none" stroke="#231f20" stroke-linecap="round" stroke-width="3" />
												<line id="Line_297-3" data-name="Line 297" y2="5.128" transform="translate(1542.064 467.5)"
													fill="none" stroke="#231f20" stroke-linecap="round" stroke-width="3" />
											</g>
											<g id="Group_1107" data-name="Group 1107" transform="translate(-526.818 -79.128)">
												<line id="Line_296-4" data-name="Line 296" x2="5.128" transform="translate(1539.5 470.064)"
													fill="none" stroke="#c18f14" stroke-linecap="round" stroke-width="3" />
												<line id="Line_297-4" data-name="Line 297" y2="5.128" transform="translate(1542.064 467.5)"
													fill="none" stroke="#c18f14" stroke-linecap="round" stroke-width="3" />
											</g>
										</g>
									</svg>
								</div>
								<p class="tffi-card-upload-card__or">or</p>
								<div>
									<file-upload :custom-action="customUpload"
										class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color p-2 uppercase strong"
										type="button" :multiple="false" :drop="true" :drop-directory="true" v-model="files"
										accept=".pdf, .jpg, .heic" ref="upload" @input-file="inputFile" :timeout="600 * 100000">Choose
										files</file-upload>

									<button type="button" class="btn btn-success d-none" v-if="!$refs.upload || !$refs.upload.active"
										@click.prevent="$refs.upload.active = true">Start Upload</button>
								</div>
							</div>

							<hr class="my-5">

							<div v-if="Object.keys(uploaded).length > 0" class="tffi-card tffi-card-upload-progress-card">
								<div class="row">
									<div class="col-24">

										<!--item-->
										<div v-for="(file, index) in uploaded" v-if="file.uploaded" :key="file.name"
											class="tffi-card-upload-progress-card__item d-flex flex-row">
											<div
												class="tffi-card-upload-progress-card__item__left-area d-flex flex-column flex-grow-1 justify-content-center me-5">
												<div class="tffi-card-upload-progress-card__item__item-description d-flex flex-row">
													<div class="tffi-card-upload-progress-card__item__item-description__file-name">
														<p>{{ file.description }} ({{ index }})</p>
													</div>
													<div class="flex-grow-1"></div>
													<div class="tffi-card-upload-progress-card__item__item-description__file-size">
														<p>{{ (file.size / 1024 / 1024).toFixed(2) }} MB</p>
													</div>
												</div>
												<div class="d-none tffi-card-upload-progress-card__item__progress-bar progress"
													style="height: 8px;">
													<div class="progress-bar theming accent-color" style="width:25%;" role="progressbar"
														aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<div class="d-none- tffi-card-upload-progress-card__item__progress-percentage">
													<p class="theming primary-color">{{ file.fileName }}</p>
												</div>
											</div>
											<div class="tffi-card-upload-progress-card__item__right-area">
												<div class="tffi-card-upload-progress-card__item__controls d-flex align-items-center h-100">
													<button @click.prevent="removeFile(index)"
														class="tffi-card-upload-progress-card__item__controls__stop">
														<img src="../../../assets/img/icons/stop.svg" alt="">
													</button>
												</div>
											</div>
										</div>

									</div>

									<div class="col-lg-24">

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="tffi-navigation-bottom">
					<div class="container-xl">
						<div class="row">
							<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
								<button type="button" class="tffi-btn tffi-btn--solid theming secondary-color" color="primary"
									:loading="goingBack" @click.prevent="goToPage({ name: 'BankVerification', params: $route.params })">
									{{ $t('forms.back') }}
								</button>
							</div>
							<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
								<button type="button" class="tffi-btn tffi-btn--solid theming secondary-color ms-auto"
									@click.prevent="goToPage({ name: 'Dashboard'})">
									Save for later
								</button>
							</div>
							<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
								<button class="tffi-btn tffi-btn--solid theming primary-color me-0"
									@click.prevent="goToPage({ name: 'ApplicationDashboard', params: $route.params })">
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

	</div>

</template>

<script>

	// import ApplicationMixin from '../ApplicationForms/Application/ApplicationMixin';
	import { mapState } from 'vuex';
	import RadialProgressBar from 'vue-radial-progress';
	import { paramCase, camelCase } from 'change-case';
	import FormsMixin from './ApplicationForms/FormsMixin';
	import { upload, getApplication, callAction } from '../../api';
	import FileUpload from 'vue-upload-component';
	// import { saveApplication, getApplication, callAction } from '../../../../api';


	export default {

		components: {
			RadialProgressBar,
			FileUpload
		},

		mixins: [
			FormsMixin
		],

		data() {

			return {
				applicant: {},
				coApps: [],
				isAnySelected: false,
				app: {},
				addingOtherDoc: false,
				documentType: '',
				url: process.env.VUE_APP_ENDPOINT,
				files: [],
				selectedType: 0,
				uploadTypeNames: [
					"Signed Contract",
					"Bill of Sale / Invoice",
					"Completion Certificate",
					"PAP Form / Void Cheque",
					"Government ID",
					"Income Verification"
				],
				uploaded: {},
				uploadTypes: {
					0: {
						name: 'signedContract',
						fileName: null,
						description: "Signed Contract",
						required: false,
						uploaded: false,
						size: 0,
					},
					1: {
						name: 'billOfSaleInvoice',
						fileName: null,
						description: 'Bill of Sale / Invoice',
						required: true,
						uploaded: false,
						size: 0,
					},
					2: {
						name: 'completionCertificate',
						fileName: null,
						description: 'Completion Certificate',
						required: true,
						uploaded: false,
						size: 0,
					},
					3: {
						name: 'papFormVoidCheque',
						fileName: null,
						description: 'PAP Form / Void Cheque',
						required: true,
						uploaded: false,
						size: 0,
					},
					4: {
						name: 'governmentId',
						fileName: null,
						description: 'Government ID',
						required: true,
						uploaded: false,
						size: 0,
					},
					5: {
						name: 'incomeVerification',
						fileName: null,
						description: 'Income Verification',
						required: false,
						uploaded: false,
						size: 0,
					}
				},
				isFilesUploaded: false,
				requestUploadTypes: {},
				vModels: {
				}
			};

		},

		watch: {

			requestUploadTypes: {
				handler(val) {

					console.log('-------- requestUploadTypes', val);

				},
				immediate: true,
				deep: true
			},
			
			selectedType: {
				handler(val) {

					console.log('-------- selectedType', val);

				},
				immediate: true,
				deep: true
			}

		},

		async mounted() {

			this.saveLoading(true);

			let id = this.$route.params.applicationId

			this.app = await getApplication(id);

			console.log('retrieved app', this.app);

			this.applicant = {
				firstName: this.app.application.firstName,
				lastName: this.app.application.lastName,
			}

			if (this.app.application.uploadTypes) {
				// console.log('this.app.application.uploadTypes', this.app.application.uploadTypes);
				
				this.uploadTypes = null
				this.uploadTypes = this.app.application.uploadTypes
				// this.uploaded = this.app.application.uploadTypes

				this.listItems();

			} else {

				// console.log(this.uploadTypes[0].name);

				// console.log('this.uploadTypes before', this.uploadTypes);
				// if (
				// 	this.uploadTypes[0].name == 'signedContract'
				// 	&& this.app.signNowSentToDealerOnly != undefined
				// 	&& this.app.signNowSentToDealerOnly
				// ) {

				// 	delete this.uploadTypes[0]
				// 	let temp = this.uploadTypes
				// 	this.uploadTypes = null
				// 	this.uploadTypes = temp
				// 	this.selectedType = 1

				// }

				// console.log('this.uploadTypes after', this.uploadTypes);

			}

			if (
				this.uploadTypes[0]
				&& this.uploadTypes[0].name == 'signedContract'
				&& (!this.app.signNowSentToDealerOnly || this.app.signNowSentToDealerOnly == undefined)
			) {

				console.log('not dealer only');
				delete this.uploadTypes[0]
				let temp = this.uploadTypes
				this.uploadTypes = null
				this.uploadTypes = temp
				this.selectedType = 1
				
			} else {
				this.selectedType = 0
			}

			if (
				this.uploadTypes[0] == undefined
				// && this.uploadTypes[0].name != 'signedContract'
				&& this.app.application.signNowSentToDealerOnly != undefined
				&& this.app.application.signNowSentToDealerOnly
			) {
				this.uploadTypes[0] = {
					name: 'signedContract',
					fileName: null,
					description: "Signed Contract",
					required: true,
					uploaded: false,
					size: 0,
				};

			}

			if (
				this.app.application.bankVerificationSent != undefined
				&& this.app.application.bankVerificationSent
			) {

				this.uploadTypes[3].required = false;
				
			}
			
			if (this.app.application.requestUploadTypes) {
				Object.keys(this.app.application.requestUploadTypes).forEach(element => {
					// console.log("this.app.application.requestUploadTypes['isRequested']", this.app.application.requestUploadTypes[element]['isRequested']);
					this.requestUploadTypes[element] = this.app.application.requestUploadTypes[element]['isRequested']
				});
			}

			// console.log('this.app', this.app);

			// console.log('Object.keys(uploaded).length', Object.keys(this.uploaded).length);

			this.checkBoxChanged()

			this.saveLoading(false);

		},

		computed: {

			...mapState({
				authUser: state => state.auth.authUser,
			}),

		},

		methods: {

			showOtherDocBox() {

				this.addingOtherDoc = true;
				const documentTypeInput = document.getElementById('documentType');
				// console.log('documentTypeInput', documentTypeInput);
				// documentTypeInput.focus();
				
			},

			checkBoxChanged() {

				console.log('checkBoxChanged');

				this.isAnySelected = false;

				Object.keys(this.uploadTypes).forEach((index, element) => {

					if (
						// ![1, 2].includes(index)
						this.requestUploadTypes[index]
					) {
						this.isAnySelected = true
					}
					// console.log('this.requestUploadTypes[index]', index, element, this.requestUploadTypes[index]);
				});

				// if (this.isAnySelected)

				// console.log('this.isAnySelected', this.isAnySelected);

			},

			async sendLink() {

				this.saveLoading(true);

				let requestUploadTypes = {};
				Object.keys(this.uploadTypes).forEach((index, element) => {

					if (this.uploadTypes[index]['name'] != 'signedContract') {

						requestUploadTypes[index] = {
							name: this.uploadTypes[index]['name'],
							description: this.uploadTypes[index]['description'],
							isRequested: this.requestUploadTypes[index] ?? false,
							required: this.uploadTypes[index]['required'],
							fileName: this.uploadTypes[index]['fileName'],
							uploaded: this.uploadTypes[index]['uploaded'],
							size: this.uploadTypes[index]['size'],
						}
						
						this.uploadTypes[index] = {
							name: this.uploadTypes[index]['name'],
							description: this.uploadTypes[index]['description'],
							isRequested: this.requestUploadTypes[index] ?? false,
							required: this.uploadTypes[index]['required'],
							fileName: this.uploadTypes[index]['fileName'],
							uploaded: this.uploadTypes[index]['uploaded'],
							size: this.uploadTypes[index]['size'],
						}

					}
					
				});

				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes,
						requestUploadTypes
					}
				});

				let result = await callAction({
					action: 'sendLink',
					id: this.app.application.id,
					for: 'main',
					domain: window.location.origin + '/',
					dealer: this.authUser.vendorName ?? this.authUser.name,
					purpose: 'uploads'
				})

				if (!result.data.application) {
					await this.showErrorAlert(result.data);
					return;
				}

				await this.showSuccessAlert('Successfully sent link.');
				this.app = null
				this.app = result.data

				console.log('this.app', this.app);

				this.saveLoading(false);
			},

			async cancelLink() {

				this.saveLoading(true);

				let result = await callAction({
					action: 'cancelLink',
					id: this.app.application.id,
					for: 'main',
					domain: window.location.origin + '/'
				})

				console.log('result', result);

				this.app = null
				this.app = result.data

				let requestUploadTypes = {};

				Object.keys(this.uploadTypes).forEach((index, element) => {

					if (this.uploadTypes[index]['name'] != 'signedContract') {

						requestUploadTypes[index] = {
							name: this.uploadTypes[index]['name'],
							description: this.uploadTypes[index]['description'],
							isRequested: false,
							required: this.uploadTypes[index]['required']
						}

						this.uploadTypes[index] = {
							name: this.uploadTypes[index]['name'],
							description: this.uploadTypes[index]['description'],
							isRequested: false,
							required: this.uploadTypes[index]['required'],
							fileName: this.uploadTypes[index]['fileName'],
							uploaded: this.uploadTypes[index]['uploaded'],
							size: this.uploadTypes[index]['size'],
						}

					}

				});

				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes,
						requestUploadTypes
					}
				});


				location.reload()

				this.saveLoading(false);

			},

			async listItems() {

				console.log('listItems');

				console.log('this.uploadTypes', this.uploadTypes);
				console.log('this.uploadTypes keys', Object.keys(this.uploadTypes));
				Object.keys(this.uploadTypes).forEach((index, element) => {
					console.log('index:', index, this.uploadTypes[index]);
					// console.log();
					// console.log('element', this.uploadTypes[element]);
					if (
						this.uploadTypes
						&& this.uploadTypes[index]
						&& this.uploadTypes[index]['uploaded']
					) {
						// console.log('adding', this.uploadTypes[index]);
						this.uploaded[index] = this.uploadTypes[index]
					}
				});

				// console.log('this.uploaded after ', this.uploaded);

			},

			async removeFile(index) {

				this.saveLoading(true);

				console.log('removing index', index);

				this.uploadTypes[index].uploaded = false;

				// console.log('this.uploaded before', this.uploaded);
				// this.uploaded = null;
				// this.uploaded = this.uploadTypes;
				
				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes
					}
				});
				
				this.listItems();

				this.saveLoading(false);

			},

			async addUploadType(type) {

				this.saveLoading(true);

				console.log('this.uploadTypes before', this.uploadTypes);

				let keysArray = Object.keys(this.uploadTypes);
				let lastKey = keysArray[keysArray.length - 1];

				console.log('lastKey', lastKey);

				this.uploadTypes[Number(lastKey) + 1] = ({
					name: camelCase(type),
					description: type,
					otherType: true
				});

				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes
					}
				});

				this.addingOtherDoc = false;
				this.documentType = ''

				console.log('this.uploadTypes after', this.uploadTypes);

				this.saveLoading(false);

			},
			
			async removeOtherType(type) {

				this.saveLoading(true);

				console.log('removeOtherType type', type);

				Object.keys(this.uploadTypes).forEach(element => {
					// console.log('element', element);
					console.log(this.uploadTypes[element].name);
					if (this.uploadTypes[element].name == type) {
						console.log('found', this.uploadTypes[element].name);
						delete this.uploadTypes[element]
						// let temp = this.uploadTypes
					}
				})

				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes
					},
					// errors: this.errors
				});

				this.saveLoading(false);

			},

			async customUpload(file, component) {

				let abvUploadType = {
					signedContract: "SC",
					billOfSaleInvoice: "BOS",
					completionCertificate: "CC",
					papFormVoidCheque: "VC",
					governmentId: "ID",
					incomeVerification: "PIC",
				}

				this.saveLoading(true);

				console.log('customUpload');
				console.log('file', file);
				console.log('this.selectedType', this.selectedType);

				let ext = file.file.name.split('.').pop();

				let edgeId = this.app.application.edgeId ?? 'XXXX'

				let fileName = edgeId + '-' + (abvUploadType[this.uploadTypes[this.selectedType].name] ?? ('OTH-' + this.uploadTypes[this.selectedType].name)) + '-' + file.file.name

				let payload = {
					uploadType: this.uploadTypes[this.selectedType].name,
					id: this.$route.params.applicationId,
					contentType: file.file.type,
					fileName
				};
				
				let result = await upload(payload, file);

				this.uploadTypes[this.selectedType].uploaded = true
				this.uploadTypes[this.selectedType].fileName = fileName
				this.uploadTypes[this.selectedType].size = file.file.size

				console.log('result', result);

				if (result.status != 200) {
					await this.showSuccessAlert('Something went wrong while uploading.' + result.data.toString());
					return;
				}

				let saveResult = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						uploadTypes: this.uploadTypes
					}
				});

				this.listItems()
				// this.uploaded = this.uploadTypes;

				
				this.saveLoading(false);
				
				if (this.uploadTypes[Number(this.selectedType) + 1]) {
					this.selectedType = Number(this.selectedType) + 1
				}
			},

			inputFile(newFile, oldFile) {

				console.log('newFile', newFile);

				if (
					(newFile && !oldFile) // add
					||
					(newFile && oldFile) // update
				) {

					// add
					console.log('add', newFile);

					let type = newFile.type;

					console.log('type', type);

					if (

						!type

						||

						(
							type !== 'application/pdf'
							&& type !== 'image/jpeg'
							&& type !== 'image/heic'
							&& type !== 'image/heif'
						)

					) {

						console.log('wrong type');
						this.files = [];
						this.showErrorAlert(this.$i18n.t('uploads.fileTypesAccepted'));

					} else {
						this.$refs.upload.active = true
					}

				}

				if (!newFile && oldFile) {

					// remove
					console.log('remove', oldFile);

				}

			},


			paramCase: val => paramCase(val),

			async onSubmit(e) {

				console.log('onSubmit');

				// this.addUploadType(this.documentType)

			}

		}

	};

</script>

<style lang="scss" scoped>
	.upload-type {
		cursor: pointer;

		&.active {
			border: solid #C18F14 2px
		}

	}

</style>
