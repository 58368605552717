<template>
  
	<component :is="tag" :class="className" role="group">
		<slot></slot>
	</component>
  
</template>

<script>
  
	export default {
    
		props: {
			tag: {
				type: String,
				default: "div"
			},
			vertical: {
				type: Boolean,
				default: false
			},
			size: {
				type: String
			}
		},
    
		computed: {
      
			className() {
        
				return [
					this.vertical ? 'btn-group-vertical' : 'btn-group',
					this.size && 'btn-group-' + this.size
				];
        
			}
      
		}
    
	};

</script>

