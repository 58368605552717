<template>
	<div class="add-applicant-template">
		<steps :current="2" :total="4" />

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>APPLICANT DETAILS</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>
			<div class="container-xl template--vh">
				<div class="row">
					<div class="col-lg-8">
						<program :programType="programType" :financedAmount="vModels.program.financedAmount" :monthly="vModels.program.monthly" />

						<div
							v-if="applicant.firstName"
							@click="selectApp('main')"
							:class="'tffi-card tffi-card-secondary-card theming primary-color ' + (selectedAppIndex == 'main' ? 'active' : '')"
							style="cursor: pointer"
						>
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(app.application)" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(app.application)" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ this.applicant['firstName'] + ' ' + this.applicant['lastName'] }}</p>
									<p>{{ this.applicant['email'] }}</p>
								</div>
								<div v-if="app.application['linkSentOn'] && app.application['linkPurpose'] == 'application'" class="tffi-card-secondary-card__right-area__link-confirmation-area">
									<ul class="">
										<li>Link Sent</li>
									</ul>
								</div>
								<!-- <button @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button> -->
							</div>
						</div>

						<div
							v-if="coApps"
							v-for="(coApp, index) in coApps"
							@click="selectApp(index)"
							:class="'tffi-card tffi-card-secondary-card ' + (selectedAppIndex == index ? 'active' : '')"
							style="cursor: pointer"
							>
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(coApps[index])" class="status-accepted">
										<img src="../../../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(coApps[index])" class="status-denied">
										<img src="../../../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Co-Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ coApp['firstName'] + ' ' + coApp['lastName'] }}</p>
									<p>{{ coApp['email'] }}</p>
								</div>
								<div v-if="coApp.linkSentOn && coApp.linkPurpose == 'application'" class="tffi-card-secondary-card__right-area__link-confirmation-area">
									<ul class="">
										<li>Link Sent</li>
									</ul>
								</div>
								<button type="button" @click="removeApplicant(index)" class="tffi-card-secondary-card__right-area__trash-area">
									<img src="../../../../../assets/img/icons/garbage-can.svg" alt="">
								</button>
							</div>
						</div>

						<button type="button" v-if="coApps.length < 3" @click="goToStep(1)" class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100">
							Add Applicant
						</button>

						<div class="template--section-divider"></div>
						<div class="template--section-divider"></div>

					</div>

					<div class="col-lg-16">
						<div class="tffi-card tffi-card-main-card">
							<div class="row">
								<div class="col-24 col-sm-14 col-md-14 col-lg-14 col-xl-14 d-flex align-items-center mb-4 mb-sm-0">
									<p class="my-0">Select an applicant and click “Send Link” to email a credit application for completion or complete below</p>
								</div>
								<div class="col-24 col-sm-5 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center justify-content-center justify-content-xl-end mb-3 mb-sm-0">
									<button
										type="button"
										class="tffi-btn tffi-btn--solid theming accent-color accent-color-btn-solid mx-auto-lg w-100 text-nowrap"
										v-if="selectedApp.linkSentOn && selectedApp.linkPurpose == 'application'"
										@click="cancelLink()"
										>
										Cancel Link
									</button>
								</div>
								<div class="col-24 col-sm-5 col-md-5 col-lg-5 col-xl-5 d-flex align-items-center justify-content-center justify-content-xl-end mb-0 mb-sm-0">
									<button
										type="button"
										@click="sendLink()"
										class="tffi-btn tffi-btn--solid theming primary-color primary-color-btn-solid mx-auto-lg w-100 text-nowrap"
										>
										{{ (selectedApp.linkSentOn && selectedApp.linkPurpose == 'application') ? 'Resend Link' : 'Send Link'}}
									</button>
								</div>
							</div>
						</div>

						<div v-if="!(selectedApp.linkSentOn && selectedApp.linkPurpose == 'application')" class="col-lg-16-">

							<div class="tffi-card tffi-card-main-card">
								<div class="row">
									<div class="col-24 mb-3">
										<h2 class="mb-0 theming primary-color">{{ selectedApp.firstName + ' ' + selectedApp.lastName }}</h2>
									</div>
								</div>
								<div class="row row-cols-md-2">
									<template v-for="(fieldData, fieldName) in getForm('2')">
										<div :class="fieldData.class">
											<div class="d-flex flex-column">
												<form-field
													v-bind="checkForOptions(fieldName, fieldData)"
													v-model="vModels[fieldName]"
													:name="fieldName"
													:label="getLabel(fieldName)"
													:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
													:error="errors[fieldName]"
													:locale="locale"
													bootstrap
													@change="onChange"
												></form-field>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div class="container-fluid">
								<div class="row">
									<div class="col-24 px-0">
										<div class="template__secondary-title">
											<h2 class="mb-0">Address</h2>
										</div>
									</div>
								</div>
							</div>

							<div class="tffi-card tffi-card-main-card">
								<div class="row row-cols-md-2">
									<template v-for="(fieldData, fieldName) in getForm('2.1')">
										<div :class="fieldData.class">
											<div class="d-flex flex-column">
												<form-field
													v-bind="checkForOptions(fieldName, fieldData)"
													v-model="vModels[fieldName]"
													:name="fieldName"
													:label="getLabel(fieldName)"
													:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
													:error="errors[fieldName]"
													:locale="locale"
													bootstrap
													@change="onChange"
												></form-field>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div class="container-fluid">
								<div class="row">
									<div class="col-24 px-0">
										<div class="template__secondary-title">
											<h2 class="mb-0">Employer Details</h2>
										</div>
									</div>
								</div>
							</div>

							<div class="tffi-card tffi-card-main-card">
								<div class="row row-cols-md-2">
									<template v-for="(fieldData, fieldName) in getForm('2.2')">
										<div :class="fieldData.class">
											<div class="d-flex flex-column">
												<form-field
													v-bind="checkForOptions(fieldName, fieldData)"
													v-model="vModels[fieldName]"
													:name="fieldName"
													:label="getLabel(fieldName)"
													:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
													:error="errors[fieldName]"
													:locale="locale"
													bootstrap
													@change="onChange"
												></form-field>
											</div>
										</div>
									</template>
								</div>
							</div>

						</div>
						
					</div>
				</div>
			</div>

			<div class="tffi-navigation-bottom">
				<div class="container-xl">
					<div class="row">
						<div class="col order-3 order-lg-1 col-24 col-lg-16 d-flex justify-content-start">
							<button
								type="button"
								class="tffi-btn tffi-btn--solid theming secondary-color"
								color="primary"
								:loading="goingBack"
								@click="goToStep(1)"
							>
								{{ $t('forms.back') }}
							</button>
						</div>
						<div class="col order-2 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
							<button
								type="button"
								class="tffi-btn tffi-btn--solid theming secondary-color ms-auto"
								@click="saveAndDashboard"
								>
								Save for later
							</button>
						</div>
						<div class="col order-1 order-lg-3 col-24 col-lg-4 d-flex justify-content-end">
							<button
								class="tffi-btn tffi-btn--solid theming primary-color me-0"
								@click="goToStep(3)"
								>
								Next
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>

</template>

<script>

	import { mapState } from 'vuex';
	import ApplicationMixin from './ApplicationMixin';
	import { saveApplication, getApplication, callAction } from '../../../../api';
	import { isNumber } from '@incodeapps/js-utilities';


	export default {

		mixins: [
			ApplicationMixin
		],

		filters: {
			formatMoney(value) {
				return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(value);
			},
		},

		data() {

			return {
				applicant: {},
				coApps: [],
				equipments: [],
				// selectApp: 0,
				selectedApp: {},
				selectedAppIndex: 'main',
				app: null,
				programType: '',
				vModels: {
					firstName: '',
					lastName: '',
					sin: '',
					dob: '',
					primaryPhone: '',
					mobilePhone: '',
					country: 'Canada',
					address1: '',
					address2: '',
					province: '',
					city: '',
					postal: '',
					titleOwner: null,
					grossIncome: '',
					linkSentOn: null,
					linkPurpose: null,
					token: null,
					program: {},
					employer: '',
					employmentTitle: '',
					employmentTerm: ''
				},
				optionals: [
					'mobilePhone',
					'sin',
					'referral',
					// 'referralOther'
				]
			};

		},

		watch: {

			'vModels.referral': {

				async handler(val) {

					let referralOther = document.getElementById('referral-other');

					if (val == 'other') {

						this.$set(this.vModels, 'referralOther', '');
						referralOther.parentElement.style.display = 'block';

					} else {

						referralOther.parentElement.style.display = 'none';
					}
					
				}

			},

		},

		created() {

		},

		async mounted() {

			this.saveLoading(true);

			let id = this.$route.params.applicationId

			let app = await getApplication(id)
			// console.log('app', result);
			this.app = app;

			this.vModels.program = app.application.program

			this.applicant = {
				firstName: this.app.application.firstName,
				lastName: this.app.application.lastName,
				email: this.app.application.email
			}

			if (app.application.coApps) {
				this.vModels.coApps = app.application.coApps
				this.coApps = this.app.application.coApps
			}
			
			if (app.application.equipments) {
				this.equipments = this.app.application.equipments
			}

			if (this.$route.query.hasOwnProperty('select')) {
				this.selectApp(this.$route.query.select, false)
			} else {
				this.selectApp('main', false)
			}

			this.programType = this.vModels.program.program.Code == 2 ? 'Lease' : 'Loan'

			this.saveLoading(false);

		},

		computed: {

			...mapState({
				authUser: state => state.auth.authUser,
			}),

		},

		methods: {

			async saveAndDashboard() {

				this.saveLoading(true);

				await this.saveApp()

				this.saveLoading(false);

				return this.$router.push({
					name: `Dashboard`,
				});
				
			},

			async removeApplicant(index) {

				this.saveLoading(true);

				this.coApps.splice(index, 1);

				let result = await this.saveApplicationAction({
					data: {
						id: this.$route.params.applicationId,
						coApps: this.coApps
					},
					errors: this.errors
				});

				this.saveLoading(false);

			},
			
			async goToStep(index) {

				this.saveLoading(true);

				await this.saveApp()
			
				await this.$router.push({
					name: 'ApplicationStep' + index,
					params: this.$route.params }
				);

				this.saveLoading(false);

			},

			async selectApp(index, isSave = true) {

				this.saveLoading(true);

				console.log('selectApp index', index);
				console.log('this.selectedApp', this.selectedApp);

				if (
					// this.selectedAppIndex == 'main'
					this.selectedApp.hasOwnProperty('linkSentOn')
					&& this.selectedApp.linkSentOn
				) {

					isSave = false

				}
				// else if (
				// 	// isNumber(this.selectedAppIndex)
				// 	this.app.application.coApps[index].hasOwnProperty('linkSendOn')
				// ) {

				// 	isSave = false

				// }

				if (
					isSave
				) {
					await this.saveApp()
				}

				// this.selectApp = 0;
				// console.log('this.vModels', this.vModels);
				// let form = this.vModels

				this.selectedApp = {}
				this.selectedAppIndex = index

				Object.keys(this.vModels).forEach(element => {
					// console.log(element);
					// this.$set(this.vModels, 'sin', '123432234');

					this.vModels[element] = ''
					this.selectedApp[element] = ''

					if (
						index == 'main'
						&& this.app.application
						&& this.app.application[element]
					) {

						this.vModels[element] = this.app.application[element]
						this.selectedApp[element] = this.app.application[element]

					}

					if (
						isNumber(index)
					) {

						if (
							this.app.application.coApps[index]
							&& this.app.application.coApps[index][element]
						) {
							this.vModels[element] = this.app.application.coApps[index][element]
							this.selectedApp[element] = this.app.application.coApps[index][element]
						} else {

							this.vModels[element] = ''

						}
						
					}

				});

				this.vModels['program'] = this.app.application.program

				// console.log('selected app index', index);

				// console.log('this.selectedApp', this.selectedApp);

				this.saveLoading(false);

			},
			
			async sendLink() {

				this.saveLoading(true);

				// console.log('this.app.application.id', this.app.application.id);
				// console.log('selectedAppIndex', this.selectedAppIndex);

				console.log(window.location.origin);

				let result = await callAction({
					action: 'sendLink',
					id: this.app.application.id,
					for: this.selectedAppIndex,
					domain: window.location.origin + '/',
					dealer: this.authUser.vendorName ?? this.authUser.name,
					purpose: 'application'
				})

				// console.log('result', result);

				// this.app = result.data
				
				if (!result.data.application) {
					await this.showErrorAlert(result.data);
					return;
				}
				
				// if (result.data.application.linkSentOn != this.app.application.linkSentOn) {
					
					await this.showSuccessAlert('Successfully sent link.');
					this.app = null
					this.app = result.data
					
				// }

				this.applicant = {
					firstName: this.app.application.firstName,
					lastName: this.app.application.lastName,
					email: this.app.application.email
				}

				this.coApps = this.app.application.coApps

				this.selectApp(this.selectedAppIndex, false)

				console.log('this.app', this.app);

				this.saveLoading(false);
			},
			
			async cancelLink(index) {

				this.saveLoading(true);

				console.log('this.app.application.id', this.app.application.id);
				console.log('selectedAppIndex', this.selectedAppIndex);

				let result = await callAction({
					action: 'cancelLink',
					id: this.app.application.id,
					for: this.selectedAppIndex,
					domain: window.location.origin + '/'
				})

				console.log('result', result);

				this.app = null
				this.app = result.data

				this.selectApp(this.selectedAppIndex, false)

				location.reload()

				this.saveLoading(false);

			},

			async saveApp(index) {
				// console.log('saveApp index', index);
				// this.selectApp = 0;
				// console.log('this.vModels', this.vModels);
				// let form = this.vModels

				this.selectedApp = {}
				index = this.selectedAppIndex

				// console.log('saving index', index);

				Object.keys(this.vModels).forEach(element => {
					// console.log(element, this.vModels[element]);

					// this.app[element] = this.vModels[element]

					if (
						index == 'main'
						&& this.vModels[element]
					) {

						// console.log('is main app');

						this.app.application[element] = this.vModels[element]
						// this.selectedApp[element] = this.app.application[element]

					}

					if (
						isNumber(index)
					) {

						// console.log('is app index', index);

						// if (
						// 	this.app.application.coApps[index]
						// 	&& this.app.application.coApps[index][element]
						// ) {
						this.app.application.coApps[index][element] = this.vModels[element]
							// this.selectedApp[element] = this.app.application.coApps[index][element]
						// } else {

							// this.vModels[element] = ''

						// }

					}

				});

				console.log({...this.app.application});

				let id = null;

				if (this.$route.params.applicationId) {
					id = this.$route.params.applicationId
				}

				let result = await this.saveApplicationAction({
					data: {
						id,
						...this.app.application
					},
					errors: this.errors
				});

				
				// let app = await getApplication(this.app.application.id)

				this.app = await getApplication(this.app.application.id)

				// console.log('app', this.app);

				// console.log('result', result);

				// console.log('this.selectedApp', this.selectedApp);
			},

			onSubmit(e) {

				this.isLoading = true;
				this.submitting = true;

				// console.debug(
				// 	'[onSubmit] event:', e,
				// 	'\nvModels:', JSON.stringify(this.vModels, null, 2)
				// );

			}
		}

	};

</script>

<style lang="scss" scoped>
.float-right {
	float: right;
}

.tffi-card-secondary-card {
	&:hover {
		border: 2px solid #C18F14;
	}
}

</style>
