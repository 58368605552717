<template>
	<div class="tffi-card tffi-card-secondary-with-bg-card theming primary-color">
		<div class="tffi-card-secondary-with-bg-card__content-hidden">
			<p>{{ programType }} Payment</p>
			<p v-if="financedAmount">Financed Amount: ${{ financedAmount | formatMoney }}</p>
			<p>Base Monthly Payment: ${{ monthly | formatMoney }}</p>
		</div>
		<div class="tffi-card-secondary-with-bg-card__content">
			<p>{{ programType }} Payment</p>
			<p v-if="financedAmount">Financed Amount: ${{ financedAmount | formatMoney }}</p>
			<p>Base Monthly Payment: ${{ monthly | formatMoney }}</p>
		</div>
		<div class="tffi-card-secondary-with-bg-card__overlay-image">
		</div>
		<div class="tffi-card-secondary-with-bg-card__bg">
		</div>
	</div>
</template>

<script>


export default {

	props: {

		financedAmount: {
			type: [Number, String],
			default: ''
		},

		monthly: {
			type: [Number, String],
			default: ''
		},
		
		programType: {
			type: String,
			default: ''
		}

	},

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, }).format(value);
		},
	},

	data() {

		return {
			
		};

	},

	computed: {
		

	},

	watch: {

	},

	methods: {

	}

};

</script>

<style lang="scss" scoped>
</style>
