<template>

	<mdb-container class="mt-5">

		<mdb-card>
			<mdb-card-body class="card-body-padding text-center">

				<p class="mb-4">
					<icon name="clock" type="tffi-icon" class="text-primary huge-tffi-icon">
						<span class="path1"></span>
						<span class="path2"></span>
						<span class="path3"></span>
						<span class="path4"></span>
						<span class="path5"></span>
						<span class="path6"></span>
						<span class="path7"></span>
						<span class="path8"></span>
						<span class="path9"></span>
						<span class="path10"></span>
						<span class="path11"></span>
						<span class="path12"></span>
						<span class="path13"></span>
						<span class="path14"></span>
					</icon>
				</p>

				<h3 class="font-weight-bold uppercase text-info mt-4">{{ $t('newApplication.wait') }}<span class="ellipsis"></span></h3>

			</mdb-card-body>
		</mdb-card>

	</mdb-container>

</template>

<script>

	import { camelCase, paramCase, pascalCase } from 'change-case';
	import { mapState, mapActions } from 'vuex';
	import { getApplication, runEvent } from '../../api';

	export default {

		computed: {

			...mapState({
				locale: state => state.app.locale
			})

		},

		created() {

			this.saveLoading(true);
			this.run();

		},

		methods: {

			...mapActions([
				'saveLoading',
				'saveTitle',
				'throwFatalError',
				'showSuccessAlert',
				'showErrorAlert',
				'showInfoAlert',
				'removeAlerts'
			]),

			async run() {

				let errorMsg;

				let response = await this.getApplicationData(true).catch(err => {

					console.debug('getApplicationData err:', JSON.stringify(err, null, 2));

					if (err.response && err.response.data && err.response.data.errorMessage) {

						errorMsg = err.response.data.errorMessage;

					} else if (err.message && err.message !== 'Network Error') {

						errorMsg = err.message;

					}

				});

				if (errorMsg) {

					this.showErrorAlert(errorMsg);
					this.$router.go(-1);
					return;

				} else if (!response || !response.result || !response.result.application || !response.result.application.status) {

					this.showErrorAlert(this.$i18n.t('fatalError.getApplication'));
					this.$router.go(-1);
					return;

				}

				let status = response.result.application.status;
				let eventId = response.eventId;
				// let errorMsg;

				console.log('eventId', eventId);

				let result = await runEvent(this.$route.params.applicationId, eventId).catch(err => {

					console.debug('runEvent err:', JSON.stringify(err, null, 2));

					if (err.response && err.response.data && err.response.data.errorMessage) {

						errorMsg = err.response.data.errorMessage;

					} else if (err.message && err.message !== 'Network Error') {

						errorMsg = err.message;

					} else if (typeof err === 'string') {

						errorMsg = err;

					}

				});

				if (errorMsg) {

					this.showErrorAlert(errorMsg);
					this.redirectBasedOnStatus(status);
					return;

				} else if (result && result.status) {

					this.showSuccessAlert(this.$i18n.t('applications.actionSuccess'));
					this.redirectBasedOnStatus(status);
					return;

				}

				let newResponse = await this.getApplicationData(false).catch(err => {

					console.debug('getApplicationData2 err:', JSON.stringify(err, null, 2), err);

					if (err.response && err.response.data && err.response.data.errorMessage) {

						errorMsg = err.response.data.errorMessage;

					} else if (err.message && err.message !== 'Network Error') {

						errorMsg = err.message;

					} else if (typeof err === 'string') {

						errorMsg = err;

					}

				});

				if (errorMsg) {

					this.showErrorAlert(errorMsg);
					this.redirectBasedOnStatus(status);
					return;

				} else if (!newResponse || !newResponse.result || !newResponse.result.application || !newResponse.result.application.status) {

					this.showErrorAlert(this.$i18n.t('fatalError.internal'));
					this.redirectBasedOnStatus(status);
					return;

				}

				let oldStatus = status;
				status = newResponse.result.application.status;

				this.showSuccessAlert(this.$i18n.t('applications.actionMaybeSuccess'));

				return this.redirectBasedOnStatus(status);

			},

			async getApplicationData(firstRunWithCheck) {

				let errorMsg;

				let res = await getApplication(this.$route.params.applicationId).catch(err => {

					if (err.response && err.response.data && err.response.data.errorMessage) {

						errorMsg = err.response.data.errorMessage;

					} else if (err.message && err.message !== 'Network Error') {

						errorMsg = err.message;

					}

				});

				if (errorMsg && errorMsg !== 'Network Error') {

					return Promise.reject(errorMsg);

				} else if (!res || !this.isAllowedAction(res, firstRunWithCheck)) {

					return Promise.reject(this.$i18n.t('fatalError.getApplication'));

				}

				let status = res.application && res.application.status;
				let eventId;

				if (firstRunWithCheck) {

					eventId = this.isAllowedAction(res);

					if (!eventId) {

						return Promise.reject(this.$i18n.t('fatalError.actionFailed'));

					}

					if (status === 'Incomplete') {

						this.saveTitle(this.$i18n.t('applications.deleteBtn'));

					} else {

						if (res.permissions[eventId]) {

							this.saveTitle(res.permissions[eventId][this.locale]);

						}

					}

				}

				return {result: res, eventId};

			},

			isAllowedAction(res, firstRunWithCheck) {

				if (!res || !res.application) {

					return false;

				}

				if (res.application.status === 'Incomplete' && this.$route.params.event === 'delete') {

					return 'Delete';

				}

				if (!firstRunWithCheck) {

					return camelCase(this.$route.params.event);

				}

				if (!res.permissions || !Object.keys(res.permissions).length) {

					return false;

				}

				return Object.keys(res.permissions).find(eventName => {

					return eventName === camelCase(this.$route.params.event);

				});

			},

			redirectBasedOnStatus(status) {

				return this.$router.replace({
					name: 'Dashboard'
				});

				if (!status) {

					return this.$router.go(-1);

				}

				let query = {
					q: this.$route.params.applicationId
				};

				let addQuery = (pascalCase(this.$route.params.event) !== 'Delete');

				switch (pascalCase(status)) {

					case 'KamselPending':
					case 'KamselDeclined':
					case 'KamselApproved':
					case 'SubmittedToKamsel':
						status = 'kamsel';
						break;

					case 'SubmittedToCredit':
						status = 'pending';
						break;

					case 'Declined':
					case 'BureauNotFound':
						status = 'not-approved';
						break;

					case 'Booked':
						status = 'funded';
						break;

				}

				if (!addQuery) {

					query = {};

				}



			}

		}

	};

</script>
