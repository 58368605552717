import SafeCookies from '../elements/SafeCookies';

export default {
	
	getItem: key => {
		
		return SafeCookies.get(key);
		
	},
	
	setItem: (key, value) => {
		
		return SafeCookies.set(key, value, '90m');
		
	},
	
	removeItem: key => {
		
		return SafeCookies.remove(key);
		
	}
	
};
