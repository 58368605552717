<template>
	<fieldset class="mt-3" @submit.prevent="onSubmit">

		<h1 class="text-center mb-4">Sign In</h1>

		<div class="text-center">

			<p class="mb-4">{{ $t('auth.login.intro') }}</p>

		</div>

			<form-field
				v-model="vModels.userName"
				:label="$t('auth.login.userName')"
				class="login-username"
				type="text"
				name="userName"
				autocomplete="username"
				required
			></form-field>

			<form-field
				v-model="vModels.password"
				:label="$t('auth.login.password')"
				class="login-password"
				type="password"
				name="password"
				autocomplete="current-password"
				required
			></form-field>

		<div class="text-center mt-3">

			<btn
				:loading="submitting"
				color="white"
				spinner-color="info"
				:spinner-absolute="true"
				size="lg"
				block
				type="submit"
				class="tffi-btn tffi-btn--solid theming primary-color"
			>
				{{ $t('auth.login.submit') }}
			</btn>

		</div>

	</fieldset>
</template>

<script>

	import {mapActions, mapState} from 'vuex';
	import Spinner from 'vue-loading-overlay/src/loaders/spinner';

	export default {

		components: {
			Spinner
		},

		data() {

			return {
				vModels: {
					userName: '',
					password: ''
				},
				submitting: false,
				errorAlert: null
			};

		},

		computed: {

			...mapState({
				title: state => state.app.title
			})

		},

		mounted() {

			console.log('router.currentRoute.fullPath', this.$router.currentRoute.fullPath);

			this.$emit('ready', true);

			if (process.env.VUE_APP_DEBUG) {

				this.setDebugValues();

			}

		},

		beforeDestroy() {

			this.removeAlert(this.errorAlert);

		},

		methods: {

			...mapActions([
				'saveLoading',
				'showErrorAlert',
				'removeAlert',
				'loginAction'
			]),

			async onSubmit() {

				this.submitting = true;

				this.removeAlert(this.errorAlert);

				let result = await this.loginAction(this.vModels).catch(async error => {

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					this.errorAlert = await this.showErrorAlert(errorMsg);

					this.saveLoading(false)

					console.log('error', error);
					console.log('error.response', error.response);
					// console.log('error.response.data', error.response.data);

					if (errorMsg) {

						console.log('errorMsg', errorMsg);


						if (errorMsg == "Password Expired") {

							return errorMsg;

						}

					}

				});

				console.log('result', result);

				if (result) {

					if (result == "Password Expired") {

						await this.$router.push({
							name: 'ChangePassword'
						});
						
						// this.vModels.userName = 'sdsd';
						// this.userName = 'sdsd';
						// this.$set(this.vModels, 'userName', 'use');

						return await this.showErrorAlert(this.$i18n.t('auth.changePassword.pleaseChangePassword'));

					}

					let redirect = {
						name: 'Dashboard'
					};

					if (this.$route.query.redirect) {

						redirect = {
							path: this.$route.query.redirect
						};

					}

					return this.$router.push(redirect);

				}

				this.submitting = false;

			},

			setDebugValues() {

				if (this.vModels && Object.keys(this.vModels).length) {

					Object.keys(this.vModels).forEach(key => {

						let val;

						switch (key) {

							case 'userName':
								val = process.env.VUE_APP_FORMS_PRE_FILLED_USERNAME;
								break;

							case 'password':
								val = process.env.VUE_APP_FORMS_PRE_FILLED_PASSWORD;
								break;

						}

						if (val) {

							this.$set(this.vModels, key, val);

						}

					});

				}

			}

		}

	};

</script>

<style lang="scss" scoped>

.text-white {
	color: white;
}

</style>