<template>

	<component
		v-if="mask"
		v-mask="mask"
		:is="componentType"
		:type="fieldType"
		:value="value"
		:name="nameCpu"
		:id="idCpu"
		:options="optionsCpu"
		:x-autocompletetype="xAutocompletetypeCpu"
		:custom-validation="!isValid"
		:is-valid="isValid"
		:invalid-feedback="invalidFeedback"
		v-bind="$attrs"
		v-on="inputListeners"
	>
		<template v-if="prepend" v-slot:prepend>{{ prepend }}</template>
		<slot v-for="(_, name) in $slots" :name="name" :slot="name" />
	</component>

	<component
		v-else-if="type === 'money' || money"
		v-currency-locale="locale"
		:is="componentType"
		type="number"
		:value="value"
		:name="nameCpu"
		:id="idCpu"
		:options="optionsCpu"
		:x-autocompletetype="xAutocompletetypeCpu"
		:custom-validation="!isValid"
		:is-valid="isValid"
		:invalid-feedback="invalidFeedback"
		v-bind="$attrs"
		v-on="inputListeners"
	>
		<template v-if="prepend" v-slot:prepend>{{ prepend }}</template>
		<slot v-for="(_, name) in $slots" :name="name" :slot="name" />
	</component>

	<component
		v-else
		:is="componentType"
		:type="fieldType"
		:value="value"
		:name="nameCpu"
		:id="idCpu"
		:options="optionsCpu"
		:x-autocompletetype="xAutocompletetypeCpu"
		:custom-validation="!isValid"
		:is-valid="isValid"
		:invalid-feedback="invalidFeedback"
		v-bind="$attrs"
		v-on="inputListeners"
	>
		<pre v-if="['datepicker', 'file'].includes(type)">@TODO: {{ type }}</pre>
		<template v-if="prepend" v-slot:prepend>{{ prepend }}</template>
		<slot v-for="(_, name) in $slots" :name="name" :slot="name" />
	</component>
</template>

<script>

	// import assignDeep from 'assign-deep';
	import { paramCase } from 'change-case';
	import CurrencyLocale, { formatValue } from '../../globalDirectives/CurrencyLocale';
	import FormFieldParent from './FormFieldParent';
	import FormInput from './Input';
	import FormSelect from './Select';
	import Checkbox from './Checkbox';
	import Radio from './Radio';

	export default {

		components: {
			FormFieldParent,
			FormInput,
			FormSelect,
			Checkbox,
			Radio
		},

		directives: {
			CurrencyLocale
		},

		inheritAttrs: false,

		props: {

			value: {
				type: [String, Number, Boolean, Array, Object, null],
				default: null
			},

			type: {
				type: String,
				default: 'text'
			},

			name: {
				type: String,
				default: null
			},

			locale: {
				type: String,
				default: 'en'
			},

			defaultLocale: {
				type: String,
				default: 'en'
			},

			empty: {
				type: Boolean,
				default: false
			},

			id: {
				type: String,
				default: null
			},

			options: {
				type: [Array, Object, Function],
				default: null
			},

			enableOnly: {
				type: [Array, String],
				default: null
			},

			xAutocompletetype: {
				type: String,
				default: null
			},

			error: {
				type: String,
				default: null
			},

			mask: {
				type: [String, Array],
				default: null
			},

			money: {
				type: [Object, Boolean],
				default: false
			},

			prepend: {
				type: String,
				default: null
			}

		},

		computed: {

			componentType() {

				switch (this.type) {

					case 'parent':
						return 'form-field-parent';

					case 'radio':
					case 'checkbox':
						return this.type;

					case 'select':
					case 'btn-group':
						return 'form-select';

					case 'datepicker':
					case 'file':
						return 'div';

					/*case 'file':
						return 'mdb-file-input';*/

					default:
						return 'form-input';

				}

			},

			fieldType() {

				if (['parent', 'checkbox', 'radio', 'datepicker', 'file'].includes(this.type)) {

					return null;

				}

				return this.type;

			},

			nameCpu() {

				return (this.name) ? this.name : 'field-' + this._uid;

			},

			idCpu() {

				return (this.id) ? this.id : paramCase(this.nameCpu);

			},

			xAutocompletetypeCpu() {

				if (this.autocomplete && !this.xAutocompletetype) {

					return this.autocomplete;

				}

				return this.xAutocompletetype;

			},

			optionsCpu() {

				if (!this.options) {

					return;

				}

				let options = this.options;

				if (Object.prototype.toString.call(this.options) === '[object Function]') {

					options = this.options();

				}

				if (options.constructor === Array) {

					let newOptions = options.map(val => {

						if (val.value !== undefined && val.text !== undefined) {

							let obj = Object.assign({}, val);
							obj.text = this.getTextFromLocalizedObject(obj.text);
							return obj;

						}

						return {
							value: this.getTextFromLocalizedObject(val, true),
							text: this.getTextFromLocalizedObject(val)
						};

					});

					if (this.empty) {

						newOptions.unshift({
							value: '',
							text: ''
						});

					}

					this.applyEnableOnly(newOptions);

					return newOptions;

				}

				let newOptions = Object.keys(options).map(key => {

					return {
						value: key,
						text: this.getTextFromLocalizedObject(options[key])
					};

				});

				this.applyEnableOnly(newOptions);

				if (this.empty) {

					newOptions.unshift({
						value: '',
						text: ''
					});

				}

				return newOptions;

			},

			isValid() {

				return !this.error;

			},

			invalidFeedback() {

				return this.error;

			},

			inputListeners() {

				let vm = this;

				return Object.assign({},
					this.$listeners,
					{

						blur: function (event) {

							vm.$emit('blur', event);

						},

						change: function (event) {

							vm.$emit('change', event);

						},

						focus: function (event) {

							vm.$emit('focus', event);

						},

						input: function (event) {

							vm.$emit('input', event);

						}

					});

			}

		},

		methods: {

			applyEnableOnly(options) {

				if (!this.enableOnly || !this.enableOnly.length) {

					return;

				}

				options.forEach(val => {

					if (
						(typeof this.enableOnly === 'string' && val.value === this.enableOnly)
						|| (Array.isArray(this.enableOnly) && this.enableOnly.includes(val.value))
					) {

						return;
					
					}

					val.disabled = true;

				});

			},

			isLocalizedObject(val) {

				if (!val || val.constructor !== Object) {

					return false;

				}

				return (val[this.locale] || val[this.defaultLocale]);

			},

			getTextFromLocalizedObject(val, defaultOnly = false) {

				if (!this.isLocalizedObject(val)) {

					return val;

				}

				return (!defaultOnly && val[this.locale]) || val[this.defaultLocale];

			}

		}

	};

</script>

<style lang="scss">

	.was-validated .valid-feedback {
		display: block;
	}
	.was-validated .invalid-feedback {
		display: block;
	}

</style>
