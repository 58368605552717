import * as actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
	options: {},
	info: {
		data: {},
		errors: {}
	},
	sections: {
		curAddressLess: false
	},
	coBorrower: {
		data: {},
		errors: {}
	},
	validateAll: false,
	applicationLists: {}
};

export default {
	state,
	actions,
	mutations,
	getters
};
