import { HeightTransition } from './Transitions';
import DashedLoader from './DashedLoader';
import MdbVue from './MdbVue';
import Icon from './Icon';
import Form from './Form';
import CardHead from './CardHead';
import Notify from './Notify';
import Navbar from './Navbar';

export default {

	install(Vue, options) {

		Vue.component('HeightTransition', HeightTransition);

		Vue.component('DashedLoader', DashedLoader);

		Vue.use(MdbVue);

		Vue.component('Icon', Icon);

		Vue.use(Form, options);

		Vue.component('CardHead', CardHead);

		Vue.use(Notify);

		Vue.component('Navbar', Navbar);

	}

};
