export const filesDefault = [
	require(`@/app/i18n/locales/en`),
	require(`@/app/auth/locales/en`)
];

export const filePromises = function (locale) {

	return [
		import(/* webpackChunkName: "lang-[request]" */ `@/app/i18n/locales/${locale}`),
		import(/* webpackChunkName: "lang-[request]" */ `@/app/auth/locales/${locale}`)
	];

};
