<template>
	<div class="payment-calculator-template">
		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1 v-if="!showConfirmation">Loan Payment Calculator</h1>
						<h1 v-if="showConfirmation">Installment Loan Estimate</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>
			<div class="container-xl template--vh">
				<div class="row">
					<div class="col-lg-16 order-2 order-lg-1" v-if="!showConfirmation">
						<div class="tffi-card tffi-card-main-card payment-calculator-main-card">
							<div class="payment-calculator-main-card__financed-amount">
								<label for="invoice-amount" class="theming primary-color fw-bold">Invoiced Amount</label>
								<input
									v-model="vModels.invoicedAmount"
									id="invoice-amount"
									name="invoicedAmount"
									type="number"
									class="my-0"
									:placeholder="$t('financingProgram.invoicedAmount')"
								>
							</div>

							<div class="payment-calculator-main-card__large-selector-area">
								<div class="payment-calculator-main-card__large-selector-area__label">
									<p>{{ $t("financingProgram.programType") }}</p>
								</div>
								<div class="payment-calculator-main-card__large-selector-area__buttons">
									<div
										:value="vModels.programType"
										name="programType"
										v-if="vModels.program"
										>
										<div class="row h-100">
											<div
												v-for="programType in programTypes"
												class="col-12 col-sm-12 col-lg-6 my-2 my-lg-3"
												>

												<button
													:key="programType.value"
													:value="programType.value"
													:active="programType.value === vModels.programType"
													class="tffi-btn tffi-btn--solid theming primary-color"
													:class="{'payment-calculator-main-card__buttons--active theming primary-color': programType.value === vModels.programType}"
													type="button"
													@click="[(vModels.programType = programType.value), toggleActiveBtn()]"
												>
													{{ programType.text }}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <div v-if="vModels.programType
								|| vModels.programType == 'equalPayment'
								|| vModels.programType == 'standard'
								|| vModels.programType == 'deferral'
								|| vModels.programType == 'buyDown'"
								> -->
								<hr class="theming primary-color mt-0"/>
							<!-- </div> -->

							<div v-if="vModels.programType
								&& vModels.programType != 'equalPayment'
								&& vModels.programType != 'standard'
								&& vModels.programType != 'deferral'
								&& vModels.programType != 'buyDown'"
								>
								<div class="payment-calculator-main-card__small-selector-area">
									<div class="payment-calculator-main-card__small-selector-area__label">
										<p>{{ $t("financingProgram.program") }}</p>
									</div>
									<div class="payment-calculator-main-card__small-selector-area__buttons">
										<div
											:value="vModels.program"
											name="program"
										>
											<div class="row">
												<div
													v-for="program in programs"
													class="col-12 col-sm-12 col-lg-6 my-3"
												>
													<button
														class="tffi-btn tffi-btn--solid"
														:class="{'payment-calculator-main-card__buttons--active theming primary-color': program === vModels.program}"
														:key="program.ProgramID"
														type="submit"
														:value="program.ProgramID"
														:active="program === vModels.program"
														@click="[(vModels.program = program), toggleActiveBtn()]"
													>
														{{ program.Description }}

														<span v-if="program.usr_DealerRentalInterestRate" class="text-1rem p-2 rate">{{ program.usr_DealerRentalInterestRate }} %</span>
														<!-- <span>{{ (program.programType ) ? $t('financingProgram.fixedRate') : '' }}</span> -->
														<span>{{ (program.programType) }}</span>
														<span>{{ program.leasingLabel != 'None' ? program.leasingLabel : '' }}</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="vModels.program
								&& rates
								&& vModels.programType == 'buyDown'
								">
								<div class="payment-calculator-main-card__small-selector-area">
									<div class="payment-calculator-main-card__small-selector-area__label mb-3">
										<p>APR</p>
									</div>
									<div class="payment-calculator-main-card__small-selector-area__buttons">
										<div
											:value="vModels.rate"
											name="rates"
										>
											<div class="row">
												<div
													v-for="(rate, index) in rates"
													class="col-12 col-sm-12 col-lg-6 mb-3"
												>
													<button
														class="tffi-btn tffi-btn--solid"
														:class="{'payment-calculator-main-card__buttons--active theming primary-color': rate === vModels.rate}"
														:key="index"
														type="submit"
														:value="index"
														color="primary"
														:active="rate === vModels.rate"
														@click="[(vModels.rate = rate), toggleActiveBtn()]"
														>
														{{ rate }} %
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="vModels.program && deferralPeriods && vModels.programType == 'deferral'">
								<div class="payment-calculator-main-card__small-selector-area mb-0">
									<div class="payment-calculator-main-card__small-selector-area__label mb-3">
										<p>Select Deferral options: </p>
									</div>
									<div class="payment-calculator-main-card__small-selector-area__buttons">
										<div
											:value="vModels.term"
											name="terms"
										>
											<div class="row">
												<div
													v-for="(deferralPeriod, index) in deferralPeriods"
													class="col-12 col-sm-12 col-lg-6 mb-3"
												>
													<button
														class="tffi-btn tffi-btn--solid"
														:class="{'payment-calculator-main-card__buttons--active theming primary-color': deferralPeriod === vModels.deferralPeriod}"
														:key="index"
														type="submit"
														:value="index"
														:active="deferralPeriod === vModels.deferralPeriod"
														@click="[(vModels.deferralPeriod = deferralPeriod), toggleActiveBtn()]"
														>
														{{ deferralPeriod }} months
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="vModels.program && terms">
								<div class="payment-calculator-main-card__small-selector-area mb-0">
									<div class="payment-calculator-main-card__small-selector-area__label mb-3">
										<p>Select a financing term: </p>
									</div>
									<div class="payment-calculator-main-card__small-selector-area__buttons">
										<div
											:value="vModels.term"
											name="terms"
										>
											<div class="row">
												<div
													v-for="(term, index) in terms"
													class="col-12 col-sm-12 col-lg-6 mb-3"
												>
													<button
														class="tffi-btn tffi-btn--solid theming primary-color"
														:class="{'payment-calculator-main-card__buttons--active theming primary-color': term === vModels.term}"
														:key="term"
														type="submit-"
														:value="term"
														color="primary"
														:active="term === vModels.term"
														@click="[(vModels.term = term), toggleActiveBtn()]"
														>
														{{ term }} months
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="vModels.program && amortTerms && vModels.programType != 'buyDown'">
								<div class="payment-calculator-main-card__small-selector-area mb-0">
									<div class="payment-calculator-main-card__small-selector-area__label mb-3">
										<p>Amortization options: </p>
									</div>
									<div class="payment-calculator-main-card__small-selector-area__buttons">
										<div
											:value="vModels.term"
											name="terms"
										>
											<div class="row">
												<div
													v-for="(amortTerm, index) in amortTerms"
													class="col-12 col-sm-12 col-lg-6 mb-3"
												>
													<button
														class="tffi-btn tffi-btn--solid theming primary-color"
														:class="{ 'payment-calculator-main-card__buttons--active theming primary-color': amortTerm === vModels.amortTerm }"
														:key="index"
														type="submit"
														:value="index"
														:active="amortTerm === vModels.amortTerm"
														@click="[(vModels.amortTerm = amortTerm), toggleActiveBtn()]"
														>
														{{ amortTerm }} months
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

					<!--Side Card-->
					<div :class="(!showConfirmation === true) ? 'col-lg-8 col-t1 order-1 order-lg-2' : 'installment-loan-estimate-template col-sm-24 col-md-14 col-lg-10 mx-auto col-t2'">
						<div class="tffi-card tffi-card-summary-card">
							<div class="tffi-card-summary-card__top-area theming primary-color">
								<!-- <div class="tffi-card-summary-card__top-area__overlay-gradient"></div> -->
								<div class="tffi-card-summary-card__top-area__overlay-image"></div>
								<div class="tffi-card-summary-card__top-area__title-area">
									<h2>Installment loan estimate</h2>
								</div>
								<div class="tffi-card-summary-card__top-area__financed-amount">
									<p v-if="vModels.invoicedAmount">Invoiced Amount: <span class="tffi-card-summary-card__top-area__financed-amount__price">${{ vModels.invoicedAmount | formatMoney}}</span></p>
								</div>
								<div class="tffi-card-summary-card__top-area__financed-amount">
									<p v-if="vModels.programCharges">Program Charges: <span class="tffi-card-summary-card__top-area__financed-amount__price">${{ vModels.programCharges | formatMoney }}</span></p>
								</div>
								<div class="tffi-card-summary-card__top-area__financed-amount">
									<p v-if="vModels.financedAmount">Financed Amount: <span class="tffi-card-summary-card__top-area__financed-amount__price">${{ vModels.financedAmount | formatMoney }}</span></p>
								</div>
							</div>
							<div class="tffi-card-summary-card__bottom-area">
								<div v-if="vModels.programType" class="tffi-card-summary-card__bottom-area__label">
									<p>Program Type:</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>{{ getProgramType(vModels.programType) }}</p>
								</div>
								<div v-if="vModels.leasingPeriod" class="tffi-card-summary-card__bottom-area__label">
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>{{ $t('financingProgram.leasingPeriod') }}: {{ vModels.leasingPeriod || 'N/A' }} </p>
								</div>
								<div v-if="vModels.rate != null" class="tffi-card-summary-card__bottom-area__label">
									<p>Rate: </p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>{{ vModels.rate }}%</p>
								</div>
								<div v-if="vModels.term" class="tffi-card-summary-card__bottom-area__label">
									<p>Finance Term:</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>{{ vModels.term }} Months</p>
								</div>
								<div v-if="vModels.amortTerm" class="tffi-card-summary-card__bottom-area__label">
									<p>Amortization Term:</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>{{ vModels.amortTerm }} Months</p>
								</div>
								<div v-if="vModels.monthly" class="tffi-card-summary-card__bottom-area__label">
									<p class="theming primary-color fw-bold mb-0">Base Monthly Payment: </p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p class="theming primary-color fw-bold mb-0">${{ vModels.monthly | formatMoney }}</p>
								</div>
							</div>
						</div>
					</div>

					

				</div>
			</div>

			<div class="tffi-navigation-bottom">
				<div class="container-xl">
					<div class="row">
						<div class="col d-flex justify-content-start">
							<button
								class="tffi-btn tffi-btn--solid theming secondary-color"
								v-if="showConfirmation"
								@click="showConfirmation = false">
								Back
							</button>
						</div>
						<div v-if="!showConfirmation" class="col d-flex justify-content-end">
							<button
								class="tffi-btn tffi-btn--solid theming secondary-color"
								type="submit"
								@click="showConfirmationMethod()">
								{{ $t('forms.next') }}
							</button>
						</div>
						<div v-if="showConfirmation" class="col d-flex justify-content-end">
							<button
								class="tffi-btn tffi-btn--solid theming secondary-color"
								type="submit"
								@click="saveAndGo()"
								>
								{{ $t('forms.next') }}
							</button>
						</div>
					</div>

					<!-- <span class="flex-grow-1"></span> -->

					<router-link v-if="showConfirmation && false" tag="div" :to="{ name: 'ApplicationStep1', params: { applicationId: 'new' } }">
						<button class="tffi-btn tffi-btn--solid theming primary-color">Next</button>
					</router-link>
				</div>
			</div>
		</form>
	</div>

</template>

<script>

	import ApplicationMixin from './ApplicationForms/Application/ApplicationMixin';
	import { getPrograms, getProgramOptions, saveApplication, getApplication } from '../../api';
	import { isNumber } from '@incodeapps/js-utilities';

	export default {

		mixins: [
			ApplicationMixin
		],

		filters: {
			formatMoney(value) {
				return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(value);
			},
		},

		data() {

			return {
				isActiveBtn: false,
				paymentTerm: [
					{text: 'Select Financing Program first', value: 'default'}
				],
				programs: [],
				programTypes: [
					{text: 'Standard', value: 'standard'},
					{text: 'Equal Payment', value: 'equalPayment'},
					{text: 'Buy Down', value: 'buyDown'},
					{text: 'Deferral', value: 'deferral'}
				],
				showConfirmation: false,
				amortTerms: [],
				terms: [],
				rates: [],
				deferralPeriods: [],

				submitting: false,
				isLoading: true,

				leasing: {},
				equalPayment: {},
				buyDown: {},
				standard: {},
				deferral: {},

				vModels: {
					program: null,
					invoicedAmount: 1000,
					financedAmount: 1000,
					programType: null,
					term: null,
					rate: null,
					monthly: null,
					paymentTerm: "default",
					amortTerm: null,
					deferralPeriod: null
				}

			};

		},

		watch: {

			'vModels.invoicedAmount': {
				async handler(val) {
					console.log('invoicedAmount changed');
					this.doCalculations();
				}
			},

			'vModels.programType': {

				async handler(val) {

					console.log('programType changed');

					this.saveLoading(true);

					// console.log('vModels.programType', val);

					this.programs = null;
					this.rates = null;
					this.amortTerms = null;
					this.vModels.program = null;
					this.vModels.rate = null;
					// this.vModels.amortTerm = null;
					this.vModels.monthly = null;
					this.vModels.deferralPeriod = null;


					// await setInterval(() => {
					// 	// workaround to this.programs not being properly replaced
					// }, 1000);

					let valuesArray

					if (val == 'equalPayment') {

						this.programs = this.equalPayment;
						this.vModels.programType = 'equalPayment'
						valuesArray = Object.values(this.equalPayment);

					} else if (val == 'deferral') {

						this.programs = this.deferral;
						valuesArray = Object.values(this.deferral);
					
					} else if (val == 'standard') {

						this.programs = this.standard;
						valuesArray = Object.values(this.standard);

					} else {
						
						this.programs = this.buyDown;
						valuesArray = Object.values(this.buyDown);

					}

					let programId = valuesArray[0].ProgramID
					this.vModels.program = valuesArray[0]
					this.vModels.program.ProgramID = programId
					this.vModels.programId = programId

					this.saveLoading(false);

				}

			},

			'vModels.program': {

				async handler(val) {

					console.log('program changed', val);

					if (
						val == null
						|| !val
					) {
						return;
					}

					console.log('val not null');

					this.rates = null;
					this.amortTerms = null;
					this.vModels.rate = null;
					// this.vModels.amortTerm = null;
					this.terms = null;

					this.saveLoading(true);
				
					// console.log('vModels.program', val);
					// console.log('application', this.application.data.id);

					let payload = {
						programId: val.ProgramID
					};

					await this.getProgramOptions(payload);
					// // console.log('programOptions', result);

					this.saveLoading(false);

				}

			},

			'vModels.term': {

				async handler(val) {

					console.log('term changed');

					if (this.vModels.programType == 'equalPayment') {
						this.vModels.amortTerm = this.vModels.term
					}
					this.doCalculations()
				},

			},
				
			'vModels.amortTerm': {

				async handler(val) {
					console.log('amortTerm changed');
					this.doCalculations()
				}

			},
			
			'vModels.rate': {

				async handler(val) {
					console.log('rate changed');
					this.doCalculations()
				}

			}

		},

		async mounted() {
			
			this.saveLoading(true);

			if (this.$route.params.applicationId == 'new') {
				console.log('------------------------------- reset');
				this.resetApp()
			}

			await this.getPrograms();

			let assignedType = false;
			let finalTypes = []

			this.programTypes.forEach((type, i) => {

				let programType = type.value

				if (
					Object.keys(this[programType]).length > 0
				) {
					finalTypes.push(type)
				}

			});

			this.programTypes = finalTypes

			finalTypes.forEach((type, i) => {

				let programType = type.value
				if (
					Object.keys(this[programType]).length > 0
					&& !assignedType
				) {
					this.vModels.programType = programType
					assignedType = true
				}

			});

			let id = this.$route.params.applicationId

			this.vModels.coApps = null

			console.log('id', id);

			if (!id) {
				// return;
			}

			if (id) {

				let app = await getApplication(id)

				if (app) {
	
					console.log('app', app);

					Object.keys(app.application.program).forEach((element, i) => {
						this.vModels[element] = app.application.program[element]
					});
	
				}

			} else {

			}

			this.saveLoading(false);

		},

		methods: {
			toggleActiveBtn() {
				console.log('toggleActiveBtn');
      			// this.isActiveBtn = !this.isActiveBtn;
			},

			async saveAndGo() {

				this.saveLoading(true);

				let id = null;

				if (this.$route.params.applicationId) {
					id = this.$route.params.applicationId
				}

				let result = await this.saveApplicationAction({
					data: {
						program: this.vModels,
						id
					}
					// errors: this.errors
				});

				console.log('result', result);
				
				this.saveLoading(false);

				return this.$router.push({
					name: `ApplicationStep1`,
					params: {
						applicationId: result
					}
				});


			},
			
			async showConfirmationMethod() {

				this.showConfirmation = true;

			},

			async doCalculations() {

				console.log('this.vModels before', this.vModels);

				this.vModels.monthly = null

				// console.log('vModels.amortTerm', this.vModels.amortTerm);


				let monthlyRate = (this.vModels.rate / 100) / 12;
				// console.log('monthlyRate', monthlyRate);

				let amortizationTerm = this.vModels.amortTerm; // ?? this.vModels.term
				let financeTerm = this.vModels.term; // ?? this.vModels.term
				// console.log('amortizationTerm', amortizationTerm);
				// console.log('this.vModels.term', this.vModels.term);

				let intermediateAmortization = Math.pow((1 + monthlyRate), amortizationTerm)
				// console.log('intermediateAmortization', intermediateAmortization);

				// console.log('this.vModels.term', this.vModels.term);
				let intermediateTerm;

				if (financeTerm) {
					intermediateTerm = Math.pow((1 + monthlyRate), financeTerm)
				}
				// console.log('intermediateTerm', intermediateTerm);

				this.vModels.monthly = monthlyRate / amortizationTerm

				this.vModels.financedAmount = (Number(this.vModels.programCharges) || 0) + Number(this.vModels.invoicedAmount)

				if (monthlyRate < 0.0000001) {
					this.vModels.monthly = (this.vModels.financedAmount / amortizationTerm)
				} else {
					this.vModels.monthly = (this.vModels.financedAmount * monthlyRate * intermediateAmortization) / (intermediateAmortization - 1) // this.vModels.amount / amortizationTerm
				}

				if (isNumber(this.vModels.monthly)) {
					// let tempMonthly = this.vModels.monthly
					this.vModels.monthly = this.vModels.monthly.toFixed(2)
					this.vModels.totalPayments = (this.vModels.monthly * financeTerm).toFixed(2)
				}

				if (monthlyRate < 0.00001) {
					this.vModels.residual = this.vModels.financedAmount - this.vModels.totalPayments
				} else {
					this.vModels.residual = (this.vModels.financedAmount * intermediateTerm) - [this.vModels.monthly * (intermediateTerm - 1) / monthlyRate]
				}
				
				this.vModels.endOfTermResidual = Number(this.vModels.residual) * (1 + monthlyRate)
				this.vModels.totalObligation = Number(this.vModels.totalPayments) + Number(this.vModels.endOfTermResidual)
				this.vModels.costBorrowing = Number(this.vModels.totalObligation) + Number(this.vModels.financedAmount)

				console.log('this.vModels after', this.vModels);

			},

			async getPrograms() {

				this.isLoading = true;
				this.submitting = true;
				this.saveLoading(true);


				let result = await getPrograms().catch(async error => {

					// this.saveLoading(false);

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				// console.log('result.programs', result.programs);
				
				//return;

				let standard = {};
				let equalPayment = {};
				let buyDown = {};
				let leasing = {};
				let deferral = {};

				if (result.programs.length == 0) {
					this.errorAlert = await this.showErrorAlert('No programs were found');
					this.saveLoading(false);
					return;
				}

				Object.keys(result.programs).forEach(key => {
					
					let program = result.programs[key];

					// console.log('program', program);
					// console.log('program.Code', program.Code);

					let programCodes = {
						2: 'leasing',
						32: 'standard',
						36: 'buyDown',
						37: 'equalPayment',
						38: 'deferral'
					}

					switch (program.Code) {
						case '2':
							// leasing[program.ProgramID] = program;
							break;
						case '32':
							// console.log('found 32');
							standard[program.ProgramID] = program;
							break;
						case '36':
							buyDown[program.ProgramID] = program;
							break;
						case '37':
							equalPayment[program.ProgramID] = program;
							break;
							
						case '38':
							deferral[program.ProgramID] = program;
							break;
						default:
							break;
					}

					// if (
					// 	program.Description.indexOf('Buy-Down') > -1
					// 	|| program.Description.indexOf('buyDown') > -1
					// ) {
						
					// 	// console.log('buyDown', buyDown);
						
					// } else if (
					// 		program.leasingLabel
					// 	&& program.leasingLabel.indexOf('None') > -1
					// ) {

					// 	equalPayment[program.ProgramID] = program;
					// 	// console.log('equalPayment', equalPayment);

					// } else {
						
					// 	leasing[program.ProgramID] = program;
					// 	// console.log('leasing', leasing);

					// }


				});

				// console.log('leasing', leasing);
				// console.log('standard', standard);
				// console.log('buyDown', buyDown);
				// console.log('equalPayment', equalPayment);

				// // console.log('buyDown.length', Object.keys(buyDown).length);

				// if (Object.keys(buyDown).length > 0) {
				// 	// this.programTypes.push(
				// 	// 	{text: 'Buy Down', value: 'buyDown'}
				// 	// );
				// }
				
				this.buyDown = buyDown;
				this.equalPayment = equalPayment;
				this.standard = standard;
				this.deferral = deferral;

				console.log('this.deferral', this.deferral);
				
				// console.log('this.leasing', this.leasing);
				// console.log('this.equalPayment', this.equalPayment);
				// console.log('this.buyDown', this.buyDown);

				this.programs = null;
				this.programs = {};
				// console.log('after nulling this.programs', this.programs);
				this.programs = this.leasing;
				// console.log('after assigning this.programs', this.programs);

				this.saveLoading(false);

			},

			async getProgramOptions(programId) {

				console.log('programId', programId);

				if (!programId.programId) {
					return;
				}

				this.submitting = true;

				let result = await getProgramOptions(programId).catch(async error => {

					this.saveLoading(false);

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				// console.log('result', result);

				if (!result) {
					return;
				}

				// this.vModels.invoicedAmount = Number(result.invoicedAmount).toFixed(2);

				console.log('this.vModels.program', this.vModels.program);

				if (
					this.vModels.program
					&& (
						this.vModels.programType == 'equalPayment'
						|| this.vModels.programType == 'standard'
					)
				) {

					this.vModels.rate = result.rates[result.rates - 1];

				}

				console.log('result', result);

				if (result.hasOwnProperty('rates')) {
					this.rates = result.rates;
					this.vModels.rate = result.rates[this.rates.length - 1]

					// if (Object.keys(result.rates) > 1) {
					// } else {
					// 	this.vModels.rate = result.rates.rate
					// }
				}
				
				if (result.hasOwnProperty('programCharges')) {
					this.vModels.programCharges = result.programCharges.Amount._text
				} else {
					this.vModels.programCharges = 0
				}
				
				if (
					result.hasOwnProperty('deferralPeriods')
					&& this.vModels.programType == 'deferral'
				) {
					result.deferralPeriods.sort()
					this.deferralPeriods = result.deferralPeriods
					this.vModels.deferralPeriod = this.deferralPeriods[this.deferralPeriods.length - 1]
				}


				// result.terms['default'] = {
				// 	en: "Choose Payment Term"
				// };

				// // console.log('result.terms', result.amortTerms);

				this.paymentTerm = null;

				this.removeAlerts();

				// console.log('this.vModels', this.vModels);

				// await setInterval(() => {
				// 	// workaround to this.vModels.programs not being properly replaced
				// }, 500);

				if (
					result.hasOwnProperty('amortTerms')
					&& result.amortTerms
					&& result.amortTerms.length
					&& this.vModels.programType != 'equalPayment'
				) {
					this.amortTerms = result.amortTerms;
				}

				if (
					result.hasOwnProperty('terms')
				) {
					this.terms = result.terms;
					this.vModels.term = this.terms[this.terms.length - 1]
				}

				if (
					result.hasOwnProperty('amortTerms')
					&& this.vModels.programType != 'equalPayment'
				) {
					this.vModels.amortTerm = this.amortTerms[this.amortTerms.length - 1]
				}

				if (this.vModels.programType == 'equalPayment') {
					this.vModels.amortTerm = this.vModels.term
				}

			},

			onSubmit(e) {

				console.log('onSubmit');

				this.isLoading = true;
				this.submitting = true;

				// // console.debug(
				// 	'[onSubmit] event:', e,
				// 	'\nvModels:', JSON.stringify(this.vModels, null, 2)
				// );

			}

		}

	};

</script>

<style lang="scss" scoped>

	.btn-group {
		display: flex;
		flex-wrap: wrap;

		.btn {
			border-radius: $btn-border-radius !important;
			width: 30%;
			max-width: 31.5%;
			margin: 1rem 1%!important;

			&.lease-date {
				// margin: 1rem 1%;
				width: 10%;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0.2rem 0%;
					padding: 10px!important;
				}
			}

			@media (max-width: 767px) {
				width: 100%;
				margin: 0.5rem 0%;

				&.program-btn {
					padding: 10px!important;
				}

				span.rate {
					font-size: 2rem!important;
				}

			}



			span {
				display: block;
			}

			&.btn-primary {
				&.active, &:active {
					background-color: $info !important;
				}
			}

		}

	}

</style>
