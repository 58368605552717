<template>

	<mdb-container class="mt-5">

		<form @submit.prevent="onSubmit" novalidate>

			<mdb-card>
				<mdb-card-body>

					<fieldset>

						<legend class="d-none">Change password</legend>

						<form-field
							v-model="vModels.userName"
							:error="errors.userName"
							:label="$t('auth.changePassword.userName')"
							type="text"
							name="userName"
							autocomplete="username"
							required
							bootstrap
							@change="onChange"
						></form-field>

						<form-field
							v-model="vModels.password"
							:error="errors.password"
							:label="$t('auth.changePassword.password')"
							type="password"
							name="password"
							autocomplete="current-password"
							required
							bootstrap
							@change="onChange"
						></form-field>

						<form-field
							v-model="vModels.newPassword"
							:error="errors.newPassword"
							:label="$t('auth.changePassword.newPassword')"
							type="password"
							name="newPassword"
							autocomplete="new-password"
							required
							bootstrap
							@change="onChange"
						></form-field>

						<form-field
							v-model="vModels.confirmNewPassword"
							:error="errors.confirmNewPassword"
							:label="$t('auth.changePassword.confirmNewPassword')"
							type="password"
							name="confirmNewPassword"
							autocomplete="new-password"
							required
							bootstrap
							@change="onChange"
						></form-field>

					</fieldset>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group text-center">

				<btn
					type="submit"
					name="next"
					color="info"
					size="lg"
					rounded
					block
					style="white-space: nowrap"
					:loading="submitting"
					spinner-absolute
				>{{ $t('auth.changePassword.submit') }}</btn>

			</div>

		</form>

	</mdb-container>

</template>

<script>

	import JoiValidator from '@incodeapps/joi-validator';
	import Spinner from 'vue-loading-overlay/src/loaders/spinner';
	import { mapActions } from 'vuex';
	import { session } from '../../api';
	import { throttle } from 'throttle-debounce';

	export default {

		components: {
			Spinner
		},

		data() {

			return {
				vModels: {
					userName: '',
					password: '',
					newPassword: '',
					confirmNewPassword: ''
				},
				validated: false,
				submitting: false,
				errors: {},
				formValidator: null,
				errorAlert: null
			};

		},

		created() {

			// console.log('this.user', this.vModels.userName);
			// console.log('this.user', this.userName);

			session().then(res => {

				this.setData(res);

			}, error => {

				let errorMsg = error.toString();

				if (error.response && error.response.data && error.response.data.errorMessage) {

					errorMsg = error.response.data.errorMessage;

				}

				console.log(errorMsg);
				
				if (
					errorMsg
					&& errorMsg != "Error: Authorization token missing."
				) {

					this.throwFatalError(errorMsg);

				}

			});

			this.formValidator = new JoiValidator(Joi => ({

				userName: Joi.string().required(),
				password: Joi.string().required(),

				newPassword: Joi.string().required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/).error(() => {
					return {
						message: 'Password must be at least 8 characters long, contain a uppercase letter, a lowercase letter, a symbol and a number.',
					};
				}),

				confirmNewPassword: Joi.string().required().when('newPassword', {
					is: Joi.string().required(),
					then: Joi.valid(Joi.ref('newPassword')).error(error => {

						if (error && error[0] && error[0].type === 'any.allowOnly') {

							error[0].message = this.$i18n.t('auth.changePassword.errors.confirmPassword');

						}

						return error;

					})
				})

			}), ['en', 'fr']);

		},

		beforeDestroy() {

			this.removeAlert(this.errorAlert);

		},

		methods: {

			...mapActions([
				'throwFatalError',
				'showErrorAlert',
				'showSuccessAlert',
				'removeAlert',
				'changePasswordAction'
			]),

			setData(res) {

				if (res && res.Item && res.Item.user && res.Item.user.userName) {

					this.$set(this.vModels, 'userName', res.Item.user.userName);

				}

			},

			async resetForm() {

				await this.removeAlert(this.errorAlert);

				Object.keys(this.vModels).forEach(field => {

					if (field === 'userName') {

						return;

					}

					this.$set(this.vModels, field, '');

				});

				this.$set(this.$data, 'validated', false);
				this.$set(this.$data, 'errors', {});

			},

			validate() {

				let errors = this.formValidator.validate(this.vModels, null, this.locale);

				this.validated = true;

				this.$set(this.$data, 'errors', {});

				if (errors) {

					Object.keys(errors).forEach(i => {

						this.$set(this.errors, errors[i].key, errors[i].message);

					});

					return false;

				}

				return true;

			},

			onChange: throttle(300, function () {

				if (!this.validated) {

					return;

				}

				this.validate();

			}),

			async onSubmit() {

				this.submitting = true;

				await this.removeAlert(this.errorAlert);

				if (!this.validate()) {

					this.errorAlert = await this.showErrorAlert(this.$i18n.t('auth.changePassword.errors.formErrors'));

					this.submitting = false;

					return;

				}

				let payload = {
					userName: this.vModels.userName,
					password: this.vModels.password,
					newPassword: this.vModels.newPassword
				};

				let result = await this.changePasswordAction(payload)
					.catch(async error => {

						let errorMsg = error.toString();

						if (error.response && error.response.data && error.response.data.errorMessage) {

							errorMsg = error.response.data.errorMessage;

						}

						if (errorMsg) {

							this.errorAlert = await this.showErrorAlert(errorMsg);

						}

					});

				if (result) {

					await this.$router.push({
						name: 'Login'
					});

					this.vModels.userName = payload.userName;

					this.showSuccessAlert(this.$i18n.t('auth.changePassword.success'));

					await this.resetForm();

				}

				this.submitting = false;

			}

		}

	};

</script>
