<template>
	<div class="stepper theming tertiary-color">
		<div class="container-lg px-0">
			<div class="row">
				<div class="col-md-24">
					<div class="d-flex flex-row stepper-m">
						<div class="stepper__steps flex-grow-1" v-for="(step, index) in steps" :key="index">
							<!-- <div v-if="index > 0" class="stepper__steps__line current">
								<hr>
							</div> -->
							<div class="stepper__steps__step">
								<router-link
									v-if="compare(step.num) === 1" :to="getRoute(step.num)"
									class="stepper__steps__step__icon"
									:class="[(index === steps.current) ? 'hello' : 'bye']">
									{{ step.num }}
								</router-link>

								<router-link
									v-else-if="compare(step.num) === -1" :to="getRoute(step.num)"
									class="stepper__steps__step__icon"
									:class="[(index === steps.num) ? 'hello' : 'bye']">
									{{ step.num }}
								</router-link>

								<router-link
									v-else :to="getRoute(step.num)"
									class="stepper__steps__step__icon"
									:class="[(index === steps.current) ? 'hello' : 'bye']">
									{{ step.num }}
								</router-link>

								<div class="stepper__steps__step__label">
									<p class="fw-bold">{{ step.label ? step.label : stepLabels[step.num - 1] }}</p>
								</div>
							</div>
							<div v-if="index < (steps.length - 1)" class="stepper__steps__line current">
								<hr>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <mdb-card class="under-nav">
			<mdb-container>
				<mdb-card-body class="px-sm-0">

					<div class="steps-form">
						<div class="steps-row">

							<div v-for="(step, index) in steps" :key="index" class="steps-step">

								<router-link
									v-if="compare(step.num) === 1"
									:to="getRoute(step.num)"
									type="button"
									class="btn btn-circle btn-white btn-outline-primary"
								>{{ step.num }}</router-link>

								<router-link
									v-else-if="compare(step.num) === -1"
									:to="getRoute(step.num)"
									type="button"
									class="btn btn-circle btn-info"
								><icon name="done" type="ma" /></router-link>

								<router-link
									v-else
									:to="getRoute(step.num)"
									type="button"
									class="btn btn-circle btn-info"
								>{{ step.num }}</router-link>

								<p>{{ step.label ? step.label : step.name }}</p>

							</div>

						</div>
					</div>

				</mdb-card-body>
			</mdb-container>
		</mdb-card>


		<div class="stepper theming tertiary-color">
			<div class="container-lg px-0">
				<div class="row">
					<div class="col-md-24">
						<div class="stepper__steps">
							<div class="stepper__steps__step">
								<div class="stepper__steps__step__icon">
									1
								</div>
								<div class="stepper__steps__step__label">
									<p>Applicants</p>
								</div>
							</div>
							<div class="stepper__steps__line current">
								<hr>
							</div>
							<div class="stepper__steps__step">
								<div class="stepper__steps__step__icon current">
									2
								</div>
								<div class="stepper__steps__step__label current">
									<p>Details</p>
								</div>
							</div>
							<div class="stepper__steps__line">
								<hr>
							</div>
							<div class="stepper__steps__step">
								<div class="stepper__steps__step__icon">
									3
								</div>
								<div class="stepper__steps__step__label">
									<p>Equipment</p>
								</div>
							</div>
							<div class="stepper__steps__line">
								<hr>
							</div>
							<div class="stepper__steps__step">
								<div class="stepper__steps__step__icon">
									4
								</div>
								<div class="stepper__steps__step__label">
									<p>Review and Submit</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
</template>

<script>

import { mapState } from 'vuex';

let stepsLabel = [
	'Applicants',
	'Details',
	'Equipment',
	'Review and Submit'
]

let guestStepsLabel = [
	'Applicant',
	'Review and Submit'
]

let phase2 = [
	'Sign',
	'Bank Verification',
	'Uploads',
	'Review & Submit',
]

let phase2Components = [
	'Sign',
	'BankVerification',
	'UploadFiles',
	'ApplicationDashboard'
]

let labels = {
	Sign: 'Sign',
	BankVerification: 'BankVerification',
	UploadFiles: 'Upload',
	ApplicationDashboard: 'ApplicationDashboard',
}

export default {

	props: {

		current: {
			type: [String, Number],
			default: 0
		},

		total: {
			type: Number,
			default: 3
		},

		scan: {
			type: Boolean,
			default: false
		},

		label: {
			type: String,
			default: ''
		}

	},

	data() {

		return {
			applicationId: null,
			stepsActivationFlag: false,
		};

	},

	computed: {

		...mapState({
			application: state => state.application.info
		}),

		steps() {

			let steps = [];

			for (let i = this.scan ? 0 : 1; i <= this.total; i++) {

				steps.push({
					num: i,
					name: i ? this.$i18n.t('forms.steps', { step: i }) : this.$i18n.t('forms.scan'),
					label: this.label ? labels[phase2[i]] : ''
				});

			}

			return steps;

		},

		stepLabels() {

			if (['GuestApplication', 'GuestApplicationReview'].includes(this.$route.name)) {
				return guestStepsLabel
			}
			
			if (['Sign', 'BankVerification', 'UploadFiles', 'ApplicationDashboard'].includes(this.$route.name)) {
				// console.log();
				return phase2
			}

			return stepsLabel
		}

	},

	watch: {

		application: {
			handler(val) {

				if (!val || !val.data || !val.data.id) {

					return;

				}

				this.applicationId = val.data.id;

			},
			deep: true,
			immediate: true
		}

	},

	methods: {

		compare(num) {

			let current = parseInt(this.current.toString().substr(0, 1));

			if (current < num) {

				return 1;

			}

			if (current > num) {

				return -1;

			}

			return 0;

		},

		getRoute(num) {

			if (['GuestApplication', 'GuestApplicationReview'].includes(this.$route.name)) {
				
				return {
					name: num == 1 ? 'GuestApplication' : 'GuestApplicationReview',
					params: this.$route.params,
					query: this.$route.query
				}
				
				return {
					name: 'GuestApplication',
					params: this.$route.params,
					query: {
						token: this.$route.query.token,
						page: num == 1 ? 'input' : 'review'
					}
				};

			}

			if (['Sign', 'UploadFiles', 'BankVerification', 'ApplicationDashboard'].includes(this.$route.name)) {
				return {
					name: phase2Components[num - 1],
					params: this.$route.params,

				};
			}
				

			let match = this.$route.name.match(/^(ApplicationStep|CoBorrowerStep)(.*?)$/);

			let defaultParams = {
				applicationId: this.applicationId
			};

			let params = (this.$route.params && this.$route.params.applicationId) ? this.$route.params : defaultParams;

			if (!match || !match[1]) {

				return {
					name: 'ApplicationStep1',
					params
				};

			}

			let beginning = match[1];

			if (phase2.includes(match[1])) {

				return {
					name: phase2[num],
					params
				};

			}

			return {
				name: beginning + num,
				params
			};

		}

	}

};

</script>

<style lang="scss" scoped>
.steps-form .steps-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	color: $blue;

	&:before {
		top: 20px;
		left: 10px;
		right: 10px;
		width: auto;
		height: 2px;
		background-color: $blue;
	}

	.steps-step {
		display: block !important;

		@media all and (-ms-high-contrast:none) {
			margin-left: auto;
			margin-right: auto;
		}

		.btn-circle {
			opacity: 1 !important;
			font-size: 1rem;
			font-weight: bold;
			padding: 8px 0;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 2rem;
			box-shadow: none;
			margin-right: 0;
			margin-left: 0;

			&.btn-info {
				padding: 9px 0;

				&:before {
					position: absolute;
					top: 20px;
					left: 10px;
					right: 10px;
					width: auto;
					height: 2px;
					background-color: $blue;
				}
			}

			&.btn-white.btn-outline-primary {

				&,
				&:hover,
				&:focus,
				&:active {
					background: $white !important;
				}
			}
		}

	}

}
</style>
