<template>

	<mdb-container class="mt-5">
		
		<h1>{{ $t('pageNotFound.title') }}</h1>
		<p class="font-weight-normal">{{ $t('pageNotFound.paragraph', {url}) }}</p>
		<btn color="info" @click="$router.go(-1)">{{ $t('pageNotFound.btn') }}</btn>
		
	</mdb-container>
	
</template>

<script>
	
	export default {
		
		name: 'PageNotFound',
		
		computed: {
			
			url() {
				
				return this.$route.path;
				
			}
			
		}
		
	};

</script>

<style lang="scss" scoped>

</style>