import { render, staticRenderFns } from "./List.vue?vue&type=template&id=50a1710b&scoped=true&"
import script from "./List.vue?vue&type=script&lang=js&"
export * from "./List.vue?vue&type=script&lang=js&"
import style0 from "./List.vue?vue&type=style&index=0&id=50a1710b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a1710b",
  null
  
)

export default component.exports