<template>
	<div class="application-dashboard-template">
		<steps :current="4" :total="4" />

		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Dashboard</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>
			<div class="container-xl template--vh-">
				<div class="row">
					<div class="col-lg-8 d-none">
						<router-link tag="div" :to="{ name: 'ApplicationStep1', params: $route.params }">
							<button type="button"
								class="tffi-btn tffi-btn--solid tffi-btn--bold theming accent-color primary-color-btn-solid w-100 mb-4"
								color="primary">
								Add Note
							</button>
						</router-link>
					</div>

					<div class="col-lg-8">

						<div class="tffi-card tffi-card-summary-card">
							<div class="tffi-card-summary-card__top-area theming primary-color">
								<!-- <div class="tffi-card-summary-card__top-area__overlay-gradient"></div> -->
								<div class="tffi-card-summary-card__top-area__overlay-image"></div>
								<div class="tffi-card-summary-card__top-area__title-area">
									<h2>{{ programType }}</h2>
								</div>
								<div class="tffi-card-summary-card__top-area__financed-amount">
									<p v-if="vModels.program.financedAmount">Financed Amount: <span
											class="tffi-card-summary-card__top-area__financed-amount__price">
											${{ vModels.program.financedAmount | formatMoney }}</span></p>
								</div>
							</div>
							<div class="tffi-card-summary-card__bottom-area">
								<div v-if="vModels.program.programType" class="tffi-card-summary-card__bottom-area__label">
									<p>Program Type:</p>
									<span class="flex-grow-1"></span>
									<p>{{ getProgramType(vModels.program.programType) }}</p>
								</div>
								<div v-if="vModels.program.leasingPeriod" class="tffi-card-summary-card__bottom-area__label">
									<span class="flex-grow-1"></span>
									<p>{{ $t('financingProgram.leasingPeriod') }}: {{ vModels.program.leasingPeriod || 'N/A' }}</p>
								</div>
								<div v-if="vModels.program.rate" class="tffi-card-summary-card__bottom-area__label">
									<p>Rate:</p>
									<span class="flex-grow-1"></span>
									<p>{{ vModels.program.rate }}%</p>
								</div>
								<div v-if="vModels.program.term" class="tffi-card-summary-card__bottom-area__label">
									<p>Finance Term:</p>
									<span class="flex-grow-1"></span>
									<p>{{ vModels.program.term }} Months</p>
								</div>
								<div v-if="vModels.program.amortTerm" class="tffi-card-summary-card__bottom-area__label">
									<p>Amortization Term:</p>
									<span class="flex-grow-1"></span>
									<p>{{ vModels.program.amortTerm }} Months</p>
								</div>
								<div v-if="vModels.program.monthly" class="tffi-card-summary-card__bottom-area__label">
									<p class="theming primary-color fw-bold">Base Monthly Payment:</p>
									<span class="flex-grow-1"></span>
									<p class="theming primary-color fw-bold">${{ vModels.program.monthly | formatMoney }}</p>
								</div>
							</div>
						</div>

					</div>

					<div class="col-lg-8">
						<div style="cursor: pointer;"
							data-bs-backdrop="static" data-bs-keyboard="false" data-bs-toggle="modal" data-bs-target="#notesModalMainApp"
							v-if="applicant && applicant.firstName" class="tffi-card tffi-card-secondary-card theming primary-color person">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(app.application)" class="status-accepted">
										<img src="./../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(app.application)" class="status-denied">
										<img src="./../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">

								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Applicant</h2>
								</div>

								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ applicant['firstName'] + ' ' + applicant['lastName'] }}</p>
									<p>{{ applicant['email'] }}</p>
								</div>

							</div>
						</div>

						<!-- Modal -->
						<div class="modal fade show-" id="notesModalMainApp" tabindex="-1" aria-labelledby="notesModalMainAppLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered">
								<div class="modal-content">
									<div class="modal-header">
										<h3 class="modal-title theming primary-color">Change email</h3>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body" @click.stop>
										<p>
											You may edit the applicant's email address. If a contract has already been generated, then editing the email address will cause e-signature invitations in progress to be cancelled. You will need to return to the e-signature page to generate a new contract and initiate new e-signature invitations. You may cancel if you prefer not to edit the email address.
										</p>
										<form-field v-model="applicant.email" name="note" bootstrap type="text" @click.stop></form-field>
									</div>
									<div class="modal-footer">
										<button @click="saveEmail('main')" data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming primary-color w-100 fw-bold">Save email</button>
										<button data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming accent-color w-100 fw-bold">Cancel</button>
									</div>
								</div>
							</div>
						</div>

						<div style="cursor: pointer;"
							data-bs-backdrop="static" data-bs-keyboard="false" data-bs-toggle="modal" :data-bs-target="'#notesModal' + index"
							v-if="coApps" v-for="(coApp, index) in coApps" class="tffi-card tffi-card-secondary-card person">
							<div class="d-none- tffi-card-secondary-card__left-area">
								<div class="tffi-card-secondary-card__left-area__status-indicator">
									<div v-if="isAppComplete(coApps[index])" class="status-accepted">
										<img src="./../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div v-if="!isAppComplete(coApps[index])" class="status-denied">
										<img src="./../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
								</div>
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Co-Applicant</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>{{ coApp['firstName'] + ' ' + coApp['lastName'] }}</p>
									<p>{{ coApp['email'] }}</p>
								</div>
							</div>
							
						</div>
						
						<!-- Modal -->
						<div v-if="coApps" v-for="(coApp, index) in coApps" class="modal fade show-" :id="'notesModal' + index" tabindex="-1" :aria-labelledby="'notesModal' + index + 'Label'" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered">
								<div class="modal-content">
									<div class="modal-header">
										<h3 class="modal-title theming primary-color">Change email</h3>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
									</div>
									<div class="modal-body" @click.stop>
										<p>
											You may edit the applicant's email address. If a contract has already been generated, then editing the email address will cause e-signature invitations in progress to be cancelled. You will need to return to the e-signature page to generate a new contract and initiate new e-signature invitations. You may cancel if you prefer not to edit the email address.
										</p>
										<form-field v-model="coApp.email" name="note" bootstrap type="text" @click.stop></form-field>
									</div>
									<div class="modal-footer">
										<button @click="saveEmail(index)" data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming primary-color w-100 fw-bold">Save email</button>
										<button data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming accent-color w-100 fw-bold">Cancel</button>
									</div>
								</div>
							</div>
						</div>


					</div>

					<div class="col-lg-8">

						<div v-if="equipments" v-for="(equipment, index) in equipments" class="tffi-card tffi-card-secondary-card">
							<div class="tffi-card-secondary-card__left-area">
								<!-- <div class="tffi-card-secondary-card__left-area__status-indicator">
									<div class="status-accepted">
										<img src="./../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<div class="status-denied">
										<img src="./../../../assets/img/icons/stop.svg" alt="">
									</div>
								</div> -->
							</div>
							<div class="tffi-card-secondary-card__right-area">
								<div class="tffi-card-secondary-card__right-area__title-area">
									<h2>Equipment</h2>
								</div>
								<div class="tffi-card-secondary-card__right-area__content-area">
									<p>Type: {{ equipment['equipmentType'] }}</p>
									<p>Brand: {{ equipment['brand'] }}</p>
									<p>Model: {{ equipment['model'] }}</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="container-xl">
				<div class="row">
					<div class="col-24">
						<hr class="theming primary-color my-5">
					</div>
				</div>
			</div>

			<div class="container-xl">
				<div class="row">

					<div class="col-lg-8">

						<div @click="resetNote" data-bs-backdrop="static" data-bs-keyboard="false" data-bs-toggle="modal"
							data-bs-target="#notesModal" v-if="note" class="tffi-card tffi-card-summary-card sticky-note theming">
							<p class="strong">Notes</p>
							{{ note }}
						</div>

						<button v-if="!note" type="button" class="tffi-btn tffi-btn--solid theming accent-color w-100 fw-bold"
							data-bs-toggle="modal" data-bs-target="#notesModal">
							Add Note
						</button>

						<!-- Modal -->
						<div class="modal fade show-" id="notesModal" tabindex="-1" aria-labelledby="notesModalLabel"
							aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered">
								<div class="modal-content">
									<!-- <div class="modal-header">
								<h3 class="modal-title theming primary-color">Modal title</h3>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div> -->
									<div class="modal-body">
										<form-field v-model="vModels.note" name="note" bootstrap multiline type="textarea"
											rows="5"></form-field>
									</div>
									<div class="modal-footer">
										<button @click="saveNote" data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming primary-color w-100 fw-bold">Save Note</button>
										<button data-bs-dismiss="modal" type="button"
											class="tffi-btn tffi-btn--solid theming accent-color w-100 fw-bold">Cancel</button>
									</div>
								</div>
							</div>
						</div>

					</div>


					<div class="col-8">

						<div @click="goToPage({name: 'Sign', params: $route.params})" class="tffi-card tffi-card-status-list-card cursor-pointer">
							<div class="tffi-card-status-list-card__left-area">
								<div class="tffi-card-status-list-card__left-area__status-indicator">
									<div class="d-none status-accepted">
										<img src="../../../assets/img/icons/check-mark.svg" alt="">
									</div>
									<!-- <div class="status-denied">
									<img src="../../../assets/img/icons/stop.svg" alt="">
								</div> -->
								</div>
							</div>
							<div class="tffi-card-status-list-card__right-area">
								<div class="tffi-card-status-list-card__right-area__title-area">
									<h2>E-Signatures</h2>
								</div>
								<div class="tffi-card-status-list-card__right-area__content-area">
									<ul class="tffi-card-status-list-card__list list-unstyled">

										<li v-if="app && app.application && !app.application.signNowSent" class="tffi-card-status-list-card__list__item">
											Click here to generate contract for signature
										</li>
										
										<li v-if="app && app.application && app.application.signNowSent && app.application.signNowSentToDealerOnly" class="tffi-card-status-list-card__list__item">
											You have chosen to upload a scanned signed contract
										</li>

										<li v-if="app && app.application && app.application.signNowSent && !app.application.signNowSentToDealerOnly && agent" class="tffi-card-status-list-card__list__item">
											<span v-if="app && app.application && agent" class="tffi-card-status-list-card__list__item__name">
												{{ agent['fullName'] }}
											</span>
											<span class="flex-grow-1"></span>
											<span class="tffi-card-status-list-card__list__item__status">
												<img v-if="signedEmails.includes(agent.email.toLowerCase())" class="" src="../../../assets/img/icons/check-mark.svg" alt="">
												<img v-else src="../../../assets/img/icons/exclamation-mark.svg" alt="">
											</span>
										</li>

										<li v-if="app && app.application && app.application.signNowSent && !app.application.signNowSentToDealerOnly" class="tffi-card-status-list-card__list__item">
											<span v-if="app && app.application && applicant" class="tffi-card-status-list-card__list__item__name">
												{{ applicant['firstName'] + ' ' + applicant['lastName'] }}
											</span>
											<span class="flex-grow-1"></span>
											<span class="tffi-card-status-list-card__list__item__status">
												<img v-if="signedEmails.includes(applicant.email)" class="" src="../../../assets/img/icons/check-mark.svg" alt="">
												<img v-else src="../../../assets/img/icons/exclamation-mark.svg" alt="">
											</span>
										</li>

										<li v-if="app && app.application && app.application.signNowSent && !app.application.signNowSentToDealerOnly && coApps" v-for="(coApp, index) in this.coApps" class="tffi-card-status-list-card__list__item">
											<span class="tffi-card-status-list-card__list__item__name">
												{{ coApp['firstName'] + ' ' + coApp['lastName'] }}
											</span>
											<span class="flex-grow-1"></span>
											<span class="tffi-card-status-list-card__list__item__status">
												<img v-if="signedEmails.includes(coApp.email)" class="" src="../../../assets/img/icons/check-mark.svg" alt="">
												<img v-else src="../../../assets/img/icons/exclamation-mark.svg" alt="">
											</span>
										</li>
										
									</ul>
								</div>
							</div>
						</div>

					</div>

					<div class="col-8">
					
						<div @click="goToPage({ name: 'UploadFiles', params: $route.params })" class="tffi-card tffi-card-status-list-card upload-files-card cursor-pointer">
							<div class="tffi-card-status-list-card__left-area">
								<div class="tffi-card-status-list-card__left-area__status-indicator">
									<div class="status-accepted">
										<img v-if="isAllUploaded" src="../../../assets/img/icons/check-mark.svg" alt="">
										<img v-if="!isAllUploaded" src="../../../assets/img/icons/exclamation-mark.svg" alt="">
									</div>
									<!-- <div class="status-denied">
									<img src="../../../assets/img/icons/stop.svg" alt="">
								</div> -->
								</div>
							</div>
							<div class="tffi-card-status-list-card__right-area">
								<div class="tffi-card-status-list-card__right-area__title-area">
									<h2>Upload Files</h2>
								</div>
								<div class="tffi-card-status-list-card__right-area__content-area">
									<ul class="tffi-card-status-list-card__list list-unstyled">

										<li v-if="uploadTypes" v-for="(r, index) in this.uploadTypes" class="tffi-card-status-list-card__list__item">
											<span class="tffi-card-status-list-card__list__item__name">
												{{ uploadTypes[index].description }}

											</span>
											<span class="flex-grow-1"></span>
											<span class="tffi-card-status-list-card__list__item__status">
												<img v-if="uploadTypes[index].uploaded" src="../../../assets/img/icons/check-mark.svg" alt="">
												<img v-if="uploadTypes[index].required && !uploadTypes[index].uploaded" src="../../../assets/img/icons/exclamation-mark.svg" alt="">
											</span>
										</li>
										
									</ul>
								</div>
							</div>
						</div>

					</div>

					<div class="col-8">

					</div>
				</div>
			</div>
 
			<div class="tffi-navigation-bottom">
				<div class="container-xl">
					<div class="row">
						<div class="col order-2 order-lg-1 col-24 col-lg-20 d-flex justify-content-end">
							<button type="button" class="tffi-btn tffi-btn--solid theming secondary-color" color="primary"
								:loading="goingBack" @click="goToPage({ name: 'Dashboard' })">
								Save For Later
							</button>
						</div>
						<div class="col order-1 order-lg-2 col-24 col-lg-4 d-flex justify-content-end">
							<button :title="checkAppDetails(app.application).errorMessage ?? ''"
								:disabled="!isEverythingComplete" type="button"
								class="tffi-btn tffi-btn--solid theming primary-color me-0"
								@click="sendToEdge">
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>

		</form>
	</div>
</template>

<script>
import ApplicationMixin from './ApplicationForms/Application/ApplicationMixin';
import FormsConfirmationMixin from './ApplicationForms/FormsConfirmationMixin';
import { uploadToEdge, getApplication, getSignNowStatusApi } from '../../api';
import { isArray } from '@incodeapps/js-utilities';



export default {

	mixins: [
		ApplicationMixin,
		FormsConfirmationMixin
	],

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, }).format(value);
		},
	},

	data() {

		return {
			isComplete: false,
			isEverythingComplete: false,
			isAllUploaded: true,
			agent: null,
			applicant: {},
			equipments: [],
			coApps: [],
			programType: '',
			app: [],
			note: '',
			requestUploadTypes: {},
			signedEmails: [],
			uploadTypes: {
				0: {
					name: 'signedContract',
					fileName: null,
					description: "Signed Contract",
					required: false,
					uploaded: false,
					size: 0,
				},
				1: {
					name: 'billOfSaleInvoice',
					fileName: null,
					description: 'Bill of Sale / Invoice',
					required: true,
					uploaded: false,
					size: 0,
				},
				2: {
					name: 'completionCertificate',
					fileName: null,
					description: 'Completion Certificate',
					required: true,
					uploaded: false,
					size: 0,
				},
				3: {
					name: 'papFormVoidCheque',
					fileName: null,
					description: 'PAP Form / Void Cheque',
					required: true,
					uploaded: false,
					size: 0,
				},
				4: {
					name: 'governmentId',
					fileName: null,
					description: 'Government ID',
					required: true,
					uploaded: false,
					size: 0,
				},
				5: {
					name: 'incomeVerification',
					fileName: null,
					description: 'Income Verification',
					required: false,
					uploaded: false,
					size: 0,
				}
			},
			vModels: {
				applicants: [],
				note: '',
				program: {
					financedAmount: 0
				}
			}
		};

	},

	filters: {
		formatMoney(value) {
			return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, }).format(value);
		},
	},

	computed: {

	},

	watch: {

	},

	async mounted() {

		this.saveLoading(true);

		let id = this.$route.params.applicationId

		let app = await getApplication(id)

		try {
			if (
				app.application.signNowSent
				// &&	
			) {
				this.signedEmails = await getSignNowStatusApi({id})
			}
		} catch (error) {
			console.log('error', error);
		}

		this.app = app

		console.log('app', app);

		this.applicant = {
			firstName: app.application.firstName,
			lastName: app.application.lastName,
			email: app.application.email
		}

		if (app.application.hasOwnProperty('agent')) {
			this.agent = app.application.agent
		}

		if (this.app.application.uploadTypes) {
			console.log('application has uploadTypes');
			this.uploadTypes = null
			this.uploadTypes = this.app.application.uploadTypes
		} else {
			console.log('no uploadTypes on application');
		}

		if (
			this.uploadTypes[0]
			&& this.uploadTypes[0].name == 'signedContract'
			&& (!this.app.application.signNowSentToDealerOnly || this.app.application.signNowSentToDealerOnly == undefined)
		) {

			console.log('not dealer only');
			delete this.uploadTypes[0]
			let temp = this.uploadTypes
			this.uploadTypes = null
			this.uploadTypes = temp
			// this.selectedType = 1

		} else {
			console.log('dealer only');
		}

		console.log('this.uploadTypes[0]', this.uploadTypes[0]);

		if (
			this.uploadTypes[0] == undefined
			// && this.uploadTypes[0].name != 'signedContract'
			&& this.app.application.signNowSentToDealerOnly != undefined
			&& this.app.application.signNowSentToDealerOnly
		) {
			this.uploadTypes[0] = {
				name: 'signedContract',
				fileName: null,
				description: "Signed Contract",
				required: true,
				uploaded: false,
				size: 0,
			};
			
		}

		console.log('this.applicant', this.applicant);

		if (app.application.equipments) {
			this.equipments = app.application.equipments
		}
		
		if (app.application.coApps) {
			this.coApps = app.application.coApps
		}
		
		if (app.application.requestUploadTypes) {
			this.requestUploadTypes = app.application.requestUploadTypes
			this.uploadTypes = app.application.uploadTypes

			console.log('app.application.requestUploadTypes', app.application.requestUploadTypes);
			console.log('app.application.uploadTypes', app.application.uploadTypes);
		}

		if (app.application.hasOwnProperty('note')) {
			this.vModels.note = app.application.note
			this.note = app.application.note
		}

		this.vModels.program = app.application.program ?? {};

		if (
			this.vModels.program
			&& this.vModels.program.program
		) {
			this.programType = (this.vModels.program.program.Code == 2) ? 'Lease Payment' : 'Loan Payment'
		} else if (app.application.isImportedFromEdge) {
			this.programType = 'Imported from EDGE, no program details found'
		}

		this.isUploadsDone()

		this.saveLoading(false);

		console.log('await this.checkAppDetails(app.application)', await this.checkAppDetails(app.application));
		console.log('this.isUploadsDone()', await this.isUploadsDone());
		this.isEverythingComplete = await this.checkAppDetails(app.application) && await this.isUploadsDone()
		console.log('this.isEverythingComplete', this.isEverythingComplete);

	},

	methods: {

		async sendToEdge() {

			this.saveLoading(true);
			
			let result = await uploadToEdge({
				id: this.app.application.id
			}).catch(error => {

				console.log('error', error);

				if (error.response.data && error.response.data.errorMessage) {

					this.showErrorAlert(error.response.data.errorMessage);

				}

			});

			console.log('result', result);

			if (
				result == 'Success'
				// || result.
			) {
				
				this.showSuccessAlert('File has been uploaded successfully')

				return this.$router.push({
					name: `Dashboard`,
				});

			} else {
				this.showErrorAlert('Something went wrong. ' + result.toString());
			}

			this.saveLoading(false);

		},
		
		async saveNote() {

			this.saveLoading(true);

			// console.log('this.app.application', this.app.application);

			let result = await this.saveApplicationAction({
				data: {
					id: this.app.application.id,
					note: this.vModels.note
				}
			});

			// console.log('result', result);
			this.app.application.note = this.vModels.note
			this.note = this.vModels.note

			this.saveLoading(false);

		},

		async saveEmail(index) {

			console.log('saveEmail', index);

			this.saveLoading(true);

			// console.log('this.app.application', this.app.application);

			if (index == 'main') {

				this.app.application.email = this.applicant.email

				let result = await this.saveApplicationAction({
					data: {
						id: this.app.application.id,
						email: this.applicant.email
					}
				});

			}

			if (index != 'main') {

				if (
					this.app.application.coApps
					&& isArray(this.app.application.coApps)
				) {

					this.app.application.coApps[index].email = this.coApps[index].email
			
					let result = await this.saveApplicationAction({
						data: this.app.application
					});

				}

			}

			// console.log('result', result);
			// this.email = this.applicant.email

			this.saveLoading(false);

		},

		async resetNote() {

			this.vModels.note = this.app.application.note;
			this.note = this.app.application.note;

		},

		async isUploadsDone() {

			console.log('isUploadsDone()');
			console.log('this.uploadTypes', this.uploadTypes);

			let isAllUploaded = true;

			Object.keys(this.uploadTypes).forEach((index, element) => {

				// console.log(this.requestUploadTypes[index].name, ': \nrequested: ', this.requestUploadTypes[index].isRequested, ', \nuploaded: ', this.uploadTypes[index].uploaded);
				console.log(this.uploadTypes[index].name, ': \nrequested: ', this.uploadTypes[index].isRequested, ', \nuploaded: ', this.uploadTypes[index].uploaded);

				if (
					// this.uploadTypes[index].isRequested
					(!this.uploadTypes[index].uploaded || this.uploadTypes[index].uploaded === undefined)
					&& this.uploadTypes[index].required
				) {
					console.log('isAllUploaded false for index: ', index, 'this.uploadTypes[index]: ', this.uploadTypes[index]);
					isAllUploaded = false;
				}

			});

			this.isAllUploaded = isAllUploaded;

			console.log('isAllUploaded', isAllUploaded);
			return isAllUploaded;
		}
 
	}

};

</script>

<style lang="scss" scoped>
.edit-btn {
	position: relative;
	top: -1rem;
	right: -1rem
}

.card-body {

	.form-group.static-form-group {

		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
		}

	}

	.invalid-feedback {
		margin-top: -1rem;
		margin-bottom: 1rem;
	}

}

.form-control-plaintext {
	white-space: pre-line;
}

.tffi-card-secondary-card.person,
.tffi-card-secondary-card.person {
	&:hover {
		border: 2px solid #C18F14;
	}
}

.sticky-note {

	padding: 40px;
	padding-top: 20px;
	overflow-wrap: anywhere;
	cursor: pointer;
	background-color: #DFCE86;

	&:hover {
		border: 2px solid #C18F14;
	}

}

.cursor-pointer {
	cursor: pointer;
	&:hover {
		border: 2px solid #C18F14;
	}
}

</style>
