import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

let k = 'g4gh352%$^54655sv%^46255rctcfgW@%^4y65gaae#$3458tgrr68f$%t4ar';

const JsonFormatter = {
	
	stringify: function (cipherParams) {
		
		// create json object with ciphertext
		let jsonObj = {
			ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
		};
		
		// optionally add iv or salt
		if (cipherParams.iv) {
			
			jsonObj.iv = cipherParams.iv.toString();
			
		}
		
		if (cipherParams.salt) {
			
			jsonObj.s = cipherParams.salt.toString();
			
		}
		
		// stringify json object
		return JSON.stringify(jsonObj);
		
	},
	
	parse: function (jsonStr) {
		
		// parse json string
		var jsonObj = JSON.parse(jsonStr);
		
		// extract ciphertext from json object, and create cipher params object
		let cipherParams = CryptoJS.lib.CipherParams.create({
			ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
		});
		
		// optionally extract iv or salt
		if (jsonObj.iv) {
			
			cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
			
		}
		
		if (jsonObj.s) {
    	
    	cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    	
		}
  
		return cipherParams;
  
	}
	
};

function encrypt(val) {
	
	return CryptoJS.AES.encrypt(val, k, {
		format: JsonFormatter
	});
	
}

function decrypt(val) {
	
	let decrypted  = CryptoJS.AES.decrypt(val, k, {
		format: JsonFormatter
	});
	
	return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
	
}

export const SafeCookies = {
	
	get: key => {
		
		let value = VueCookies.get(key);
		
		if (!value) {
			
			return value;
			
		}
		
		let decrypted = decrypt(JSON.stringify(value));
		
		return decrypted;
		
	},
	
	set: (key, value, time) => {
		
		let encrypted = encrypt(JSON.stringify(value));
		encrypted = JSON.parse(encrypted);
		
		time = time || '3h';
		
		return VueCookies.set(key, encrypted, time, null, window.location.hostname, (!process.env.VUE_APP_DEBUG));
		
	},
	
	remove: key => {
		
		return VueCookies.remove(key, null, window.location.hostname);
		
	},
	
	isKey: key => {
		
		return VueCookies.isKey(key);
		
	},
	
	keys: () => {
		
		return VueCookies.keys();
	
	}
	
};

export default SafeCookies;