import * as actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
	authUser: null,
	authUserVendor: null,
	// logo: null,
	authUserToken: null,
	authUserTokenLastCheck: null
};

export default {
	state,
	actions,
	mutations,
	getters
};
