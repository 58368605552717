<template>
	<transition :enter-active-class="enterActiveClass" :leave-active-class="leaveActiveClass" @before-enter="beforeEnter" @after-enter="afterEnter" @before-leave="beforeLeave">
		
		<div class="md-toast" :class="['md-toast-'+type]" :style="{backgroundColor:toastBackgroundColor}" v-if="show">
			
			<mdb-container>
			
				<button class="md-toast-close-button" role="button" @click="hideToastr" v-if="closeButton">
					<icon name="close" type="ma"></icon>
				</button>
				
				<div class="md-toast-progress" v-if="progressBar" :style="'width: ' + progress.percent + '%'"></div>
				<!-- <div class="toast-icon">
					<img :src="iconSrc"/>
				</div> -->
				<div class="md-toast-title" v-if="title">{{ title }}</div>
				<div class="md-toast-message">{{ message }}</div>
				
			</mdb-container>
			
		</div>
	</transition>
</template>


<script>
	
	export default {
		
		name: 'Notification',
		
		props: {
			type: {
				type: String,
				default: 'success'
			},
			position: {
				type: String,
				default: 'top center'
			},
			title: {
				type: String,
				default: null
			},
			message: {
				type: String,
				required: true
			},
			closeButton: {
				type: Boolean,
				default: true
			},
			progressBar: {
				type: Boolean,
				default: false
			},
			icon: {
				type: String,
				default: null
			},
			timeOut: {
				type: [String, Number],
				default: '10000'
			},
			showMethod: {
				type: String,
				default: 'fadeIn'
			},
			hideMethod: {
				type: String,
				default: 'fadeOut'
			},
			showDuration: {
				type: [String, Number],
				default: '1000'
			},
			hideDuration: {
				type: [String, Number],
				default: '1000'
			},
			delay: {
				type: [String, Number],
				default: '0'
			},
			successColor: {
				type: String,
				default: null
			},
			infoColor: {
				type: String,
				default: null
			},
			warningColor: {
				type: String,
				default: null
			},
			errorColor: {
				type: String,
				default: null
			},
			color: {
				type: String,
				default: null
			}
		},
		
		data: () => {
			
			return {
				progress: {
					hideEta: 0,
					percent: 0,
					intervalId: null
				},
				show: false,
				defaultIcons: {
					// success: require('./assets/success.png'),
					// info: require('./assets/info.png'),
					// warning: require('./assets/warning.png'),
					// error: require('./assets/error.png')
				}
			};
			
		},
		
		computed: {
			
			positionClass() {
				
				return this.position.split(' ').join('-');
				
			},
			
			enterActiveClass() {
				
				return 'animated ' + this.showMethod;
				
			},
			
			leaveActiveClass() {
				
				return 'animated ' + this.hideMethod;
				
			},
			
			toastBackgroundColor() {
				
				if (this.color) {
					
					return this.color;
					
				}
				
				if (this.type === 'success' && this.successColor) {
					
					return this.successColor;
					
				} else if (this.type === 'info' && this.infoColor) {
					
					return this.infoColor;
					
				} else if (this.type === 'warning' && this.warningColor) {
					
					return this.warningColor;
					
				} else if (this.type === 'error' && this.errorColor) {
					
					return this.errorColor;
					
				}
				
				return null;
				
			},
			
			iconSrc() {
				
				if (this.icon) return this.icon;
				return this.defaultIcons[this.type];
				
			}
			
		},
		
		mounted() {
			
			let toastContainer = document.querySelector(`.toastr-container.md-toast-${this.positionClass}`);
			
			if (!toastContainer) {
				
				toastContainer = document.createElement('div');
				toastContainer.classList.add('toastr-container');
				toastContainer.id = 'toast-container';
				toastContainer.classList.add(`md-toast-${this.positionClass}`);
				document.body.appendChild(toastContainer);
				
			}
			
			toastContainer.appendChild(this.$el);
			setTimeout(() => this.showToastr(), this.delay);
			
		},
		
		methods: {
			
			showToastr() {
				
				this.show = true;
				
				this.sto = setTimeout(() => this.hideToastr(), this.timeOut);
				
				if (this.progressBar) {
					
					this.progress.hideEta = new Date().getTime() + parseFloat(this.timeOut);
					this.progress.intervalId = setInterval(() => this.refreshProgress(), 10);
					
				}
				
			},
			
			async hideToastr() {
				
				clearTimeout(this.sto);
				clearTimeout(this.progress.intervalId);
				
				this.show = false;
				
				function timeout(ms) {
					
					return new Promise(resolve => setTimeout(resolve, ms));
					
				}
				
				return timeout(this.hideDuration);
				
			},
			
			refreshProgress() {
				
				this.progress.percent = ((this.progress.hideEta - (new Date().getTime())) / this.timeOut) * 100;
				
			},
			
			beforeEnter(el) {
				
				el.style.animationDuration = this.showDuration + 'ms';
				
			},
			
			afterEnter(el) {
				
				this.$el.classList.add('animated');
				this.$el.classList.add(this.showMethod);
				
			},
			
			beforeLeave(el) {
				
				el.style.animationDuration = this.hideDuration + 'ms';
				
			}
			
		}
		
	};
	
</script>

<style lang="scss">
	
	#toast-container {
		
		.md-toast-close-button {
			top: -3px;
			line-height: 2em;
		}
		
		&.md-toast-top-center > div,
		&.md-toast-bottom-center > div {
			width: 100% !important;
			padding: 0;
			background-image: none !important;
			
			.container {
				position: relative;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 50px;
				background-repeat: no-repeat;
				background-position: 15px center;
			}
			
			&.md-toast-success .container {
				background-color: $success-color;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
			}
			
			&.md-toast-error .container {
				background-color: $danger-color;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
			}
			
			&.md-toast-info .container {
				background-color: $info-color;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
			}
			
			&.md-toast-warning .container {
				background-color: $warning-color;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
			}
			
		}
	}
	
</style>