<template>
	<div class="payment-calculator-template">
		<div class="container-xl">
			<div class="row">
				<div class="col-24">
					<div class="template__page-title">
						<h1>Lease Calculator</h1>
					</div>
				</div>
			</div>
		</div>

		<form @submit.prevent="onSubmit" novalidate>
			<div class="container-xl template--vh">
				<div class="row">
					<div class="col-lg-15 order-2 order-lg-1" v-if="!showConfirmation">
						<div class="tffi-card tffi-card-main-card payment-calculator-main-card">
							<div class="payment-calculator-main-card__financed-amount d-flex align-items-center">
								<label for="monthly" class="theming primary-color fw-bold">Monthly Payment</label>
								<input
									v-model="vModels.monthly"
									name="monthly"
									type="number"
									class="my-0 lease-calculator-input"
									bootstrap
								>
							</div>

						</div>
					</div>

					<!--Side Card-->
					<div :class="(!showConfirmation === true) ? 'col-lg-9 col-t1 order-1 order-lg-2' : 'installment-loan-estimate-template col-sm-24 col-md-14 col-lg-10 mx-auto col-t2'">
						<div class="tffi-card tffi-card-summary-card">
							<div class="tffi-card-summary-card__top-area theming primary-color">
								<!-- <div class="tffi-card-summary-card__top-area__overlay-gradient"></div> -->
								<div class="tffi-card-summary-card__top-area__overlay-image"></div>
								<div class="tffi-card-summary-card__top-area__title-area">
									<h2>Lease Program</h2>
								</div>
							</div>
							<div class="tffi-card-summary-card__bottom-area">
								<div class="tffi-card-summary-card__bottom-area__label">
									<p>Monthly Payment (before tax):</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>${{ vModels.monthly | formatMoney }}</p>
								</div>
								<div class="tffi-card-summary-card__bottom-area__label">
									<p>Tax Rate:</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>{{ vModels.taxRate * 100 }}%</p>
								</div>
								<div class="tffi-card-summary-card__bottom-area__label">
									<p>Monthly Payment (including tax): </p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p>${{ vModels.monthlyWithTax | formatMoney }}</p>
								</div>
								<div class="tffi-card-summary-card__bottom-area__label">
									<p>Lease Term:</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p class="theming primary-color fw-bold">{{ vModels.leaseTermMonths }} month</p>
								</div>
								<div class="tffi-card-summary-card__bottom-area__label">
									<p class="theming primary-color fw-bold">Total Payments (including tax):</p>
									<span class="flex-grow-1 d-none d-lg-flex"></span>
									<p class="theming primary-color fw-bold">${{ vModels.totalPaymentsWithTax | formatMoney  }}</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="tffi-navigation-bottom">
				<div class="container-xl">
					<div class="row">
						<div class="col d-flex justify-content-start">
							<button
								type="button"
								class="tffi-btn tffi-btn--solid theming secondary-color"
								@click="$router.push({name: 'Dashboard'})"
								>
								Cancel
							</button>
						</div>
						<div v-if="!showConfirmation" class="col d-flex justify-content-end">
							<button
								class="tffi-btn tffi-btn--solid theming secondary-color"
								type="submit"
								:disabled="!this.vModels.program"
								@click="showConfirmationMethod()"
								>
								{{ $t('forms.next') }}
							</button>
						</div>
						<div v-if="showConfirmation" class="col d-flex justify-content-end">
							<button
								class="tffi-btn tffi-btn--solid theming secondary-color"
								type="submit"
								@click="saveAndGo()"
								>
								{{ $t('forms.next') }}
							</button>
						</div>
					</div>

					<router-link v-if="showConfirmation && false" tag="div" :to="{ name: 'ApplicationStep1', params: { applicationId: 'new' } }">
						<button class="tffi-btn tffi-btn--solid theming primary-color">Next</button>
					</router-link>
				</div>
			</div>

			</form>
	</div>

</template>

<script>

	import ApplicationMixin from './ApplicationForms/Application/ApplicationMixin';
	import { getPrograms, getProgramOptions } from '../../api';
	import { mapState } from 'vuex';


	export default {

		mixins: [
			ApplicationMixin
		],

		filters: {
			formatMoney(value) {
				return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(value);
			},
		},

		data() {

			return {
				submitting: false,
				isLoading: true,
				showConfirmation: false,

				vModels: {
					taxRate: 0.13,
					program: null,
					monthly: 1000,
					programType: 'equalPayment',
					term: null,
					rate: null,
					leasingPeriod: null,
					paymentTerm: "default",
					amortTerm: null,
					escalationRate: 1.035
				}

			};

		},

		computed: {

			...mapState({
				authUser: state => state.auth.authUser
			}),

		},

		watch: {
				
			'vModels.monthly': {

				async handler(val) {

					console.log('this.vModels', this.vModels);

					// this.vModels.escalationRate = 1.035;
					// this.vModels.taxRate = 0.13;
					this.vModels.leaseTermMonths = 120; // always 120
					this.vModels.leaseTermYears = this.vModels.leaseTermMonths / 12;
					this.vModels.monthlyWithTax = this.vModels.monthly * (1 + this.vModels.taxRate);
					this.vModels.totalPayments = 12 * this.vModels.monthly * ((Math.pow(this.vModels.escalationRate, this.vModels.leaseTermYears) - 1) / (this.vModels.escalationRate - 1));
					this.vModels.totalPaymentsWithTax = this.vModels.totalPayments * (1 + this.vModels.taxRate);

					if (this.vModels.totalPaymentsWithTax) {
						this.vModels.totalPaymentsWithTax = this.vModels.totalPaymentsWithTax.toFixed(2);
					}

				}

			}

		},

		async mounted() {

			console.log('this.optionSets.taxes', this.optionSets.taxes);
			console.log('this.authUser', this.authUser);

			let taxes = this.optionSets.taxes;
			let taxRate = 0;

			let province = this.authUser.province || 'ON';

			taxes[province]['taxes'].forEach(item => {
				taxRate += item.tax
			});

			if (taxRate) {
				this.vModels.taxRate = taxRate;
			}
			
			console.log('taxRate', taxRate);

			this.saveLoading(true);

			if (this.$route.params.applicationId == 'new') {
				console.log('------------------------------- reset');
				this.resetApp()
			}

			await this.getPrograms();

			this.vModels.monthly = 100

			this.saveLoading(false);
		},

		methods: {

			async saveAndGo() {

				this.saveLoading(true);

				let id = null;

				if (
					this.$route.params.applicationId
					&& this.$route.params.applicationId != 'new'
				) {
					id = this.$route.params.applicationId
				}

				let result = await this.saveApplicationAction({
					data: {
						program: this.vModels,
						id
					},
					// errors: this.errors
				});

				console.log('result', result);

				this.saveLoading(false);

				return this.$router.push({
					name: `ApplicationStep1`,
					params: {
						applicationId: result
					}
				});


			},

			async showConfirmationMethod() {

				this.showConfirmation = true;

			},

			async getPrograms() {

				this.isLoading = true;
				this.submitting = true;
				this.saveLoading(true);


				let result = await getPrograms().catch(async error => {


					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				console.log('result obj keys', Object.keys(result.programs).length);
				if (Object.keys(result.programs).length == 0) {
					this.errorAlert = await this.showErrorAlert('No programs were found.');
					this.saveLoading(false);
					return;
				}

				console.log('result.programs', result.programs);
				
				Object.keys(result.programs).forEach(key => {
					
					let program = result.programs[key];

					console.log('program', program);
					console.log('program.Code', program.Code);

					if (program.Code == 2) {
						this.vModels.program = program
						if (program.usr_EscalationRate) {
							this.vModels.escalationRate = 1 + (program.usr_EscalationRate / 100)
						}

						console.log('program.usr_EscalationRate', program.usr_EscalationRate);
					}

				});

				let payload = {
					programId: this.vModels.program.ProgramID,
					amount: this.vModels.monthly
				};

				// await this.getProgramOptions(payload);

				this.saveLoading(false);

			},

			async getProgramOptions(programId) {

				this.submitting = true;

				let result = await getProgramOptions(programId).catch(async error => {

					console.log('getProgramOptions programId', programId);

					this.saveLoading(false);

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				console.log('result', result);


				// this.vModels.monthly = Number(result.monthly).toFixed(2);
				
				this.vModels.rate = result.rates.name;

				// result.terms['default'] = {
				// 	en: "Choose Payment Term"
				// };

				console.log('result.terms', result.amortTerms);

				this.paymentTerm = null;

				this.removeAlerts();

				await setInterval(() => {
				// workaround to this.programs not being properly replaced
				}, 500);

				this.amortTerms = result.amortTerms;

			},

			onSubmit(e) {

				this.isLoading = true;
				this.submitting = true;

				// console.debug(
				// 	'[onSubmit] event:', e,
				// 	'\nvModels:', JSON.stringify(this.vModels, null, 2)
				// );

			}

		}

	};

</script>

<style lang="scss" scoped>

	.btn-group {
		display: flex;
		flex-wrap: wrap;

		.btn {
			border-radius: $btn-border-radius !important;
			width: 30%;
			max-width: 31.5%;
			margin: 1rem 1%!important;

			&.lease-date {
				// margin: 1rem 1%;
				width: 10%;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0.2rem 0%;
					padding: 10px!important;
				}
			}

			@media (max-width: 767px) {
				width: 100%;
				margin: 0.5rem 0%;

				&.program-btn {
					padding: 10px!important;
				}

				span.rate {
					font-size: 2rem!important;
				}

			}



			span {
				display: block;
			}

			&.btn-primary {
				&.active, &:active {
					background-color: $info !important;
				}
			}

		}

	}

</style>
