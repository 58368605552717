import { DefaultLayout } from '../layout';
import { default as AuthRoutes, AfterAuthRoutes } from '../auth/routes';
import ApplicationRoutes from '../application/routes';
import PagesRoutes from '../pages/routes';

export default [

	...AuthRoutes,

	{
		path: '/',
		component: DefaultLayout,
		children: [
			...ApplicationRoutes,
			...AfterAuthRoutes,
			...PagesRoutes // must be the last in array
		]
	}

];
