<template>

	<div class="default-layout" :id="pageId" ref="layoutWrapper">

		<header>
			<nav-top></nav-top>
		</header>

		<div class="d-flex flex-lg-row flex-column">
			<aside class="side-bar">
				<div class="side-bar__bg">
					<div class="side-bar__content">
						<button @click="$router.push({ name: 'Dashboard' })" class="side-bar__content__item theming primary-color">
							<svg class="theming primary-color" xmlns="http://www.w3.org/2000/svg" width="22.004" height="17.073" viewBox="0 0 22.004 17.073">
								<g id="Group_917" data-name="Group 917" transform="translate(-108.926 -134.795)">
									<path id="Path_965" data-name="Path 965" d="M122.516,149.868a.9.9,0,0,0,0-1.8l-2.27-1.646-.381.4,1.747,2.176,0,.03A.9.9,0,0,0,122.516,149.868Z" transform="translate(-2.209 -2.344)" fill="#c18f14"/>
									<path id="Path_966" data-name="Path 966" d="M119.926,134.795a11,11,0,0,0-9.253,16.946l.083.128h1.368l1.854-1.62-.8-.92-1.809,1.58-.059-.1a9.942,9.942,0,0,1-1.361-5.015c0-.051,0-.1.008-.163l.007-.184,2.2,0v-1.22H110.08l.02-.113a9.919,9.919,0,0,1,2.1-4.626l.066-.083,1.553,1.551.865-.859-1.574-1.573.074-.068a9.875,9.875,0,0,1,5.786-2.59l.1-.01,0,2.544h1.217v-2.564l.1,0a9.967,9.967,0,0,1,6.415,2.739l.071.068-1.609,1.61.863.861,1.567-1.567.067.085a9.963,9.963,0,0,1,1.985,4.48l.02.112-2.166,0v1.22h2.284l.01.2c0,.05.006.1.006.151a9.934,9.934,0,0,1-1.351,4.993l-.061.108-1.705-1.7-.861.863,1.813,1.813H129.1l.083-.127a11,11,0,0,0-9.257-16.946Z" transform="translate(0)" fill="#c18f14"/>
								</g>
							</svg>
						</button>
					</div>
				</div>
			</aside>

			<main class="w-100">
				<transition name="fade">
					<router-view :key="$route.fullPath"></router-view>
				</transition>
			</main>
		</div>

		<footer ref="navBottom">
			<nav-bottom></nav-bottom>
		</footer>

	</div>

</template>

<script>

	import { mapActions, mapState } from 'vuex';
	import { paramCase } from 'change-case';
	import NavTop from './navTop';
	import NavBottom from './navBottom';
	let eventListener, resizeTimeout;

	export default {

		components: {
			NavTop,
			NavBottom
		},

		data() {

			return {
				resizeTimeout
			};

		},

		computed: {

			...mapState({
				locale: state => state.app.locale
			}),

			pageId() {

				return paramCase(this.$route.name);

			}

		},

		mounted() {

			this.resizeFooter();

			window.addEventListener('resize', this.windowResize());

		},

		beforeDestroy() {

			clearTimeout(this.resizeTimer);

			window.removeEventListener('resize', this.windowResize());

		},

		methods: {

			...mapActions([
				'saveLocale'
			]),

			changeLocale(locale) {

				this.saveLocale(locale);

			},

			windowResize() {

				clearTimeout(this.resizeTimer);

				let _self = this;

				this.resizeTimer = setTimeout(function () {

					// Run code here, resizing has "stopped"
					_self.resizeFooter();

				}, 250);

			},

			resizeFooter() {

				if (this.$refs.navBottom) {

					let footer = this.$refs.navBottom.clientHeight;

					this.$refs.layoutWrapper.style.paddingBottom = (footer + 20) + 'px';

				}

			}

		}

	};

</script>
