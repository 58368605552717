<template>

	<div class="box-titles-wrap" :class="displayStyle + '-titles'">
		<div class="box-titles d-flex p-1">

			<div
				v-if="!col.hidden"
				v-for="(col, colIndex) in columnsDisplay"
				:key="itemKey(row, colIndex) + '-title'"
				class="box-cell flex-fit fw-bold"
				:class="colClass('title', col.field)"
			>
				<a
					v-if="sorting"
					href="javascript:;"
					@click="triggerSort(col)"
				>{{ col.label }}<icon v-show="isCurrentSort(col)" type="fa" :name="sortIcon" class="ml-1" />
				</a>
				<template v-else>
					{{ col.label }}
				</template>
			</div>

		</div>
	</div>

</template>

<script>

	import { paramCase } from 'change-case';

	export default {

		name: 'DatatableHeader',

		props: {

			columns: {
				type: Array,
				required: true
			},

			row: {
				type: Number,
				default: null
			},

			displayStyle: {
				type: String,
				default: 'grid'
			},

			sorting: {
				type: Boolean,
				default: true
			},

			sort: {
				type: Object,
				default: null
			}

		},

		computed: {

			columnsDisplay() {

				return this.columns.filter(column => !column.hidden);

			},

			sortIcon() {

				if (!this.sort) {
					return 'sort';
				}

			return this.sort.sort === 'asc' ? 'sort-up' : 'sort-down';

			}

		},

		methods: {

			triggerSort(col) {

				this.$emit('sort', {
					field: col.field,
					sort: col.sort
				});

			},

			itemKey(rowIndex, colIndex) {

				return 'row-' + rowIndex + '-col-' + colIndex;

			},

			colClass(type, field) {

				return [
					'table-col',
					'table-col-' + paramCase(field),
					type + '-col-' + paramCase(field)
				];

			},

			slotName(col) {

				return 'column-' + paramCase(col.field);

			},

			isCurrentSort(col) {

				if (!this.sort) {
					return false;
				}

				return (this.sort.field == col.field);

			}

		}

	};

</script>

<style lang="scss" scoped>

</style>
