<template>
  
	<ul :class="className" ref="options">
    
		<form-input
			v-if="search"
			class="search mx-2"
			:placeholder="searchPlaceholder"
			@input="searchValue"
		/>
    
		<slot></slot>
    
	</ul>
  
</template>

<script>

	import FormInput from './Input';

	export default {
  
		components: {
			FormInput
		},
  
		props: {
			search: {
				type: Boolean,
				default: false
			},
			searchPlaceholder: {
				type: String
			}
		},
  
		data() {
  	
			return {
				options: []
			};
    
		},
  
		computed: {
  	
			className() {
    	
				return [
					'dropdown-content',
					'select-dropdown'
				];
      
			}
    
		},
  
		mounted() {
  	
			if (this.search) {
    	
				this.options = this.$refs.options.querySelectorAll('li');
      
			}
    
		},
  
		updated() {
  	
			if (this.search) {
    	
				this.options = this.$refs.options.querySelectorAll('li');
      
			}
    
		},
  
		methods: {
  	
			searchValue(value) {
    	
				this.options.forEach(function (option) {
      	
					let optionValue = option.getElementsByTagName('span')[0].innerText.toLowerCase();
        
					if (optionValue.includes(value.toLowerCase())) {
        	
						option.style.display = "block";
          
					} else {
        	
						option.style.display = "none";
          
					}
        
				});
      
				this.$refs.options.style.height = 'auto';
      
			}
    
		}
  
	};

</script>

<style scoped>
  
  ul {
    top: 0;
    width: 100%;
  }
  .dropdown-content {
    display: block;
    opacity: 1;
  }
  
</style>
