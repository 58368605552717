import VueTheMask from 'vue-the-mask';
import Btn from './Button';
import FormField from './FormField';
import assignDeep from 'assign-deep';

let defaults = {
	money: {
		decimal: '.',
		thousands: '',
		prefix: '',
		suffix: '',
		precision: 2,
		masked: false
	}
};

export default {

	install(Vue, options) {

		let opts = assignDeep({}, defaults, options);
		Object.defineProperty(Vue.prototype, '$formFieldComponent', {value: opts});

		Vue.use(VueTheMask);

		// Vue.component('Btn', Btn);
		Vue.component('FormField', FormField);

	}

};
