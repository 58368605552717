import JoiValidator from '@incodeapps/joi-validator';

const Validator = new JoiValidator(Joi => ({

	'common': {

		id: Joi.string().valid(null).uuid(),
		edgeId: Joi.string().max(8).valid(...[null, '']),
		vendorId: Joi.number().valid(...[null, '']),
		amountApproved: Joi.money().valid('').positive()

	},

	'1': {

		firstName: Joi.string().required(),
		lastName: Joi.string().required(),
		email: Joi.string().required().email()

	},

	'2': {

		sin: Joi.sin().valid(''),

		dob: Joi.dateOfBirth()
			.regex(/^\d{2}\/\d{2}\/\d{4}$/, 'mm/dd/yyyy')
			.possiblePast({min: 16, max: 120})
			.required(),

		primaryPhone: Joi.phone().regex().required(),

		mobilePhone: Joi.phone().valid('').regex(),
		
		address1: Joi.string().required(),

		address2: Joi.string().valid(''),

		city: Joi.string().required(),

		province: Joi.string().required(),

		postal: Joi.postal().required(),


		employer: Joi.string().required(),

		grossIncome: Joi.number().required().positive(),

		employmentTitle: Joi.required(),

		employmentTerm: Joi.required(),

		acceptTerms: Joi.string().valid(''),
		authToken: Joi.string().valid(''),
		coApps: Joi.string().valid(''),
		created: Joi.string().valid(''),
		email: Joi.string().valid(''),
		firstName: Joi.string().valid(''),
		id: Joi.string().valid(''),
		isSuperUser: Joi.string().valid(''),
		lastName: Joi.string().valid(''),
		linkSentOn: Joi.string().valid(''),
		modified: Joi.string().valid(''),
		primaryPhone: Joi.string().valid(''),
		program: Joi.string().valid(''),
		sin: Joi.string().valid(''),
		status: Joi.string().valid(''),
		token: Joi.string().valid(''),
		userIds: Joi.string().valid(''),
		vendorId: Joi.string().valid('')

	}

	


}), ['en', 'fr']);

export default Validator;
