<template>
	
	<div :class="wrapperClasses">
		
		<label v-if="separateLabel" :for="nameCPU">{{ separateLabel }}</label>
		
		<div v-if="$slots.prepend" :id="prependSlotID">
			<slot name="prepend"></slot>
		</div>
		
		<div :class="fieldsWrapperClasses">
			
			<slot name="fields" v-bind="fields">
				
				<form-field
					v-for="(childData, childName) in fields"
					:key="childName"
					v-bind="childData"
					v-model="$data[childName]"
					:name="childName"
					:error="$data.errors && $data.errors[childName]"
					bootstrap
				></form-field>
				
			</slot>
			
		</div>
		
		<div v-if="validFeedback" class="valid-feedback">
			{{ validFeedback }}
		</div>
		
		<div v-if="invalidFeedback" class="invalid-feedback">
			{{ invalidFeedback }}
		</div>
		
		<small v-if="small" class="form-text text-muted">{{ small }}</small>
		
		<div v-if="$slots.append" :id="appendSlotID">
			<slot name="append"></slot>
		</div>
	
	</div>

</template>

<script>
	
	import { paramCase } from 'change-case';
	import waves from '@incodeapps/mdbvue-pro/src/mixins/waves';
	import mdbClassMixin from '@incodeapps/mdbvue-pro/src/mixins/mdbClassMixin';
	
	export default {
		
		mixins: [waves, mdbClassMixin],
		
		inheritAttrs: false,
		
		props: {
			
			fields: {
				type: Object,
				default: () => ({})
			},
			
			bootstrap: {
				type: Boolean,
				default: false
			},
			
			basic: {
				type: Boolean,
				default: false
			},
			
			required: {
				type: Boolean,
				default: false
			},
			
			id: {
				type: String
			},
			
			label: {
				type: String
			},
			
			size: {
				type: String
			},
			
			disabled: {
				type: Boolean,
				default: false
			},
			
			value: {
				type: [String, Number, Boolean],
				default: ''
			},
			
			labelColor: {
				type: String
			},
			
			inline: {
				type: Boolean
			},
			
			successMsg: {
				type: String
			},
			errorMsg: {
				type: String
			},
			validation: {
				type: Boolean
			},
			customValidation: {
				type: Boolean
			},
			isValid: {
				type: Boolean
			},
			
			active: {
				type: Boolean,
				default: false
			},
			
			labelClass: {
				type: [Array, String]
			},
			
			fieldsWrapperClass: {
				type: String
			},
			
			wrapperClass: {
				type: [String, Array]
			},
			
			ariaLabel: {
				type: String
			},
			
			ariaDescribedBy: {
				type: String
			},
			
			ariaLabelledBy: {
				type: String
			},
			
			prependSlotID: {
				type: String
			},
			
			appendSlotID: {
				type: String
			},
			
			floatLabel: {
				type: Boolean,
				default: true
			},
			
			validFeedback: {
				type: [String, Boolean],
				default: false
			},
			
			invalidFeedback: {
				type: [String, Boolean],
				default: false
			},
			
			small: {
				type: String
			}
			
		},
		
		data() {
			
			return {
				innerValue: this.value,
				isTouched: this.active
			};
			
		},
		
		computed: {
			
			separateLabel() {
				
				return (this.bootstrap || !this.floatLabel) ? this.label : false;
				
			},
			
			propsCPU() {
				
				return Object.assign({}, this.$props, this.$attrs);
				
			},
			
			nameCPU() {
				
				return (this.name) ? this.name : 'field-' + this._uid;
				
			},
			
			idCPU() {
				
				return (this.id) ? this.id : paramCase(this.nameCPU);
				
			},
			
			inputClasses() {
				
				return [
					'form-control',
					this.validation ? this.isValid ? 'is-valid' : 'is-invalid' : false,
					this.customValidation ? this.isValid ? 'is-valid' : 'is-invalid' : false,
					this.size && 'form-control-' + this.size,
					{
						'filled-in': this.filled,
						'with-gap': this.gap
					},
					this.type === 'checkbox' ? this.gap ? false : 'form-check-input' : false,
					this.type === 'radio' ? 'form-check-input' : false,
					this.type === 'textarea' && !this.basic ? 'md-textarea' : false,
					this.inputClass && this.inputClass
				];
				
			},
			
			fieldsWrapperClasses() {
				
				return [
					'fields-wrapper',
					this.fieldsWrapperClass
				];
				
			},
			
			wrapperClasses() {
				
				if (this.noWrapper) {
					
					return [];
					
				}
				
				let classes = [
					(this.bootstrap && !this.basic) && 'form-group',
					(!this.bootstrap && !this.basic) && 'md-form',
					this.size ? `form-${this.size}` : false,
					this.wrapperClass,
					...this.mdbClass,
					'field-type-parent',
					this.required ? 'required' : null,
					!this.isValid ? 'was-validated' : null
				];
				
				return classes;
				
			},
			
			labelClasses() {
				
				return [
					{
						'active': (this.placeholder || this.isTouched || this.innerValue !== '') && this.type!=='checkbox' && this.type!=='radio',
						'disabled': this.disabled
					},
					this.labelColor && 'text-' + this.labelColor,
					this.labelClass
				];
				
			}
			
		},
		
		watch: {
			
			value(val) {
				
				this.innerValue = val;
				this.$emit('change', this.innerValue);
				
			}
			
		},
		
		mounted() {
			
			this.$emit('getDefaultValue', this.innerValue);
			
		},
		
		methods: {
			
			focus(e) {
				
				this.isTouched = true;
				
				if (!this.disabled) {
					
					this.$refs.input.focus();
					this.$emit('focus', e);
					
				}
				
			},
			
			blur(e) {
				
				this.isTouched = false;
				
				this.$emit('blur', e);
				
			},
			
			onChange(e) {
				
				this.$emit('change', e);
				
			},
			
			onInput(e) {
				
				this.$emit('input', e);
				this.innerValue = e;
				
			}
			
		}
		
	};

</script>

<style lang="scss" scoped>

</style>
