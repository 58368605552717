<template>
	
	<div :class="classes">
		
		<h2 class="h5">
			<span class="badge badge-pill badge-danger mr-2" v-if="count">{{ count }}</span>
			<slot></slot>
		</h2>
		
	</div>

</template>

<script>
	
	export default {
		
		name: 'CardHead',
		
		props: {
			
			gradient: {
				type: String,
				default: null
			},
			
			count: {
				type: Number,
				default: null
			},
			
			dark: {
				type: Boolean,
				default: false
			}
			
		},
		
		computed: {
			
			classes() {
				
				let obj = {
					'card-head': 1,
					'text-light': this.dark
				};
				
				if (this.gradient) {
					
					obj[`${this.gradient}-gradient`] = 1;
					
				}
				
				return obj;
				
			}
			
		}
		
	};

</script>

<style lang="scss" scoped>


</style>