<template>

	<div>
		<!--e-signature-template-->
		<div class="e-signature-template">
			<steps :current="1" :total="4" />

			<div class="container-xl">
				<div class="row">
					<div class="col-24">
						<div class="template__page-title">
							<h1>GENERATE CONTRACT</h1>
						</div>
						<div class="template__page-description">
							<p>
								Choose a method for executing the contract.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="mb-5"></div>

			<form @submit.prevent="onSubmit" novalidate>
				<div class="container-xl template--vh">
					<div class="row">
						<div class="col-lg-12">
							<div :class="'tffi-card tffi-card-main-card e-signature-card ' + ((app && app.application && app.application.signNowInvite == 'eSignature') ? 'active' : '')">
								<div class="row">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0">
											Route the contract to all parties’ emails for e-signature
										</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button @click="sign(false)" :disabled="isDisableButtons" class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											ROUTE FOR E-SIGNATURE
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-12">
							<div :class="'tffi-card tffi-card-main-card e-signature-card ' + ((app && app.application && app.application.signNowInvite == 'onlyDealer') ? 'active' : '')">
								<div class="row">
									<div class="col-lg-12 d-flex align-items-center">
										<p class="my-0">
											Prepare the contract for signing in person
										</p>
									</div>
									<div class="col-lg-12 d-flex align-items-center justify-content-center justify-content-xl-end">
										<button @click="sign(true)" :disabled="isDisableButtons" class="tffi-btn tffi-btn--solid theming primary-color mx-auto-lg w-100 fw-bold">
											GENERATE PDF FOR PRINTING
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

	</div>

</template>

<script>

	// import ApplicationMixin from '../../application/components/ApplicationForms/Application/ApplicationMixin';
	import FormsMixin from './ApplicationForms/FormsMixin';
	import { mapActions } from 'vuex';
	import axios from 'axios';
	import { sendEmailInvite, sendUploadTrigger, getApplication, checkSignStatus, downloadPdf } from '../../api';
	// import store from '../../store';
	// import { saveLoading } from '../../vuex/actions';
	// import dotCase from 'dot-case';
	import { paramCase } from 'change-case';
	// import fs from 'fs';
	const iconv = require('iconv-lite');

	
	axios.defaults.headers.common['Content-Type'] = 'application/json';
	axios.defaults.headers.common['Accept'] = 'application/json';

	export default {

		mixins: [
			// ApplicationMixin
			FormsMixin
		],

		data() {

			return {
				isDisableButtons: false,
				app: null,
				isDocSigned: false,
				urls: null,
				isSigned: {
					agent: false,
					customer: false,
					coCustomer: false,
				},
				signees: [
					'customer',
					'coCustomer'
				],
				allSigned: false,
				envelopeId: null,
				customerSignMethod: null,
				coCustomerSignMethod: null,
				hasCoApplicant: null,
				fetchingApp: true,
				isTimeToCheckStatus: false,
				closeTab: false,
				isEmailSentToCustomer: false,
				isCoCustomerSigned: false,
				isQC: false,
				isShowPopupNotice: false,
			};

		},

		watch: {
			'isDocSigned': {

				handler(val) {

					if (val) {

						// this.navToUploads();

					}

				}
				
			},
			'isTimeToCheckStatus': {

				handler(val) {

					if (val) {

						console.log('isTimeToCheckStatus', this.isTimeToCheckStatus);

						this.checkStatus()

					}

				}

			}

		},

		async mounted() {

			this.saveLoading(true);

			let id = this.$route.params.applicationId

			this.app = await getApplication(id);

			if (this.app.application.signNowInvite) {
				this.isDisableButtons = 'disabled'
			}

			console.log('this.app', this.app);

			this.saveLoading(false);

		},

		methods: {

			...mapActions([
				'showErrorAlert',
				'showInfoAlert',
				'removeAlerts'
			]),

			getUrl(id) {

				return {
					name: 'UploadFiles',
					params: {
						status: paramCase(id)
					}
				};

			},

			async getApp() {

				this.saveLoading(true);

				let app = await getApplication(this.$route.params.applicationId);
				console.log('app', app);

				if (
					app.application
					&& !app.application.creditProgramID
				) {

					this.showErrorAlert('Please select a program first.');

					return this.$router.push({
						name: 'FinancingProgram',
						params: this.$route.params
					});

				}

				this.hasCoApplicant = app.application.hasCoApplicant == 'Yes' ? true : false;
				this.isDocSigned = app.application.isDocSigned;

				if (app.application.envelopeId) {

					this.envelopeId = app.application.envelopeId;

					await this.checkStatus();

				}

				this.fetchingApp = false;

				this.saveLoading(false);

			},

			navToUploads() {

				return this.$router.push({
					name: 'UploadFiles',
					params: this.$route.params
				});
			
			},

			async download(event) {

				this.saveLoading(true);

				let data;

				try {
					
					data = await downloadPdf({
						id: this.$route.params.applicationId,
					});

				} catch (error) {

					this.saveLoading(false);
					console.log('data', data);
					this.showErrorAlert(error);

				}

				let byteCharacters = atob(data.base64);
				let byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				let byteArray = new Uint8Array(byteNumbers);
				let file = new Blob([byteArray], { type: 'application/pdf;base64' });
				let fileURL = URL.createObjectURL(file);
				let newWin = window.open(fileURL);

				if(!newWin || newWin.closed || typeof newWin.closed=='undefined') {
					let errorMsg = this.$i18n.t('sign.popupNotice');
					this.showErrorAlert(errorMsg);
					this.isShowPopupNotice = true;
				}

				this.saveLoading(false);

			},

			async sign(onlyDealer) {

				this.removeAlerts();

				if (!this.$route.params.applicationId) {

					this.showErrorAlert('Input application id in the URL');
					
					return {
						errorMessage: 'Application Id not found'
					};
				
				}

				let applicationId = this.$route.params.applicationId;
				
				this.saveLoading(true);

				let data = await sendEmailInvite({
					onlyDealer,
					signNowInvite: onlyDealer ? 'onlyDealer' : 'eSignature',
					id: applicationId
				});

				let result = JSON.parse(data)

				console.log('result', result);

				if (result.status == 'success') {
					this.showSuccessAlert('The invite has been sent.');
				} else {
					this.showErrorAlert('Something went wrong.' + data);
				}

				console.log('data', data);

				this.saveLoading(false);

				return this.$router.replace({
					name: 'BankVerification',
					params: this.$route.params
				});

			}

		}

	};

</script>

<style lang="scss" scoped>
.sign-links {
	list-style: none;
	padding-left: 0px;
}
select {
	display: inline!important;
}
.active {
	border: 2px solid var(--tffi-primary-color);
}
</style>