import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import assignDeep from 'assign-deep';
import { filePromises, filesDefault } from './locales';
import store from '../store';
import { isFunction } from '@incodeapps/js-utilities';

const loadedLanguages = ['en'];

Vue.use(VueI18n);

const mergeMessages = function (responses) {

	let msgs = {};

	responses.forEach(response => {

		if (response.default) {

			assignDeep(msgs, response.default);

		} else {

			assignDeep(msgs, response);

		}

	});

	return msgs;

};

const loadLocaleMessages = function () {

	const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'en';
	//const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

	let messages = {};
	messages[defaultLocale] = mergeMessages(filesDefault);

	return messages;

};

export const setI18nLanguage = function (lang) {

	i18n.locale = lang;

	axios.defaults.headers.common['Accept-Language'] = lang;

	document.documentElement.lang = lang;

	return lang;

};

export const loadLanguageAsync = function (lang) {

	if (i18n.locale !== lang) {

		if (!loadedLanguages.includes(lang)) {

			return Promise.all(filePromises(lang)).then(responses => {

				let msgs = mergeMessages(responses);

				i18n.setLocaleMessage(lang, msgs);

				loadedLanguages.push(lang);

				return setI18nLanguage(lang);

			});

		}

		return Promise.resolve(setI18nLanguage(lang));

	}

	return Promise.resolve(lang);

};

export const checkRouteForLocaleSwitch = async function (to) {

	if (to.query.l || i18n.locale !== store.state.app.locale) {

		const locale = (to.query.l) ? to.query.l : store.state.app.locale;

		if (['en', 'fr'].includes(locale)) {

			await loadLanguageAsync(locale);

			store.dispatch('saveLocale', locale);

			return true;

		}

	}

	return false;

};

export const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'en',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: loadLocaleMessages()
});
