const PageNotFound = () => import(/* webpackChunkName: "pages" */ './components/PageNotFound');

export default [
	
	{
		path: '*',
		name: 'PageNotFound',
		component: PageNotFound
	}
	
];