import Vue from 'vue';
import { i18n } from '../i18n';
import { SnotifyPosition } from 'vue-snotify';

export const saveLoading = ({ commit }, payload) => {

	commit('SAVE_LOADING', payload);

};

export const confirm = async ({ commit }, payload) => {

	let content, title, options;

	if (typeof payload !== 'string') {

		options = Object.assign({
			timeout: 1000 * 30,
			showProgressBar: true,
			pauseOnHover: true,
			position: SnotifyPosition.centerCenter,
			oneAtTime: true
		}, payload);

		content = options.message || options.content || options.html;
		title = options.title || 'Confirm';

		['message','content', 'html', 'title'].forEach(key => delete options[key]);


	} else {

		content = payload;

	}

	let notification = await Vue.prototype.$snotify.confirm(content, title, options);

	if (!notification && !notification.id) {

		return;
	
	}

	let obj = {
		id: notification.id,
		payload
	};

	commit('SHOW_CONFIRM', obj);

	return notification;

};

export const showSuccessAlert = async ({ commit }, payload) => {

	let content, options;
	let title = i18n.t('common.success');

	if (typeof payload == 'string') {

		content = payload;

	} else {

		options = Object.assign({}, payload);
		content = options.message || options.content || options.html;
		title = options.title || title;
		['message','content', 'html', 'title'].forEach(key => delete options[key]);

	}

	let notification = await Vue.prototype.$snotify.success(content, title, options);

	if (!notification && !notification.id) {

		return;
	
	}

	let obj = {
		id: notification.id,
		payload
	};

	commit('SHOW_SUCCESS_ALERT', obj);

	return notification;

};

export const showErrorAlert = async ({ commit }, payload) => {

	console.log('showErrorAlert payload', payload);

	// let notification1 = await Vue.prototype.$snotify.error('content', 'title', {});

	let content, options;
	let title = i18n.t('common.error');

	if (typeof payload == 'string') {

		content = payload;

	} else {

		options = Object.assign({}, payload);
		content = options.message || options.content || options.html;
		title = options.title || title;
		['message','content', 'html', 'title'].forEach(key => delete options[key]);

	}

	let notification = await Vue.prototype.$snotify.error(content, title, options);

	if (!notification && !notification.id) {

		return;
	
	}

	let obj = {
		id: notification.id,
		payload
	};

	commit('SHOW_ERROR_ALERT', obj);

	return notification;

};

export const showInfoAlert = async ({ commit }, payload) => {

	let content, options;
	let title = i18n.t('common.info');

	if (typeof payload == 'string') {

		content = payload;

	} else {

		options = Object.assign({}, payload);
		content = options.message || options.content || options.html;
		title = options.title || title;
		['message','content', 'html', 'title'].forEach(key => delete options[key]);

	}

	let notification = await Vue.prototype.$snotify.info(content, title, options);

	if (!notification && !notification.id) {

		return;
	
	}

	let obj = {
		id: notification.id,
		payload
	};

	commit('SHOW_INFO_ALERT', obj);

	return notification;

};

export const removeAlert = async ({ commit }, payload) => {

	if (!payload) {

		return;
	
	}

	commit('REMOVE_ALERT', payload);

	let id = (typeof payload === 'string') ? payload : payload.id;

	return Vue.prototype.$snotify.remove(id);

};

export const removeAlerts = async ({ commit }) => {

	commit('REMOVE_ALERTS');

	return Vue.prototype.$snotify.clear();

};

export const throwFatalError = ({ commit }, payload) => {

	commit('SAVE_FATAL_ERROR', payload);

};

export const saveTitle = ({ commit }, payload) => {

	commit('SAVE_TITLE', payload);

};

export const saveLocale = ({ commit }, payload) => {

	commit('SAVE_LOCALE', payload);

};
