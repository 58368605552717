import * as actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
	loading: true,
	fatalError: null,
	locale: 'en',
	title: '',
	alerts: {
		error: [],
		success: [],
		info: [],
		confirm: []
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
