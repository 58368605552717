<template>

	<!--<mdb-icon v-if="isFontAwesome" v-bind="binding" :icon="name" aria-hidden="true" role="img"></mdb-icon>-->
	
	<i v-bind="binding" :class="classes" aria-hidden="true" role="img"><slot></slot></i>
	
</template>

<script>
	
	
	export default {
		
		name: 'Icon',
		
		props: {
			
			type: {
				type: String,
				default: 'Font-Awesome'
			},
			
			name: {
				type: String,
				required: true
			},
			
			size: {
				type: [Boolean, String],
				default: false
			},
			fixed: {
				type: Boolean,
				default: false
			},
			pull: {
				type: [Boolean, String],
				default: false
			},
			border: {
				type: Boolean,
				default: false
			},
			spin: {
				type: Boolean,
				default: false
			},
			pulse: {
				type: Boolean,
				default: false
			},
			rotate: {
				type: [Boolean, String],
				default: false
			},
			flip: {
				type: [Boolean, String],
				default: false
			},
			inverse: {
				type: [Boolean, String],
				default: false
			},
			stack: {
				type: [Boolean, String],
				default: false
			},
			color: {
				type: String,
				default: ''
			},
			far: {
				type: Boolean,
				default: false
			},
			regular: {
				type: Boolean,
				default: false
			},
			fal: {
				type: Boolean,
				default: false
			},
			light: {
				type: Boolean,
				default: false
			},
			fab: {
				type: Boolean,
				default: false
			},
			brands: {
				type: Boolean,
				default: false
			}
			
		},
		
		data() {
			
			return {
				binding: {}
			};
			
		},
		
		computed: {
			
			isMaterial() {
				
				return /^[mM]/.test(this.type);
				
			},
			
			isFontAwesome() {
				
				return /^[fF]/.test(this.type);
				
			},
			
			classes() {
				
				if (this.isMaterial) {
					
					return `material-icons mi mi-${this.name}`;
					
				}
				
				if (this.isFontAwesome) {
					
					let firstClass;
					
					if (this.far || this.regular) {
						
						firstClass = 'far';
						
					} else if (this.fal || this.light) {
						
						firstClass = 'fal';
						
					} else if (this.fab || this.brands) {
						
						firstClass = 'fab';
						
					} else {
						
						firstClass = 'fas';
						
					}
					
					return [
						firstClass,
						this.name && 'fa-' + this.name,
						this.size && 'fa-' + this.size,
						this.fixed && 'fa-fw',
						this.pull && 'fa-pull-' + this.pull,
						this.border && 'fa-border',
						this.spin && 'fa-spin',
						this.pulse && 'fa-pulse',
						this.rotate && 'fa-rotate-' + this.rotate,
						this.flip && 'fa-flip-' + this.flip,
						this.inverse && 'fa-inverse',
						this.stack && 'fa-' + this.stack,
						this.color && 'text-' + this.color
					];
					
				}
				
				return `custom-icon ${this.type} ${this.type}-${this.name}`;
				
			}
			
		},
		
		watch: {
			
			$attrs: {
				handler(val, oldVal) {
					
					if (!val || val === oldVal) {
						
						return;
						
					}
					
					let props = Object.assign({}, val);
					
					delete props.type;
					delete props.name;
					
					this.binding = props;
					
				},
				immediate: true,
				deep: true
			},
			
		}
		
	};

</script>

<style lang="scss" scoped>
	
	$material-design-icons-font-path: '~material-design-icons-iconfont/dist/fonts/' !default;
	@import '~material-design-icons-iconfont/src/mixins';
	@import '~material-design-icons-iconfont/src/variables';
	
	@each $name, $value in $material-icons-codepoints {
		
		.mi-#{$name} {
			@include material-icon($name);
		}
		
	}

</style>