import { formatCurrency } from '../../../elements/globalDirectives/CurrencyLocale';
import { mapState } from 'vuex';

export default {

	data() {

		return {
			values: {},
			coAppValues: {}
		};

	},

	computed: {

		...mapState({
			locale: state => state.app.locale
		})

	},

	methods: {

		setValues() {

			console.log('setValues');

			let data;
			let coApps;

			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				data = this.application.data;
				console.log('data', data);

				coApps = this.application.data.coApps;
				console.log('coApps', coApps);

			}

			if (!data) {

				return;

			}

			let values = {};

			// looping through fields to figure out if they have options in the config
			// this way the value is the option label instead of the option value
			Object.keys(data).forEach(name => {

				let optionSet = this.checkForOptions(name, {});
				optionSet = optionSet.options || optionSet;

				if (!optionSet || !Object.keys(optionSet).length && Object.keys(this.optionSets).includes(name)) {

					optionSet = this.optionSets[name];

				}

				if (!optionSet || !Object.keys(optionSet).length) {

					let result = this.findInConfig(name);

					optionSet = result && result.field && result.field.options;

				}

				if (optionSet && Object.keys(optionSet).length) {

					let optionLabel = optionSet[data[name]];

					if (optionLabel) {

						if (this.isLocalizedObject(optionLabel)) {

							optionLabel = this.getTextFromLocalizedObject(optionLabel);

						}

						values[name] = optionLabel;

					}

				}

				if (typeof values[name] === 'undefined') {

					values[name] = data[name];

				}

				switch (name) {

					case 'amountApproved':
					case 'rentalAmount':
					case 'amount':
					case 'grossIncome':
					case 'totalHousingCosts':
					case 'totalOtherMonthlyCosts':

						if (typeof values[name] !== 'undefined') {

							try {
								
								var moneyVal = formatCurrency(values[name], this.locale);

							} catch (error) {
								
							}

							if (moneyVal) {
								values[name] = moneyVal;
							}

						}

						break;

				}

			});

			let coAppValues = [];

			console.log('Object.keys(coApps).length', Object.keys(coApps).length);

			if (
				coApps
				&& Object.keys(coApps).length > 0
			) {

				coApps.forEach(coApp => {

					let coAppValue = {};

					Object.keys(coApp).forEach(name => {
		
						let optionSet = this.checkForOptions(name, {});
						optionSet = optionSet.options || optionSet;
		
						if (!optionSet || !Object.keys(optionSet).length && Object.keys(this.optionSets).includes(name)) {
		
							optionSet = this.optionSets[name];
		
						}
		
						if (!optionSet || !Object.keys(optionSet).length) {
		
							let result = this.findInConfig(name);
		
							optionSet = result && result.field && result.field.options;
		
						}
		
						if (optionSet && Object.keys(optionSet).length) {
		
							let optionLabel = optionSet[data[name]];
		
							if (optionLabel) {
		
								if (this.isLocalizedObject(optionLabel)) {
		
									optionLabel = this.getTextFromLocalizedObject(optionLabel);
		
								}
		
								coAppValue[name] = optionLabel;
		
							}
		
						}
		
						if (typeof coAppValue[name] === 'undefined') {
		
							coAppValue[name] = data[name];
		
						}
		
					});

					console.log('coAppValue', coAppValue);
					
					coAppValues.push(coAppValue);
					
				})
			}

			console.log('coAppValues', coAppValues);


			this.$set(this.$data, 'values', values);
			this.$set(this.$data, 'coAppValues', coAppValues);

		},

		getGroupClasses(name) {

			return [
				'form-group static-form-group',
				this.application.errors[name] && 'was-validated is-invalid'
			];

		}

	}

};

