import {
	// mdbAccordion,
	// mdbAccordionPane,
	// mdbAlert,
	// mdbBadge,
	// mdbBreadcrumb,
	// mdbBreadcrumbItem,
	// mdbBtn,
	// mdbBtnGroup,
	// mdbBtnToolbar,
	mdbCard,
	// mdbCardAvatar,
	mdbCardBody,
	// mdbCardFooter,
	mdbCardGroup,
	// mdbCardHeader,
	// mdbCardImage,
	// mdbCardText,
	// mdbCardTitle,
	// mdbCardUp,
	// mdbCarousel,
	// mdbCarouselCaption,
	// mdbCarouselControl,
	// mdbCarouselIndicator,
	// mdbCarouselIndicators,
	// mdbCarouselInner,
	// mdbCarouselItem,
	// mdbBarChart,
	// mdbDoughnutChart,
	// mdbLineChart,
	// mdbPieChart,
	// mdbPolarChart,
	// mdbRadarChart,
	mdbCol,
	// mdbCollapse,
	mdbContainer,
	// mdbDatatable,
	mdbDropdown,
	mdbDropdownItem,
	mdbDropdownMenu,
	mdbDropdownToggle,
	// mdbEdgeHeader,
	// mdbIcon,
	// mdbInput,
	// mdbFooter,
	// mdbFormInline,
	// mdbGoogleMap,
	// mdbJumbotron,
	// mdbListGroup,
	// mdbListGroupItem,
	mdbMask,
	// mdbMedia,
	// mdbMediaBody,
	// mdbMediaImage,
	// mdbModal,
	// mdbModalHeader,
	// mdbModalTitle,
	// mdbModalBody,
	// mdbModalFooter,
	// mdbNumericInput,
	// mdbNavbar,
	mdbNavbarBrand,
	mdbNavbarNav,
	mdbNavbarToggler,
	mdbNavItem,
	// mdbPageItem,
	// mdbPageNav,
	// mdbPagination,
	// mdbPopover,
	// mdbProgress,
	mdbRow,
	// mdbTabs,
	// mdbTab,
	// mdbTabContent,
	// mdbTabItem,
	// mdbTabPane,
	// mdbTbl,
	// mdbTblBody,
	// mdbTblHead,
	// mdbTextarea,
	// mdbTooltip,
	mdbView,
	ViewWrapper,
	// mdbAutocomplete,
	// mdbAvatar,
	// mdbBtnFixed,
	// mdbBtnFixedItem,
	// mdbChip,
	// mdbChipInput,
	// mdbCsvDownloader,
	// mdbDatePicker,
	// mdbFileInput,
	// mdbFlippingCard,
	// mdbLightbox,
	// mdbNotification,
	// mdbRangeInput,
	// mdbSpinner,
	// mdbScrollspyBox,
	// mdbScrollspyList,
	// mdbScrollspyListItem,
	// mdbScrollspyText,
	// mdbSwitch,
	// mdbTableEditable,
	// mdbTestimonial,
	// mdbTestimonialNavigation,
	// mdbTimePicker,
	// mdbScrollbar,
	// mdbSelect,
	// mdbSelectDropdown,
	// mdbSelectInput,
	// mdbSelectOption,
	// mdbSideNav,
	// mdbSideNavNav,
	// mdbSideNavCat,
	// mdbSideNavItem,
	// mdbSimpleChart,
	// mdbStep,
	// mdbStepper,
	// mdbStreak,
	// mdbSubMenu,
	// mdbParallax
} from '@incodeapps/mdbvue-pro';

export default {
	
	install(Vue) {
		
		// Vue.component('mdbAccordion', mdbAccordion);
		// Vue.component('mdbAccordionPane', mdbAccordionPane);
		// Vue.component('mdbAlert', mdbAlert);
		// Vue.component('mdbBadge', mdbBadge);
		// Vue.component('mdbBreadcrumb', mdbBreadcrumb);
		// Vue.component('mdbBreadcrumbItem', mdbBreadcrumbItem);
		// Vue.component('mdbBtn', mdbBtn);
		// Vue.component('mdbBtnGroup', mdbBtnGroup);
		// Vue.component('mdbBtnToolbar', mdbBtnToolbar);
		Vue.component('mdbCard', mdbCard);
		// Vue.component('mdbCardAvatar', mdbCardAvatar);
		Vue.component('mdbCardBody', mdbCardBody);
		// Vue.component('mdbCardFooter', mdbCardFooter);
		Vue.component('mdbCardGroup', mdbCardGroup);
		// Vue.component('mdbCardHeader', mdbCardHeader);
		// Vue.component('mdbCardImage', mdbCardImage);
		// Vue.component('mdbCardText', mdbCardText);
		// Vue.component('mdbCardTitle', mdbCardTitle);
		// Vue.component('mdbCardUp', mdbCardUp);
		// Vue.component('mdbCarousel', mdbCarousel);
		// Vue.component('mdbCarouselCaption', mdbCarouselCaption);
		// Vue.component('mdbCarouselControl', mdbCarouselControl);
		// Vue.component('mdbCarouselIndicator', mdbCarouselIndicator);
		// Vue.component('mdbCarouselIndicators', mdbCarouselIndicators);
		// Vue.component('mdbCarouselInner', mdbCarouselInner);
		// Vue.component('mdbCarouselItem', mdbCarouselItem);
		// Vue.component('mdbBarChart', mdbBarChart);
		// Vue.component('mdbDoughnutChart', mdbDoughnutChart);
		// Vue.component('mdbLineChart', mdbLineChart);
		// Vue.component('mdbPieChart', mdbPieChart);
		// Vue.component('mdbPolarChart', mdbPolarChart);
		// Vue.component('mdbRadarChart', mdbRadarChart);
		Vue.component('mdbCol', mdbCol);
		// Vue.component('mdbCollapse', mdbCollapse);
		Vue.component('mdbContainer', mdbContainer);
		// Vue.component('mdbDatatable', mdbDatatable);
		Vue.component('mdbDropdown', mdbDropdown);
		Vue.component('mdbDropdownItem', mdbDropdownItem);
		Vue.component('mdbDropdownMenu', mdbDropdownMenu);
		Vue.component('mdbDropdownToggle', mdbDropdownToggle);
		// Vue.component('mdbEdgeHeader', mdbEdgeHeader);
		// Vue.component('mdbIcon', mdbIcon);
		// Vue.component('mdbInput', mdbInput);
		// Vue.component('mdbFooter', mdbFooter);
		// Vue.component('mdbFormInline', mdbFormInline);
		// Vue.component('mdbGoogleMap', mdbGoogleMap);
		// Vue.component('mdbJumbotron', mdbJumbotron);
		// Vue.component('mdbListGroup', mdbListGroup);
		// Vue.component('mdbListGroupItem', mdbListGroupItem);
		Vue.component('mdbMask', mdbMask);
		// Vue.component('mdbMedia', mdbMedia);
		// Vue.component('mdbMediaBody', mdbMediaBody);
		// Vue.component('mdbMediaImage', mdbMediaImage);
		// Vue.component('mdbModal', mdbModal);
		// Vue.component('mdbModalHeader', mdbModalHeader);
		// Vue.component('mdbModalTitle', mdbModalTitle);
		// Vue.component('mdbModalBody', mdbModalBody);
		// Vue.component('mdbModalFooter', mdbModalFooter);
		// Vue.component('mdbNumericInput', mdbNumericInput);
		// Vue.component('mdbNavbar', mdbNavbar);
		Vue.component('mdbNavbarBrand', mdbNavbarBrand);
		Vue.component('mdbNavbarNav', mdbNavbarNav);
		Vue.component('mdbNavbarToggler', mdbNavbarToggler);
		Vue.component('mdbNavItem', mdbNavItem);
		// Vue.component('mdbPageItem', mdbPageItem);
		// Vue.component('mdbPageNav', mdbPageNav);
		// Vue.component('mdbPagination', mdbPagination);
		// Vue.component('mdbPopover', mdbPopover);
		// Vue.component('mdbProgress', mdbProgress);
		Vue.component('mdbRow', mdbRow);
		// Vue.component('mdbTabs', mdbTabs);
		// Vue.component('mdbTab', mdbTab);
		// Vue.component('mdbTabContent', mdbTabContent);
		// Vue.component('mdbTabItem', mdbTabItem);
		// Vue.component('mdbTabPane', mdbTabPane);
		// Vue.component('mdbTbl', mdbTbl);
		// Vue.component('mdbTblBody', mdbTblBody);
		// Vue.component('mdbTblHead', mdbTblHead);
		// Vue.component('mdbTextarea', mdbTextarea);
		// Vue.component('mdbTooltip', mdbTooltip);
		Vue.component('mdbView', mdbView);
		Vue.component('ViewWrapper', ViewWrapper);
		// Vue.component('mdbAutocomplete', mdbAutocomplete);
		// Vue.component('mdbAvatar', mdbAvatar);
		// Vue.component('mdbBtnFixed', mdbBtnFixed);
		// Vue.component('mdbBtnFixedItem', mdbBtnFixedItem);
		// Vue.component('mdbChip', mdbChip);
		// Vue.component('mdbChipInput', mdbChipInput);
		// Vue.component('mdbCsvDownloader', mdbCsvDownloader);
		// Vue.component('mdbDatePicker', mdbDatePicker);
		// Vue.component('mdbFileInput', mdbFileInput);
		// Vue.component('mdbFlippingCard', mdbFlippingCard);
		// Vue.component('mdbLightbox', mdbLightbox);
		// Vue.component('mdbNotification', mdbNotification);
		// Vue.component('mdbRangeInput', mdbRangeInput);
		// Vue.component('mdbSpinner', mdbSpinner);
		// Vue.component('mdbScrollspyBox', mdbScrollspyBox);
		// Vue.component('mdbScrollspyList', mdbScrollspyList);
		// Vue.component('mdbScrollspyListItem', mdbScrollspyListItem);
		// Vue.component('mdbScrollspyText', mdbScrollspyText);
		// Vue.component('mdbSwitch', mdbSwitch);
		// Vue.component('mdbTableEditable', mdbTableEditable);
		// Vue.component('mdbTestimonial', mdbTestimonial);
		// Vue.component('mdbTestimonialNavigation', mdbTestimonialNavigation);
		// Vue.component('mdbTimePicker', mdbTimePicker);
		// Vue.component('mdbScrollbar', mdbScrollbar);
		// Vue.component('mdbSelect', mdbSelect);
		// Vue.component('mdbSelectDropdown', mdbSelectDropdown);
		// Vue.component('mdbSelectInput', mdbSelectInput);
		// Vue.component('mdbSelectOption', mdbSelectOption);
		// Vue.component('mdbSideNav', mdbSideNav);
		// Vue.component('mdbSideNavNav', mdbSideNavNav);
		// Vue.component('mdbSideNavCat', mdbSideNavCat);
		// Vue.component('mdbSideNavItem', mdbSideNavItem);
		// Vue.component('mdbSimpleChart', mdbSimpleChart);
		// Vue.component('mdbStep', mdbStep);
		// Vue.component('mdbStepper', mdbStepper);
		// Vue.component('mdbStreak', mdbStreak);
		// Vue.component('mdbSubMenu', mdbSubMenu);
		// Vue.component('mdbParallax', mdbParallax);
		
	}
};
